import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

import { CognitoUser } from 'types';

export const useTokenInfo = (): CognitoUser | null => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const updateUserInfo = async () => {
      try {
        const currentUser = await Auth.currentSession();
        if (currentUser?.isValid()) {
          const transformedPayload = {
            ...currentUser?.getIdToken()?.payload,
            email: currentUser?.getIdToken()?.payload?.email?.toLowerCase(),
          };
          setUserInfo(transformedPayload);
        }
      } catch {
        setUserInfo(null);
      }
    };
    // uncomment these lines if you want to remove all user info at the instant user clicks signout button
    // Hub.listen('auth', updateUserInfo);
    updateUserInfo();
    // return () => Hub.remove('auth', updateUserInfo);
  }, []);

  return userInfo;
};
