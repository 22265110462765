import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import theme from 'themes/baseTheme';

import { Box, createTheme, Divider, Grid, Stack, ThemeProvider, Typography } from '@mui/material';

import { DeviceStatus } from 'components/Common/DeviceStatus';

import { getEvent } from 'services/eventService';

import { FeedNodeStatusType } from 'constants/DeviceStates';

import { SourceBase, SourceType } from 'types';

const gridTheme = createTheme({
  typography: {
    fontFamily: theme.typography.fontFamily,
    subtitle3: {
      fontSize: theme.typography.fontSize,
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        item: {
          '&:not(:first-child)::after': {
            content: '" "',
            position: 'absolute',
            top: '45%',
            left: '-25px',
            width: '2px',
            height: '30%',
            backgroundColor: '#d0c8c8',
            borderRadius: '5px',
          },
        },
      },
    },
  },
});

interface SourceDetailProps {
  eventID: string;
  isCompact?: boolean;
}

export function SourceDetail({ eventID, isCompact = false }: SourceDetailProps): JSX.Element {
  const [sources, setSources] = useState<SourceBase[]>([]);

  const sourceQuery = useQuery(['event', eventID], () => getEvent(eventID), {
    onSuccess: (data) => {
      const filteredSource = data.sources.filter(
        (source: SourceBase) => source.type !== SourceType.ZIXI_MULTI
      );
      setSources(filteredSource);
    },
  });

  return (
    <>
      <Grid container flexDirection="column">
        <Grid item>
          <Stack sx={{ mt: isCompact ? '0px' : '20px' }} direction="row" spacing={2}>
            <Typography
              sx={{ fontWeight: 'bold', color: isCompact ? 'common.black' : 'grey.600' }}
              variant="subtitle2"
            >
              Sources
            </Typography>
            <Divider variant="inset" sx={{ flexGrow: '1' }} />
          </Stack>
        </Grid>
        <ThemeProvider theme={gridTheme}>
          <Box
            sx={{
              maxWidth: '690px',
              overflowX: 'auto',
              padding: '5px 5px 16px 5px',
              display: 'flex',
              gap: '3rem',
            }}
          >
            {sources &&
              sources.length > 0 &&
              sources?.map((source: SourceBase) => {
                return (
                  <Grid item key={source.id} sx={{ position: 'relative' }}>
                    <Stack
                      sx={{ mt: isCompact ? '10px' : '20px', minWidth: '110px' }}
                      direction="column"
                      flexWrap="wrap"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Stack sx={{ alignItems: 'start' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 'bold',
                            color: isCompact ? 'common.black' : 'grey.600',
                          }}
                        >
                          {source.type.replace('_', ' ') || '-'}
                        </Typography>
                      </Stack>
                      <Stack>
                        <DeviceStatus
                          status={
                            source?.status || {
                              type: FeedNodeStatusType.NEGATIVE,
                              value: 'NOT PRESENT',
                            }
                          }
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
          </Box>
        </ThemeProvider>
      </Grid>
    </>
  );
}
