import { useState } from 'react';

import { Button, ButtonProps, CircularProgress, SvgIconProps } from '@mui/material';
import {
  AddRounded,
  DeleteRounded,
  PlayArrowRounded,
  RefreshRounded,
  StopRounded,
} from '@mui/icons-material';

import { EventStreamStatus, StreamDisplayName } from 'constants/StreamConstants';

import { useStreamApi } from 'hooks/useStreamApi';

import { SingleAPIEvent } from 'types';
import { getNextMasterButtonState } from 'utils/streamHelper';

interface IMasterStreamButton extends ButtonProps {
  event: SingleAPIEvent;
}

const StreamIcon = ({
  status,
  iconProps,
}: {
  status: EventStreamStatus;
  iconProps?: SvgIconProps;
}) => {
  switch (status) {
    case EventStreamStatus.CREATED:
      return <AddRounded {...iconProps} />;
    case EventStreamStatus.DESTROYED:
      return <DeleteRounded {...iconProps} />;
    case EventStreamStatus.NON_EXISTENT:
      return <RefreshRounded {...iconProps} />;
    case EventStreamStatus.STARTED:
      return <PlayArrowRounded {...iconProps} />;
    case EventStreamStatus.STOPPED:
      return <StopRounded {...iconProps} />;
    default:
      return <></>;
  }
};

export const MasterStreamControlButton = ({ event, ...buttonProps }: IMasterStreamButton) => {
  const [isFetching, setisFetching] = useState(false);
  const { eventOperation, isLoading } = useStreamApi(setisFetching);
  const { status, hasFailed, isQueued } = event.stream;
  const { nextAction, disable } = getNextMasterButtonState(status, hasFailed, isQueued);

  const streamControlAction = () => {
    setisFetching(true);
    switch (nextAction) {
      case EventStreamStatus.CREATED:
        return eventOperation({ eventId: event.id, operation: EventStreamStatus.CREATED });
      case EventStreamStatus.DESTROYED:
        return eventOperation({ eventId: event.id, operation: EventStreamStatus.DESTROYED });
      default:
        return eventOperation({ eventId: event.id, operation: EventStreamStatus.NON_EXISTENT });
    }
  };
  // useEffect(() => {
  //   if (isLoading) {
  //     setisFetching(!isLoading);
  //   }
  // }, [isLoading]);
  // const statusButton = event.stream.isQueued ? event.stream.status : nextAction;
  return (
    <Button
      variant="contained"
      size="small"
      disabled={disable || isFetching}
      startIcon={
        (event.stream.isQueued &&
          (event.stream.status === EventStreamStatus.CREATED ||
            event.stream.status === EventStreamStatus.DESTROYED)) ||
        isFetching ? (
          <CircularProgress size={15} />
        ) : (
          <StreamIcon status={nextAction} />
        )
      }
      onClick={() => streamControlAction()}
      {...buttonProps}
    >
      {StreamDisplayName[nextAction]['displayName']} STREAM
    </Button>
  );
};
