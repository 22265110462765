import _ from 'lodash';

import { Box } from '@mui/material';

import { JSONGridDetail } from 'components/Event/JSONGridDetail';

import { AWSResourceType, OutputType } from 'types';

const transformResourceDetails = (data: any) => {
  const { details, type, specifications } = data;
  const commonZixiDetails = {
    details,
    Id: details?.id || '-',
    Name: details?.name || '-',
    'General Status': details?.generalStatus || '-',
  };

  switch (type) {
    case AWSResourceType.ZIXI_SOURCE:
      return {
        ...commonZixiDetails,
        'Target Host': details?.target_host || '-',
        Port: details?.listening_port || details?.remote_port || '-',
        Latency: details?.latency || '-',
        'Max Bitrate': details?.max_bitrate || '-',
      };
    case AWSResourceType.ZIXI_CHANNEL:
      return commonZixiDetails;
    case AWSResourceType.ZIXI_TARGET:
      return {
        ...commonZixiDetails,
        Latency: details?.latency || '-',
        ...(specifications && specifications?.type?.toUpperCase() !== OutputType.SRT
          ? {
              Target: details?.target || '-',
              'Max Bitrate': details?.max_bitrate || '-',
            }
          : {
              Host: details?.host || '-',
              Port: details?.port || '-',
            }),
      };
    default:
      return {};
  }
};

export const ZixiResourceDetails = ({ data }: any) => {
  const transformedDetails = transformResourceDetails(data);

  return (
    <Box py={1}>
      <JSONGridDetail data={transformedDetails} />
    </Box>
  );
};
