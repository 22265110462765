import { createTheme, Theme } from '@mui/material/styles';

export const calenderTheme = createTheme({
  components: {
    MuiPaper: {
      defaultProps: {
        sx: {
          boxShadow: (theme: Theme) => theme.shadows[1],
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        sx: {
          label: {
            color: 'grey.600',
          },
        },
      },
    },
    MuiPickersDay: {
      defaultProps: {
        sx: {
          '&&.Mui-selected': {
            backgroundColor: 'primary.main',
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          button: {
            color: 'primary.main',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'small' && {
            '.MuiInputBase-inputSizeSmall, .MuiInputLabel-root': {
              fontSize: '0.85rem !important',
            },

            '.MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.MuiFormLabel-filled': {
              transform: 'translate(18px, -8px) scale(0.8)',
            },
          }),
        }),
      },
    },
  },
});
