import { useContext, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Box, Theme } from '@mui/system';

import { CurrentUserInfoContext } from 'contexts/CurrentUserInfoContext';

import { UserRole } from 'constants/AuthConstants';
import { ROUTE_KEY, ROUTE_PATH } from 'constants/RouteConstants';

import { UserRoleTypes } from 'types';
import { buildStyles } from 'utils/helpers';

import MenuBar from './MenuBar';

const styles = buildStyles({
  viewContainer: (theme: Theme) => ({
    marginTop: '80px',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      padding: '0 80px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      padding: '0 50px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 40px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  }),
});

function PrivateRoute({
  path = '/',
  allowedRoles = [],
  component,
}: {
  path: string;
  allowedRoles: UserRoleTypes[];
  component: JSX.Element;
}): JSX.Element {
  const [auth, setAuth] = useState(false);
  const { userInfo } = useContext(CurrentUserInfoContext);
  const history = useHistory();

  const roleFilter = (currentUserRole) => {
    const shouldAllow = allowedRoles && allowedRoles.indexOf(currentUserRole) !== -1;
    if (!shouldAllow) {
      history.push(ROUTE_PATH[ROUTE_KEY.LOGIN]);
    } else {
      setAuth(shouldAllow);
    }
  };

  const isAuthenticated = () => {
    setAuth(false);

    const redirectToLogin = () => {
      // <Redirect to="/" state={state} />;
      history.push('/');
    };

    Auth.currentSession()
      .then((session) => {
        if (session.isValid()) {
          /* if there is a role??
              if the role is UNASSIGNED ?? or the role is not even in the list of defined roles ??
                set autherror on session storage and goto login page
          */
          if (
            session.getIdToken()?.payload?.role &&
            (session.getIdToken()?.payload?.role === UserRole.UNASSIGNED ||
              !Object.values(UserRole).includes(session.getIdToken()?.payload?.role))
          ) {
            Auth.signOut();
            // TODO: Move this to a top global AuthError context, instead of using sessionStorage
            sessionStorage.setItem('AUTH_ERROR', UserRole.UNASSIGNED);
          } else {
            setAuth(true);
            roleFilter(session.getIdToken()?.payload?.role);
          }
        } else {
          redirectToLogin();
        }
      })
      .catch(() => {
        redirectToLogin();
      });
  };

  useEffect(() => {
    isAuthenticated();
  }, [userInfo]);

  return (
    <Route
      exact
      path={path}
      render={() =>
        auth ? (
          <>
            <MenuBar />
            <Box sx={styles.viewContainer}>{component}</Box>
          </>
        ) : null
      }
    />
  );
}

export default PrivateRoute;
