import { useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { format } from 'date-fns';

import {
  createTheme,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Circle } from '@mui/icons-material';

import { EventGridActions } from 'components/Event/EventGridActions';
import { getBadgeColor } from 'components/Event/EventStatus';

import { APIEvent, StreamStatus } from 'types';
import { timeZoneAbbreviated } from 'utils/helpers';

const tableTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '10px 16px 10px 16px',
          position: 'relative',
          minWidth: '150px',
          '&:first-child': {
            maxWidth: '300px ',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
        head: {
          '&:not(:last-child)::after': {
            content: '" "',
            height: '40%',
            width: '1px',
            position: 'absolute',
            top: '13px',
            right: '0px',
            background: 'grey',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: '#f4f4f4',
            '> .MuiTableCell-root:last-child': {
              backgroundColor: '#f4f4f4',
            },
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#fff',
            '> .MuiTableCell-root:last-child': {
              backgroundColor: '#fff',
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        input: {
          width: '50px',
        },
      },
    },
  },
});

const headCells: { id: string; label: string }[] = [
  { id: 'name', label: 'Name' },
  { id: 'scheduleStart', label: 'Start time' },
  { id: 'scheduledDate', label: 'Scheduled Date' },
  { id: 'group', label: 'Club' },
  { id: 'streamStatus', label: 'Status' },
  { id: 'creatorName', label: 'Creator Name' },
  { id: 'creatorEmail', label: 'Creator Email' },
  { id: 'creatorGroup', label: 'Creator Group' },
  { id: 'actions', label: 'Actions' },
];

export const RenderTable = ({ events, isRunning }: { events: APIEvent[]; isRunning?: boolean }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const timeZone = timeZoneAbbreviated();

  const handleChangePage = (changePageEvent, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (changeRowsPerPageEvent) => {
    setRowsPerPage(parseInt(changeRowsPerPageEvent.target.value, 10));
    setPage(0);
  };
  return events?.length > 0 ? (
    <>
      <ThemeProvider theme={tableTheme}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="center"
                    padding="none"
                    style={
                      headCell.id === 'actions'
                        ? {
                            position: 'sticky',
                            right: '0px',
                            background: 'black',
                            fontWeight: 'bold',
                            color: 'white',
                            minWidth: '250px',
                          }
                        : {
                            background: 'black',
                            color: 'white',
                            fontWeight: 'bold',
                            paddingLeft: headCell.id === 'gameDate' ? '7px' : '0px',
                          }
                    }
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {events?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((event) => (
                <TableRow key={event.id}>
                  <TableCell align="center" padding="none">
                    {event.name}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {format(new Date(event.scheduledTime), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {format(new Date(event.scheduledTime), 'hh:mm a')} {timeZone}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {event.group}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <Typography
                      variant="body2"
                      style={{ display: 'flex' }}
                      alignItems="center"
                      justifyItems="center"
                    >
                      <Circle
                        sx={{
                          color: getBadgeColor(
                            event.stream?.status as unknown as StreamStatus,
                            event.stream?.isQueued,
                            event.stream?.hasFailed
                          ),
                          mr: 0.5,
                          width: '13px',
                          height: '13px',
                        }}
                      />
                      {event.stream?.status?.replace('_', ' ')}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {event.createdBy.firstName} {event.createdBy.lastName}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {event.createdBy.email}
                  </TableCell>
                  <TableCell align="center" padding="none">
                    {event.createdBy.group}
                  </TableCell>
                  <TableCell
                    style={{
                      position: 'sticky',
                      right: '0px',
                      minWidth: '250px',
                    }}
                    padding="none"
                    align="center"
                  >
                    <EventGridActions event={event} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[3, 5, 10]}
          component="div"
          count={events.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          hidden={!events?.length}
        />
      </ThemeProvider>
    </>
  ) : (
    <Stack
      alignItems="center"
      justifyContent="center"
      style={{
        textAlign: 'center',
        marginTop: '20px',
        height: isRunning ? '130px' : '100px',
      }}
    >
      <Typography variant="body1" sx={{ color: 'grey.600' }}>
        {isRunning ? 'There are no running events found' : 'No Events found'}
      </Typography>
    </Stack>
  );
};
