import { Box } from '@mui/material';

import { JSONGridDetail } from 'components/Event/JSONGridDetail';

import { AWSMedialiveDevice, AWSResource } from 'types';

interface IMedialiveDeviceResourceDetails {
  data: AWSResource<AWSMedialiveDevice>;
}

const transformResourceDetails = (data: AWSResource<AWSMedialiveDevice>) => {
  const { details } = data;

  return {
    details,
    Id: details?.Id || '-',
    Name: details?.Name,
    Connection: details?.ConnectionState,
  };
};

export const MedialiveDeviceResourceDetails = ({ data }: IMedialiveDeviceResourceDetails) => {
  const transformedDetails = transformResourceDetails(data);

  return (
    <Box py={1}>
      <JSONGridDetail data={transformedDetails} />
    </Box>
  );
};
