import { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getAllGroups, getGroupById } from 'services/groupService';

import { UserRole } from 'constants/AuthConstants';
import { AdminGroupNames, NHLLogo } from 'constants/TeamConstants';
import logo from 'assets/nhlflat.svg';

import { CurrentUserInfoContext } from './CurrentUserInfoContext';
import { GroupInfo } from '../types';

export type GroupInfoContextReturnType = Partial<{
  groups: GroupInfo[];
  currentUserGroup: GroupInfo;
  isUserAdminGroup: () => boolean;
  getGroupLogoById: (id: string) => string;
  getOutputURLById: (id: string) => string;
}>;

export const GroupInfoContext = createContext<GroupInfoContextReturnType>({});

export function GroupInfoProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element {
  const [currentUserGroup, setCurrentUserGroup] = useState<GroupInfo>();
  const { userInfo } = useContext(CurrentUserInfoContext);

  const { refetch: refetchGroups, data: groups = [] } = useQuery(
    ['allGroups'],
    async () => {
      const response = await getAllGroups();
      return response.sort((a, b) => (a.id > b.id ? 1 : -1));
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { refetch: refetchGroupById, data: myGroup } = useQuery(
    ['groupInfo', userInfo?.group],
    () => getGroupById(userInfo?.group),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const isUserAdminGroup = () =>
    Object.keys(AdminGroupNames).includes(userInfo?.group) &&
    userInfo?.role === UserRole.APPLICATION_ADMIN;

  const fetchGroupInfo = async () => {
    // get user's own group only if user is not an admin, else get all the groups
    if (!isUserAdminGroup()) {
      const { data: group } = await refetchGroupById();
      setCurrentUserGroup(group);
    } else {
      const { data: allGroups } = await refetchGroups();
      setCurrentUserGroup(allGroups.find((group) => group.id === userInfo?.group));
    }
  };

  const getOutputURLById = (id: string): string => {
    if (!isUserAdminGroup()) {
      return myGroup?.outputUrl || '';
    } else {
      const groupToSelect = groups?.find((group) => group.id === id);
      return groupToSelect?.outputUrl || '';
    }
  };

  const getGroupLogoById = (groupId: string): string => {
    if (groupId === NHLLogo.NHLOriginalProductions) return logo;
    const group = (() => {
      if (isUserAdminGroup()) {
        return groups.find((group) => group.id === groupId);
      } else {
        return currentUserGroup;
      }
    })();
    return group?.logo || null;
  };

  useEffect(() => {
    if (userInfo?.group) {
      fetchGroupInfo();
    }
  }, [userInfo]);

  return (
    <GroupInfoContext.Provider
      value={{
        groups,
        currentUserGroup,
        isUserAdminGroup,
        getGroupLogoById,
        getOutputURLById,
      }}
    >
      {children}
    </GroupInfoContext.Provider>
  );
}

export const useGroups = (): GroupInfoContextReturnType => {
  return useContext(GroupInfoContext);
};
