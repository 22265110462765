import { OutputType, SourceType } from 'types';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_FORMAT_WITH_TIME = 'MM/dd/yyyy hh:mm:ss aa';

export const SKIP_WEBSOCKT_MESSAGE_TIMEOUT = 3000;

export const SourceTypeDisplayName: Record<SourceType, string> = {
  [SourceType.ELEMENTAL_LINK]: 'ELEMENTAL LINK',
  [SourceType.RTMP]: 'RTMP',
  [SourceType.SLATE_INPUT]: 'SLATE',
  [SourceType.ZIXI]: 'ZIXI PUSH',
  [SourceType.SRT]: 'SRT',
  [SourceType.ZIXI_MULTI]: 'ZIXI MULTIPLEXER',
};

export const OutputTypeDisplayName: Record<OutputType, string> = {
  [OutputType.HLS]: 'HLS',
  [OutputType.RTMP]: 'RTMP',
  [OutputType.SRT]: 'SRT',
  [OutputType.COMMONCHANNEL]: 'COMMON CHANNEL',
};

export const CONTROL_CENTER_OUTPUT_PATH_ID = 'cc-output-path-id';
