import { StreamActionButtonsStatus, StreamStatus } from 'types';

export enum EventStreamStatus {
  NON_EXISTENT = 'NON_EXISTENT',
  CREATED = 'CREATED',
  SOURCE_SELECTED = 'SOURCE_SELECTED',
  STARTED = 'STARTED',
  STOPPED = 'STOPPED',
  DESTROYED = 'DESTROYED',
}

export enum StreamActionButtons {
  CREATE = 'CREATE',
  DESTROY = 'DESTROY',
  ATTACH_INPUT = 'ATTACH_INPUT',
  START_STREAM = 'START_STREAM',
  STOP_STREAM = 'STOP_STREAM',
  RESET = 'RESET',
}

export const StreamLiveStates = [
  StreamStatus.STARTED,
  StreamStatus.STOPPED,
  StreamStatus.SOURCE_SELECTED,
];

export const DefaultStreamActionButtonsStatus: StreamActionButtonsStatus = Object.freeze({
  disabled: [
    StreamActionButtons.ATTACH_INPUT,
    StreamActionButtons.CREATE,
    StreamActionButtons.DESTROY,
    StreamActionButtons.RESET,
    StreamActionButtons.START_STREAM,
    StreamActionButtons.STOP_STREAM,
  ],
  visible: [
    StreamActionButtons.START_STREAM,
    StreamActionButtons.ATTACH_INPUT,
    StreamActionButtons.CREATE,
  ],
  loading: [],
});

export const StreamDisplayName: Record<StreamStatus, { displayName: string; gerund: string }> = {
  [StreamStatus.CREATED]: { displayName: 'CREATE', gerund: 'CREATING' },
  [StreamStatus.DESTROYED]: { displayName: 'DESTROY', gerund: 'DESTROYING' },
  [StreamStatus.SOURCE_SELECTED]: { displayName: 'SWITCH SOURCE', gerund: 'SWITCHING SOURCE' },
  [StreamStatus.STARTED]: { displayName: 'START', gerund: 'STARTING' },
  [StreamStatus.STOPPED]: { displayName: 'STOP', gerund: 'STOPPING' },
  [StreamStatus.NON_EXISTENT]: { displayName: 'RESET', gerund: 'RESETTING' },
};
