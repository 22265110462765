import { PropsWithChildren } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme,
  Tooltip,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { PopUpButtonProps, TooltipProps } from 'types';
import { buildStyles } from 'utils/helpers';

interface PopUpProps {
  isPopUpOpen: boolean;
  title?: string;
  buttons?: PopUpButtonProps[];
  fullWidth?: boolean;
  maxWidth?: Pick<DialogProps, 'maxWidth'>['maxWidth'];
  disableCTA?: boolean;
  close: () => void;
  description?: JSX.Element;
  tooltip?: TooltipProps;
  showClose?: boolean;
}

const styles = buildStyles({
  dialogBox: (theme: Theme) => ({
    '.MuiPaper-root': {
      position: 'relative',
      padding: '1rem 3rem 2rem 3rem',
      input: {
        minWidth: '20ch',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '3rem 1rem 2rem 1rem',
        minWidth: '80vw',
        input: {
          minWidth: 'min(20ch, 65%)',
        },
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: '100vw',
      },
    },
  }),
  closeIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
});

export function PopUp({
  isPopUpOpen,
  fullWidth,
  children,
  title,
  buttons,
  close,
  disableCTA,
  description,
  maxWidth,
  tooltip,
  showClose = true,
}: PropsWithChildren<PopUpProps>): JSX.Element {
  return (
    <Dialog open={isPopUpOpen} fullWidth={fullWidth} maxWidth={maxWidth} sx={styles.dialogBox}>
      {showClose && (
        <IconButton onClick={close} sx={styles.closeIcon}>
          <Close sx={{ color: 'common.black' }} />
        </IconButton>
      )}
      {title && (
        <DialogTitle sx={{ fontWeight: '600', textAlign: 'center', padding: '4px' }}>
          {title}
        </DialogTitle>
      )}
      {description && (
        <DialogContent sx={{ textAlign: 'center', overflow: 'hidden' }}>
          {description}
        </DialogContent>
      )}
      {children && <DialogContent sx={{ padding: '10px !important' }}>{children}</DialogContent>}
      <DialogActions
        sx={{
          justifyContent: 'center',
          gap: '10px',
          marginTop: '20px',
        }}
      >
        {buttons &&
          buttons.map((buttonAttributes) => {
            const { name, variant, handler, isLoading } = buttonAttributes;
            return (
              <>
                <Tooltip
                  title={
                    disableCTA && tooltip?.message && tooltip?.buttonName === name
                      ? tooltip?.message
                      : ''
                  }
                  placement="top"
                >
                  <span>
                    <Button
                      key={name}
                      disabled={
                        disableCTA || isLoading ? name !== 'CANCEL' && name !== 'BACK' : false
                      }
                      startIcon={isLoading && <CircularProgress size={15} />}
                      variant={variant}
                      onClick={handler}
                      size="small"
                      sx={{ padding: '4px 30px', fontSize: '0.75rem' }}
                    >
                      {name}
                    </Button>
                  </span>
                </Tooltip>
              </>
            );
          })}
      </DialogActions>
    </Dialog>
  );
}
