import { useEffect } from 'react';

import { EventStream, WebSocketResponseByType, WebSocketResponseTypes } from 'types';

import { socketConnection } from '../contexts/WebSocketContext';

type EventUpdateDetails = WebSocketResponseByType<WebSocketResponseTypes.EVENT_STREAM_UPDATES>;

export const useStreamUpdated = (
  eventId: string,
  onUpdate: (stream: EventStream) => void
): void => {
  useEffect(() => {
    socketConnection.on('message', (data) => {
      try {
        const {
          type,
          details: { eventId: wsEventId, stream },
        } = JSON.parse(JSON.stringify(data)) as EventUpdateDetails;
        if (type === WebSocketResponseTypes.EVENT_STREAM_UPDATES && eventId === wsEventId) {
          onUpdate(stream);
        }
      } catch (err) {}
    });

    return () => {
      socketConnection.off('message');
    };
  }, []);
};
