import { MarkerType, Position, XYPosition } from 'reactflow';
import { graphlib as graphLib, layout as graphLayout } from 'dagre';
import { addDays, isAfter, isBefore, subDays } from 'date-fns';
import _ from 'lodash';
import theme from 'themes/baseTheme';

import { grey } from '@mui/material/colors';

import { EventStreamStatus } from 'constants/StreamConstants';

import {
  APIEvent,
  Bitrates,
  Channel,
  CustomFeedGraphNodeType,
  EventTag,
  EventType,
  FeedGraph,
  FeedGraphEdge,
  FeedGraphNode,
  OutputTag,
  OutputType,
  SingleAPIEvent,
  SourceType,
  TransformApiEventsType,
} from 'types';

import { formatUTCTimeToDate } from './helpers';

export const transformApiEvents = (
  events: APIEvent[],
  fromDate?: Date,
  toDate?: Date
  // selectedDate?: Date
): TransformApiEventsType[] => {
  const groups = events.reduce((groups, event: APIEvent) => {
    const eventDate = formatUTCTimeToDate(event.scheduledTime);

    const dayBefore = fromDate
      ? formatUTCTimeToDate(subDays(fromDate, 1))
      : formatUTCTimeToDate(subDays(new Date(), 1));

    const dayAfter = toDate
      ? formatUTCTimeToDate(addDays(toDate, 1))
      : formatUTCTimeToDate(addDays(new Date(), 1));

    if (
      (isAfter(new Date(eventDate), new Date(dayBefore)) || eventDate === dayBefore) &&
      (isBefore(new Date(eventDate), new Date(dayAfter)) || eventDate === dayAfter)
    ) {
      if (!groups[eventDate]) {
        groups[eventDate] = [];
      }
      groups[eventDate].push(event);
    }
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupEvents = Object.keys(groups).map((scheduledTime) => {
    return {
      scheduledTime,
      events: groups[scheduledTime],
    };
  });
  const sortEvents = sortEventByDate(groupEvents);
  return sortEvents;
};

const sortEventByDate = (events) => {
  return events.sort(
    (a, b) => new Date(a.scheduledTime).valueOf() - new Date(b.scheduledTime).valueOf()
  );
};

export const getDefaultChannel = (): Channel => {
  return {
    Id: '-',
    Name: '-',
    group: '-',
    State: '-',
  };
};

// export const isDefaultSourceType = (sourceType: string) => {
//   return [SourceType.ELEMENTAL_LINK, SourceType.SLATE_INPUT].includes(sourceType as SourceType);
// };

// export const isDefaultOutputType = (outputType: string) => {
//   return [OutputType.HLS].includes(outputType as OutputType);
// };

export const areAllObjectValuesValid = (obj: Record<string, any>) => {
  return Object.entries(obj).every(([key, value]) => {
    // Object with no specifications
    if (key === 'index') {
      return true;
    }
    return Boolean(value);
  });
};

const basePosition: XYPosition = { x: 0, y: 0 };
export const GRAPH_NODE_DIMENSIONS = {
  [CustomFeedGraphNodeType.SOURCE_NODE]: {
    width: 290,
    height: 170,
  },
  [CustomFeedGraphNodeType.SWITCH_NODE]: {
    width: 300,
    height: 70,
  },
  [CustomFeedGraphNodeType.CONTROL_NODE]: {
    width: 110,
    height: 110,
  },
  [CustomFeedGraphNodeType.OUTPUT_NODE]: {
    width: 360,
    height: 220,
  },
};
export const DefaultEdgeStyles = {
  style: { stroke: grey[400], strokeWidth: '2.5px' },
  markerEnd: { type: MarkerType.Arrow, width: 25, height: 25, color: grey[400] },
};
export const AnimatedEdgeStyles = {
  style: { stroke: theme.palette.primary.main, strokeWidth: '3px' },
  markerEnd: { type: MarkerType.Arrow, width: 25, height: 25, color: theme.palette.primary.main },
};

const hasSingleSource = (event: SingleAPIEvent) => {
  if (event?.sources?.length <= 2) {
    return true;
  }
  return false;
};

export const transformEventIntoGraph = ({
  event,
  onSwitchToggle,
  onControlClick,
  broadcasterIp = '',
}: {
  event: SingleAPIEvent;
  onSwitchToggle;
  onControlClick;
  broadcasterIp?: string;
}): FeedGraph => {
  const nodes: FeedGraphNode[] = [];
  const edges: FeedGraphEdge[] = [];

  const controlNode: FeedGraphNode = {
    id: CustomFeedGraphNodeType.CONTROL_NODE,
    type: CustomFeedGraphNodeType.CONTROL_NODE,
    position: basePosition,
    draggable: false,
    connectable: false,
    data: {
      ...event.stream,
      eventId: event.id,
      onClick: onControlClick,
    },
  };

  event?.sources?.forEach((source) => {
    // source nodes
    if (source.type !== SourceType.ZIXI_MULTI) {
      const transformedSourceNode: FeedGraphNode = {
        id: source.id,
        position: basePosition,
        type: CustomFeedGraphNodeType.SOURCE_NODE,
        data: {
          ...source,
          broadcasterIp,
        },
        draggable: false,
        connectable: false,
      };
      // switch nodes
      const transformedSwitchNode: FeedGraphNode = {
        id: `${source.id}-${CustomFeedGraphNodeType.SWITCH_NODE}`,
        position: basePosition,
        type: CustomFeedGraphNodeType.SWITCH_NODE,
        data: {
          ...source,
          stream: event.stream,
          isSwitchedOn:
            validEdgeToAnimate(event) &&
            event.stream.selectedSourceId &&
            source.id === event.stream.selectedSourceId
              ? true
              : false,
          isSingleSource: hasSingleSource(event),
          onClick: onSwitchToggle,
        },
        draggable: false,
        connectable: false,
      };
      nodes.push(transformedSourceNode);
      nodes.push(transformedSwitchNode);
      edges.push({
        id: `${transformedSourceNode.id}-${transformedSwitchNode.id}`,
        source: transformedSourceNode.id,
        target: transformedSwitchNode.id,
        animated:
          validEdgeToAnimate(event) &&
          event.stream.selectedSourceId ===
            transformedSwitchNode.id.replace(`-${transformedSwitchNode.type}`, '') &&
          [EventStreamStatus.SOURCE_SELECTED, EventStreamStatus.STARTED].includes(
            event.stream.status
          )
            ? true
            : false,
        style:
          validEdgeToAnimate(event) &&
          event.stream.selectedSourceId ===
            transformedSwitchNode.id.replace(`-${transformedSwitchNode.type}`, '')
            ? { ...AnimatedEdgeStyles.style }
            : { ...DefaultEdgeStyles.style },
        markerEnd:
          validEdgeToAnimate(event) &&
          event.stream.selectedSourceId ===
            transformedSwitchNode.id.replace(`-${transformedSwitchNode.type}`, '')
            ? { ...AnimatedEdgeStyles.markerEnd }
            : { ...DefaultEdgeStyles.markerEnd },
      });
      edges.push({
        id: `${transformedSwitchNode.id}-${CustomFeedGraphNodeType.CONTROL_NODE}`,
        source: transformedSwitchNode.id,
        target: CustomFeedGraphNodeType.CONTROL_NODE,
        animated:
          validEdgeToAnimate(event) &&
          event.stream.selectedSourceId ===
            transformedSwitchNode.id.replace(`-${transformedSwitchNode.type}`, '') &&
          [EventStreamStatus.SOURCE_SELECTED, EventStreamStatus.STARTED].includes(
            event.stream.status
          )
            ? true
            : false,
        style:
          validEdgeToAnimate(event) &&
          event.stream.selectedSourceId ===
            transformedSwitchNode.id.replace(`-${transformedSwitchNode.type}`, '')
            ? { ...AnimatedEdgeStyles.style }
            : { ...DefaultEdgeStyles.style },
        markerEnd:
          validEdgeToAnimate(event) &&
          event.stream.selectedSourceId ===
            transformedSwitchNode.id.replace(`-${transformedSwitchNode.type}`, '')
            ? { ...AnimatedEdgeStyles.markerEnd }
            : { ...DefaultEdgeStyles.markerEnd },
      });
    }
  });

  // control node
  nodes.push(controlNode);

  event?.outputs
    ?.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
    .forEach((output) => {
      // output nodes
      if (output.type !== OutputType.COMMONCHANNEL) {
        const transformedOutputNode: FeedGraphNode = {
          id: output.id,
          position: basePosition,
          type: CustomFeedGraphNodeType.OUTPUT_NODE,
          data: {
            ...output,
            broadcasterIp,
            season: event?.season,
          },
          draggable: false,
          connectable: false,
        };

        nodes.push(transformedOutputNode);
        edges.push({
          id: `${CustomFeedGraphNodeType.CONTROL_NODE}-${transformedOutputNode.id}`,
          source: CustomFeedGraphNodeType.CONTROL_NODE,
          target: transformedOutputNode.id,
          animated:
            validEdgeToAnimate(event) &&
            event.stream.selectedSourceId &&
            [EventStreamStatus.SOURCE_SELECTED, EventStreamStatus.STARTED].includes(
              event.stream.status
            )
              ? true
              : false,
          style:
            validEdgeToAnimate(event) && event.stream.selectedSourceId
              ? { ...AnimatedEdgeStyles.style }
              : { ...DefaultEdgeStyles.style },
          markerEnd:
            validEdgeToAnimate(event) && event.stream.selectedSourceId
              ? { ...AnimatedEdgeStyles.markerEnd }
              : { ...DefaultEdgeStyles.markerEnd },
        });
      }
    });

  return { id: event.id, nodes, edges };
};

const validEdgeToAnimate = (event: SingleAPIEvent) => {
  const { hasFailed, isQueued, status } = event.stream;
  return (
    !(hasFailed && !isQueued && status === EventStreamStatus.SOURCE_SELECTED) &&
    !(!hasFailed && isQueued && status === EventStreamStatus.STOPPED)
  );
};
export const calculateGraphNodeHorizontalSeperation = (event: SingleAPIEvent) => {
  if (event.sources.length >= 5) {
    return 500;
  }

  return 300;
};

export const calculateLayoutForGraph = ({
  nodes,
  edges,
  horizontalSep,
}: Pick<FeedGraph, 'nodes' | 'edges'> & { horizontalSep: number }): Pick<
  FeedGraph,
  'nodes' | 'edges'
> => {
  const dagreGraph = new graphLib.Graph({ directed: true });
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: 'LR',
    // align: 'DR',
    ranksep: horizontalSep,
    nodesep: 100, //To increase the space between nodes(verically)
    edgesep: 0,
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: 300,
      height: node.type === CustomFeedGraphNodeType.OUTPUT_NODE ? 270 : 220,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  graphLayout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    // make this dynamic based on graph rank for mobile devices
    node.targetPosition = Position.Left;
    node.sourcePosition = Position.Right;
    const nodeDims = GRAPH_NODE_DIMENSIONS[node.type];

    if (nodeWithPosition) {
      node.position = {
        x: nodeWithPosition.x - nodeDims.width / 2,
        y: nodeWithPosition.y - nodeDims.height / 2,
      };
    }

    return node;
  });

  return { nodes, edges };
};

export function checkObjectNotEmpty(obj) {
  if (_.isArray(obj)) {
    if (_.isObject(obj[0])) {
      return _.every(obj, (value) => checkObjectNotEmpty(value));
    }
    if (!_.isObject(obj[0])) {
      return obj.length > 0;
    }
  }
  if (_.isObject(obj)) {
    return _.every(obj, (value) => checkObjectNotEmpty(value));
  }
  return obj !== '' && obj !== 0 && !_.isEmpty(obj);
}

export const deleteSlate = (id: string, selectedSources, setSelectedSources) => {
  const slateInputs = selectedSources.filter((slate) => slate.slateId !== id);
  setSelectedSources((sources) => {
    return {
      ...sources,
      SLATE_INPUT: slateInputs,
    };
  });
};

export const deleteDevice = (id: string, selectedSources, setSelectedSources) => {
  const devices = selectedSources.filter((device) => device.deviceId !== id);
  setSelectedSources((sources) => {
    return {
      ...sources,
      ELEMENTAL_LINK: devices,
    };
  });
};

export const hasEventStreamed = (event: APIEvent): boolean => {
  return !!event.stream?.duration;
};

export const isConsistentStateToDelete = (event: APIEvent): boolean => {
  const { hasFailed, isQueued, status } = event.stream;
  return (
    ([EventStreamStatus.NON_EXISTENT, EventStreamStatus.DESTROYED].includes(status) &&
      !hasFailed &&
      !isQueued) ||
    (status === EventStreamStatus.CREATED && hasFailed && !isQueued)
  );
};

export const eventsTypeOptions: EventType[] = Object.values(EventTag).map((tag) => ({
  value: tag,
  label: tag,
}));

export const outputTypeOptions: EventType[] = Object.values(OutputTag).map((type) => ({
  value: type,
  label: type,
}));

export const bitRateOptions: EventType[] = Object.entries(Bitrates).map(([key, value]) => ({
  label: key,
  value: value,
}));
