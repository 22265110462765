import { StepType } from '@reactour/tour';

export type UserTourStepContent = {
  title: string;
  // renders as individual paragraph if a string array is passed
  content: string | string[];
};

const formatToClass = (str) => `.${str}`;

// first add a key here
export enum UserTourStepKey {
  'WELCOME' = 'WELCOME',
  'SHOW_INPUTS' = 'SHOW_INPUTS',
  'SHOW_SWITCHES' = 'SHOW_SWITCHES',
  'SHOW_PLAY_BTN' = 'SHOW_PLAY_BTN',
  'SHOW_OUTPUTS' = 'SHOW_OUTPUTS',
  'CREATE_STREAM' = 'CREATE_STREAM',
  'DESTROY_STREAM' = 'DESTROY_STREAM',
  'PUBLISH' = 'PUBLISH',
}

// then add a selector here, make sure to use the same selctor as class on DOM or JSX
export const UserTourClassSelector: Record<UserTourStepKey, string> = {
  [UserTourStepKey.WELCOME]: 'control-center-cont',
  [UserTourStepKey.SHOW_INPUTS]: 'inputs-section',
  [UserTourStepKey.SHOW_SWITCHES]: 'switches-section',
  [UserTourStepKey.SHOW_PLAY_BTN]: 'play-btn-section',
  [UserTourStepKey.SHOW_OUTPUTS]: 'outputs-section',
  [UserTourStepKey.CREATE_STREAM]: 'create-stream-btn',
  [UserTourStepKey.DESTROY_STREAM]: 'create-stream-btn',
  [UserTourStepKey.PUBLISH]: 'publish-btn',
};

// then add the actual content here
export const UserTourSteps: Record<UserTourStepKey, UserTourStepContent> = {
  [UserTourStepKey.WELCOME]: {
    title: 'Welcome to Control Center!',
    content:
      'Control Center is the dashboard for your Event. You can monitor and control your event streams, channels, inputs and outputs with ease.',
  },
  [UserTourStepKey.CREATE_STREAM]: {
    title: 'Create/Destroy/Reset Stream',
    content: [
      'The "Create/Destroy/Reset" Stream button takes care of creating and deleting the channel for your event.',
      'Creating the stream resources is the first step before doing any of the event stream operations.',
    ],
  },
  [UserTourStepKey.SHOW_INPUTS]: {
    title: 'Inputs Section',
    content: [
      'The Inputs Section contains mainly the slate & link device used for this event.',
      'The device input thumbnail refreshes periodically with the latest data.',
    ],
  },
  [UserTourStepKey.SHOW_SWITCHES]: {
    title: 'Switches Section',
    content: [
      'Each input is virtually connected to a switch, to enable an input just click on the respective switch. At any point of time, only one switch/input will be enabled.',
      'Make sure to start stream before switching inputs.',
      'You can also switch inputs while the event is streaming.',
    ],
  },
  [UserTourStepKey.SHOW_PLAY_BTN]: {
    title: 'Stream Control Button',
    content:
      'The Stream Control button pauses/resumes your event stream. This button will be enabled only if the stream is created. It might take about 5 mins to start streaming.',
  },
  [UserTourStepKey.SHOW_OUTPUTS]: {
    title: 'Output Section',
    content:
      'Your Event will stream to respective output destinations. The thumbnail of the output feed will be updated periodically with the latest data.',
  },
  [UserTourStepKey.DESTROY_STREAM]: {
    title: 'Destroy/Reset Stream',
    content: [
      'Always ensure to destory stream after your event is over. This button deletes and cleans up the channel created for your event.',
      'If you want to restart/recreate the stream, click on the "Reset Stream" button, then you will be prompted to create stream from the start.',
    ],
  },
  [UserTourStepKey.PUBLISH]: {
    title: 'Update Brightcove',
    content: [
      'The Update Brightcove button publishes your HLS output to brightcove.',
      'The button will be enabled only if the output destinations has HLS URL configured.',
    ],
  },
};

// then combine everything into array that is processable by @react-tour library, ordering matters here
export const ControlCenterTour: StepType[] = [
  {
    selector: formatToClass(UserTourClassSelector.WELCOME),
    content: UserTourStepKey.WELCOME,
    position: 'center',
  },
  {
    selector: formatToClass(UserTourClassSelector.CREATE_STREAM),
    content: UserTourStepKey.CREATE_STREAM,
    position: 'bottom',
  },
  {
    selector: formatToClass(UserTourClassSelector.PUBLISH),
    content: UserTourStepKey.PUBLISH,
    position: 'bottom',
  },
  {
    selector: formatToClass(UserTourClassSelector.SHOW_INPUTS),
    content: UserTourStepKey.SHOW_INPUTS,
    position: 'right',
  },
  {
    selector: formatToClass(UserTourClassSelector.SHOW_PLAY_BTN),
    content: UserTourStepKey.SHOW_PLAY_BTN,
    position: 'top',
  },
  {
    selector: formatToClass(UserTourClassSelector.SHOW_SWITCHES),
    content: UserTourStepKey.SHOW_SWITCHES,
    position: 'right',
  },
  {
    selector: formatToClass(UserTourClassSelector.SHOW_OUTPUTS),
    content: UserTourStepKey.SHOW_OUTPUTS,
    position: 'left',
  },
  {
    selector: formatToClass(UserTourClassSelector.DESTROY_STREAM),
    content: UserTourStepKey.DESTROY_STREAM,
    position: 'bottom',
  },
];
