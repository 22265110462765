import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { ActiveViewHeader } from 'components/Common/ActiveViewHeader';
import { Center } from 'components/Common/Centered';
import { CustomDataGrid } from 'components/Common/CustomDataGrid';
import { AddMemberPopUp } from 'components/PopUp/AddMemberPopUp';
import { TeamMemberActions } from 'components/Team/TeamMemberActions';

import { ActiveMenuContext } from 'contexts/ActiveMenuContext';

import { UserRole } from 'constants/AuthConstants';
import { ROUTE_KEY } from 'constants/RouteConstants';

import { User } from 'types';

import { getAllUsers } from '../services/userService';

function ManageTeams(): JSX.Element {
  const { setActiveMenu } = useContext(ActiveMenuContext);
  const [addMemberPopUpState, setAddMemberPopUpState] = useState(false);

  useEffect(() => {
    setActiveMenu(ROUTE_KEY.MANAGE_TEAMS);
  }, []);

  const columns: GridColDef<User>[] = [
    {
      field: 'fullName',
      flex: 0.2,
      headerName: 'Full Name',
      minWidth: 200,
      sortable: true,
      renderCell: (params) => {
        const { row } = params;
        const { role, firstName, lastName } = row;
        const disable = role === UserRole.UNASSIGNED;
        if (disable) {
          return (
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              {firstName} {lastName}
            </Typography>
          );
        }
        return (
          <Typography variant="body2">
            {firstName} {lastName}
          </Typography>
        );
      },
      valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: 'email',
      flex: 0.25,
      headerName: 'Email',
      minWidth: 200,
      renderCell: (params) => {
        const { row } = params;
        const { email, role } = row;
        const disable = role === UserRole.UNASSIGNED;
        if (disable) {
          return (
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              {email}
            </Typography>
          );
        }
        return <Typography variant="body2">{email}</Typography>;
      },
    },
    {
      field: 'group',
      flex: 0.1,
      headerName: 'Group',
      minWidth: 80,
      renderCell: (params) => {
        const { row } = params;
        const { group, role } = row;
        const disable = role === UserRole.UNASSIGNED;
        if (disable) {
          return (
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              {group}
            </Typography>
          );
        }
        return <Typography variant="body2">{group || '-'}</Typography>;
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 150,
      flex: 0.15,
      renderCell: (params) => {
        const { row } = params;
        const { role } = row;
        const disable = role === UserRole.UNASSIGNED;
        if (disable) {
          return (
            <Typography variant="body2" sx={{ opacity: 0.5 }}>
              {role}
            </Typography>
          );
        }
        return <Typography variant="body2">{role.replace('_', ' ')}</Typography>;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      minWidth: 300,
      flex: 0.3,
      renderCell: ({ row }) => {
        const { role } = row;
        const disable = role === UserRole.UNASSIGNED;
        return <TeamMemberActions isDisabled={disable} member={row} />;
      },
    },
  ];

  const {
    data: users,
    isFetching,
    refetch: refetchUsers,
  } = useQuery(['users'], () => getAllUsers(), {
    refetchOnWindowFocus: false,
  });

  const renderTable = () => {
    if (isFetching) {
      return (
        <Center width="100%" pt={10}>
          <CircularProgress sx={{ color: 'highlight.underline' }} />
        </Center>
      );
    }

    return users?.length ? (
      <CustomDataGrid rows={users} rowIdKey="email" columns={columns} pageSize={20} showSearchbar />
    ) : (
      <Center width="100%" pt={10}>
        <Typography variant="body1" color="grey.500">
          No team members found
        </Typography>
      </Center>
    );
  };

  return (
    <>
      <Box sx={{ pt: '25px' }}>
        <ActiveViewHeader title="MANAGE TEAM">
          <Button
            onClick={() => setAddMemberPopUpState(true)}
            variant="outlined"
            sx={{ mb: '10px' }}
          >
            Add Member
          </Button>
        </ActiveViewHeader>
        <Box sx={{ backgroundColor: 'common.white', mt: '20px' }}>
          <Grid sx={{ height: '80vh' }}>{renderTable()}</Grid>
        </Box>
      </Box>
      <AddMemberPopUp
        isAddMemberPopUpOpen={addMemberPopUpState}
        close={() => setAddMemberPopUpState(false)}
        triggerRefetch={refetchUsers}
      />
    </>
  );
}

export default ManageTeams;
