import { Edge as GraphEdge, Node as GraphNode } from 'reactflow';

import { AlertColor, ButtonProps } from '@mui/material';

import { UserRole } from 'constants/AuthConstants';
import { FeedNodeStatusType } from 'constants/DeviceStates';
import { EventStreamStatus, StreamActionButtons } from 'constants/StreamConstants';

export type UserRoleTypes = typeof UserRole[keyof typeof UserRole] | keyof typeof UserRole;

export interface EventCreator {
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  group: string;
}

export enum StreamStatus {
  NON_EXISTENT = 'NON_EXISTENT',
  CREATED = 'CREATED',
  SOURCE_SELECTED = 'SOURCE_SELECTED',
  STARTED = 'STARTED',
  STOPPED = 'STOPPED',
  DESTROYED = 'DESTROYED',
}

export enum EventTag {
  GAMES = 'Games',
  'MORNING_SKATE/TEAM_WORKOUT' = 'Morning Skate/Team Workout',
  'NHL_NETWORK/STUDIOS_PRODUCTION' = 'NHL Network/Studios Production',
  'POST-GAME_PRESS_CONFERENCE/SHOW' = 'Post-Game Press Conference/Show',
  'PRE-GAME_PRESS_CONFERENCE/SHOW' = 'Pre-Game Press Conference/Show',
  PRESS_CONFERENCE = 'Press Conference',
  'ROOKIE/PROSPECT_TOURNAMENT' = 'Rookie/Prospect Tournament',
  'SPECIAL_EVENT-NON-NHL' = 'Special Event - Non-NHL',
  TEAM_PRODUCTION = 'Team Production',
  OTHERS = 'Others',
}

export enum OutputTag {
  YOUTUBE = 'YouTube',
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter/X',
  TIKTOK = 'TikTok',
  INSTAGRAM = 'Instagram',
  NHL = 'NHL.com',
  VIMEO = 'Vimeo',
  MOBILE = 'Mobile App',
  OTHERS = 'Others',
}

export enum Bitrates {
  '5 Mbps' = '5',
  '10 Mbps' = '10',
  '20 Mbps' = '20',
  '40 Mbps' = '40',
  '100 Mbps' = '100',
  '150 Mbps' = '150',
  '250 Mbps' = '250',
}

export enum EventStatus {
  CREATED = 'CREATED',
  SOURCE_SELECTED = 'SOURCE_SELECTED',
  STARTED = 'STARTED',
  STOPPED = 'STOPPED',
}

export type APIEvent = {
  id: string;
  name: string;
  type: 'EVENT';
  scheduledTime: string;
  channelId: string;
  channel?: Channel;
  sourceId?: string;
  stream: EventStream;
  group: string;
  createdBy: EventCreator;
  status: EventStatus;
  tag?: EventTag | string;
  season?: string;
};

export type SingleAPIEvent = {
  id: string;
  name: string;
  type: 'EVENT';
  scheduledTime: string;
  sources: Source[];
  stream: EventStream;
  outputs: Output[];
  group: string;
  createdBy: EventCreator;
  status: EventStatus;
  tag?: EventTag | string;
  season?: string;
};

export type AnalyticsResponse = APIEvent & { destinations: string[] };

export type AWSResource<T = unknown> = {
  id: string;
  type: AWSResourceType;
  group: string;
  details: T;
  updatedAt?: number;
  region: string;
  status: EntityStatus;
};

export type Slate = {
  id: string;
  name: string;
  group: string;
  url: string;
  type: SlateType;
};

export type presignedUrlRequest = {
  filename: string;
  group: string;
  caller: string;
};

export enum SlateType {
  'video/mp2t' = 'TS_FILE',
}

export type ChannelState =
  | 'CREATING'
  | 'CREATE_FAILED'
  | 'IDLE'
  | 'STARTING'
  | 'RUNNING'
  | 'RECOVERING'
  | 'STOPPING'
  | 'DELETING'
  | 'DELETED'
  | 'UPDATING'
  | 'UPDATE_FAILED'
  | string;

export type Channel = {
  Id: string;
  Name: string;
  // has to be changed
  State: ChannelState;
  group: string;
  updatedAt?: number;
};

export type TransformApiEventsType = {
  scheduledTime: string;
  events: APIEvent[];
};

export type EventRequestQueryParam = Partial<{
  startDate: string;
  endDate: string;
  operatorEmail: string;
  date: string | number;
}>;

export type User = {
  firstName: string;
  lastName: string;
  role: UserRole;
  email: string;
  group?: string;
};

export type CognitoUser = {
  'custom:first_name': string;
  'custom:last_name': string;
  email: string;
  role: UserRoleTypes;
  group: string;
};

export interface EventStream {
  status: EventStreamStatus;
  isQueued: boolean;
  hasFailed: boolean;
  updatedAt?: number;
  selectedSourceId?: string;
  streamStartedAt?: number;
  streamEndedAt?: number;
  streamCreatedAt?: number;
  createdBy?: User;
  duration?: number;
}

export interface PopUpButtonProps {
  isLoading?: boolean;
  name: string;
  variant: Pick<ButtonProps, 'variant'>['variant'];
  handler: (param?: any) => void | Promise<void>;
}

export interface TooltipProps {
  buttonName: string;
  message: string;
}

export enum ActivityType {
  CREATE = 'create',
  START = 'start',
  STOP = 'stop',
  DELETE = 'delete',
  UPDATE = 'update',
  SWITCH_INPUT = 'switch_input',
}

export type Activity = {
  eventId: string;
  timestamp: number;
  group: string;
  message: string;
  causedBy?: string;
  type: ActivityType;
  additionalInfo: AdditionalEventInfo;
};

export type AdditionalEventInfo = {
  eventName: string;
  eventScheduledTime: string;
};

export type BasicEventInfo = {
  id: string;
  name?: string;
};

export type GroupInfo = {
  id: string;
  name: string;
  logo: string;
  videoIds: Record<string, string>;
  outputUrl?: string;
};

export type ToastOptions = {
  type: AlertColor;
  message: string;
};

export type EventType = {
  label: string;
  value: string;
};

export interface FilterEventsWithGroup {
  group: string;
}
export interface FilterEventsWithRange extends Partial<FilterEventsWithGroup> {
  startDate: string;
  endDate: string;
}

export interface FilterEventsWithDate extends Partial<FilterEventsWithGroup> {
  date: string;
}

export type EventRequestQueryParams = FilterEventsWithRange | FilterEventsWithDate;

export type CreateEventPayload = Omit<
  APIEvent,
  'channel' | 'createdBy' | 'status' | 'channelId' | 'stream' | 'sourceId'
> & {
  sources: {
    id: string;
    eventId: string;
    type: string;
    group: string;
    specifications: Record<string, string>;
    resources: [];
  }[];
  outputs: {
    id: string;
    eventId: string;
    type: string;
  }[];
};

export type UpdateEventPayload = Omit<
  APIEvent,
  'channel' | 'createdBy' | 'status' | 'channelId' | 'stream' | 'sourceId'
> & {
  sources: {
    id: string;
    eventId: string;
    type: string;
    group: string;
    specifications: Record<string, string>;
    resources: [];
  }[];
  outputs: {
    id: string;
    eventId: string;
    type: string;
    specifications: Record<string, string>;
  }[];
};

export interface DeleteEventPayload {
  eventId: string;
}

export type SourceBase = {
  type: SourceType;
  eventId: string;
  id: string;
  name: string;
  updatedAt?: number;
  group: string;
  details: any;
  status?: EntityStatus;
  specifications: Record<string, number | string>;
};

export enum SourceType {
  SLATE_INPUT = 'SLATE_INPUT',
  ELEMENTAL_LINK = 'ELEMENTAL_LINK',
  RTMP = 'RTMP',
  SRT = 'SRT',
  ZIXI = 'ZIXI',
  ZIXI_MULTI = 'ZIXI_MULTI',
}

export enum AWSResourceType {
  FLOW = 'FLOW',
  CHANNEL = 'CHANNEL',
  MEDIALIVE_INPUT = 'MEDIALIVE_INPUT',
  MEDIALIVE_DEVICE = 'MEDIALIVE_DEVICE',
  ZIXI_TARGET = 'ZIXI_TARGET',
  ZIXI_CHANNEL = 'ZIXI_CHANNEL',
  ZIXI_SOURCE = 'ZIXI_SOURCE',
}

export interface MedialiveInputDevice {
  ConnectionState?: 'DISCONNECTED' | 'CONNECTED' | string;
  DeviceSettingsSyncState?: 'SYNCED' | 'SYNCING' | string;
  DeviceUpdateStatus?: 'UP_TO_DATE' | 'NOT_UP_TO_DATE' | string;
  HdDeviceSettings?: unknown;
  UhdDeviceSettings?: unknown;
  MacAddress?: string;
  NetworkSettings?: {
    IpAddress?: string;
    [key: string]: unknown;
  };
  Type?: 'HD' | string;
}

export type Source<T = any> =
  | ({ type: SourceType.SLATE_INPUT; details?: T } & SourceBase)
  | ({ type: SourceType.ELEMENTAL_LINK; details?: MedialiveInputDevice } & SourceBase)
  | ({ type: SourceType.RTMP; details?: any } & SourceBase)
  | ({ type: SourceType.SRT; details?: any } & SourceBase)
  | ({ type: SourceType.ZIXI; details?: any } & SourceBase)
  | ({ type: SourceType.ZIXI_MULTI; details?: any } & SourceBase);

export type SourceByType<SourceType, T = unknown> = Extract<Source<T>, { type: SourceType }>;

export type Output = {
  type: OutputType;
  specifications?: Record<string, number | string>;
  id: string;
  eventId: string;
  status?: EntityStatus;
  group: string;
  tag?: string;
};

export enum OutputType {
  HLS = 'HLS',
  SRT = 'SRT',
  RTMP = 'RTMP',
  COMMONCHANNEL = 'COMMONCHANNEL',
}

export enum ActionButtonState {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  HIDDEN = 'HIDDEN',
  LOADING = 'LOADING',
}

export type EntityStatus = {
  value: string;
  type: FeedNodeStatusType | string;
};

export interface StreamActionButtonsStatus {
  visible: StreamActionButtons[];
  loading: StreamActionButtons[];
  disabled: StreamActionButtons[];
}

export type StreamActionButtonsTooltip = Partial<Record<StreamActionButtons, string>>;

export interface ErrorResponse {
  message: string;
  statusCode: number;
  error: string;
}

// #region websocket types
export enum WebSocketActionTypes {
  AUTHENTICATE = 'authenticate',
  EVENT_SUBSCRIBE = 'event_subscribe',
  EVENT_UNSUBSCRIBE = 'event_unsubscribe',
}

export type GenericWebSocketEvent<T> = {
  subscriptionEvent: WebSocketActionTypes;
  body: T;
};

export interface SUBSCRIBE_TO_EVENT_REQUEST {
  eventId: string;
}

export interface UNSUBSCRIBE_TO_EVENT_REQUEST {
  eventId: string;
}

export type WebSocketEventRequest =
  | {
      subscriptionEvent: WebSocketActionTypes.EVENT_SUBSCRIBE;
      body: SUBSCRIBE_TO_EVENT_REQUEST;
    }
  | {
      subscriptionEvent: WebSocketActionTypes.EVENT_UNSUBSCRIBE;
      body: UNSUBSCRIBE_TO_EVENT_REQUEST;
    };

export interface WEBSOCKET_AUTHENTICATION_REQUEST {
  token: string;
}

export enum WebSocketResponseTypes {
  EVENT_UPDATES = 'EVENT_UPDATES',
  DEVICE_PREVIEW_UPDATE = 'DEVICE_PREVIEW_UPDATE',
  EVENT_STREAM_UPDATES = 'EVENT_STREAM_UPDATES',
  SLATE_ACTIVITY = 'SLATE_ACTIVITY',
}

export type WebSocketResponse =
  | {
      type: WebSocketResponseTypes.EVENT_UPDATES;
      details: { eventId: string; sources: SourceBase[]; outputs: Output[] };
    }
  | {
      type: WebSocketResponseTypes.DEVICE_PREVIEW_UPDATE;
      updatedDevicePreview: [DevicePreview, SourceBase];
    }
  | {
      type: WebSocketResponseTypes.EVENT_STREAM_UPDATES;
      details: { eventId: string; stream: EventStream };
    };

export type WebSocketResponseByType<T extends WebSocketResponseTypes> = Extract<
  WebSocketResponse,
  { type: T }
>;

export type DevicePreview = {
  Body: { data: number[] };
  ContentType: 'image/jpeg' | string;
} & { url: string } & {
  Body: string;
  ContentType: 'image/jpeg' | string;
};

// #region graph types
export enum CustomFeedGraphNodeType {
  SOURCE_NODE = 'SOURCE_NODE',
  SWITCH_NODE = 'SWITCH_NODE',
  CONTROL_NODE = 'CONTROL_NODE',
  OUTPUT_NODE = 'OUTPUT_NODE',
}

// feed node data based on node type
export type FeedGraphSourceNodeData = Omit<Source, 'id'> & { broadcasterIp?: string };
export type FeedGraphSwitchNodeData = Source & {
  onClick: (id: string, switchStatus: boolean) => void;
  isSwitchedOn: boolean;
  isSingleSource: boolean;
  stream: EventStream;
  broadcasterIp: string;
};
export type FeedGraphControlNodeData = EventStream & {
  onClick: (state: EventStreamStatus, sourceId: string) => void;
  eventId: string;
};
export type FeedGraphOutputNodeData = Omit<Output, 'id'> & {
  broadcasterIp?: string;
  season?: string;
};

// group
export type FeedGraphNodeData =
  | FeedGraphSourceNodeData
  | FeedGraphSwitchNodeData
  | FeedGraphControlNodeData
  | FeedGraphOutputNodeData;
export type FeedGraphNode = GraphNode<FeedGraphNodeData>;
export type FeedGraphEdge = GraphEdge;

// final type for feed
export interface FeedGraph {
  id: string;
  nodes: FeedGraphNode[];
  edges: FeedGraphEdge[];
}
// #endregion

// #region resource types
export interface AWSMedialiveInput {
  Id?: string;
  Name?: string;
  RoleArn?: string;
  SecurityGroups?: string[];
  Sources?: any;
  State?: string;
  Tags?: any;
  Type?: string;
  Arn?: string;
  AttachedChannels?: string[];
  Destinations?: any;
  InputClass?: string;
  InputDevices?: { Id: string }[];
  MediaConnectFlows?: { FlowArn: string }[];
  InputPartnerIds?: string[];
}

export interface AWSMedialiveDevice {
  Id: string;
  Name: string;
  Type: string;
  region: string;
  cameraAngle: string;

  Arn?: string;
  ConnectionState?: 'DISCONNECTED' | 'CONNECTED' | string;
  DeviceSettingsSyncState?: string;
  DeviceUpdateStatus?: string;
  HdDeviceSettings?: any;
  MacAddress?: string;
  NetworkSettings?: any;
  SerialNumber?: string;
  UhdDeviceSettings?: any;
}

export interface AWSChannel {
  Id: string;
  Name: string;

  Arn?: string;
  CdiInputSpecification?: any;
  ChannelClass?: string;
  Destinations?: any;
  EgressEndpoints?: any;
  InputAttachments?: any;
  InputSpecification?: any;
  LogLevel?: any;
  RoleArn?: string;
  State?: string;
  Tags?: any;
  Vpc?: any;
}

export interface AWSFlow {
  AvailabilityZone: string;
  Description: string;
  FlowArn: string;
  Name: string;
  SourceType: string;
  Status: string;
}

// #endregion
