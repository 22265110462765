import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { calenderTheme } from 'themes/calenderTheme';

import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  Stack,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { EventActivity } from 'components/Activity/EventActivity';
import { ActiveViewHeader } from 'components/Common/ActiveViewHeader';

import { EventActivityService } from 'services/EventActivityService';

import { ActiveMenuContext } from 'contexts/ActiveMenuContext';
import { useToaster } from 'contexts/ToasterContext';

import { DATE_FORMAT } from 'constants/CommonConstants';
import { ROUTE_KEY } from 'constants/RouteConstants';

import { Activity, BasicEventInfo } from 'types';

const customInput = (props) => {
  return (
    <Tooltip placement="bottom" title={DATE_FORMAT.toLocaleLowerCase()}>
      <TextField {...props} size="small" />
    </Tooltip>
  );
};

const ActivityLog = (): JSX.Element => {
  const { setActiveMenu } = useContext(ActiveMenuContext);
  const { triggerToast } = useToaster();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState<BasicEventInfo>();
  const [eventList, setEventList] = useState<BasicEventInfo[]>([]);
  const getEventActivityLogs = async () => {
    return await EventActivityService.getActivityLogs({
      ...(selectedDate
        ? { date: selectedDate.getTime() + new Date().getTimezoneOffset() * 60000 }
        : {}),
      ...(selectedEvent ? { eventId: selectedEvent.id } : {}),
    });
  };

  const getEventsList = (newActivityLogs) => {
    const allEvents = newActivityLogs.map((activityLog) => {
      return JSON.stringify({
        id: activityLog.eventId,
        name: activityLog?.additionalInfo?.eventName,
      });
    });
    const dulicatesRemovedEvents = Array.from(new Set(allEvents).values()).map((event: string) => {
      return JSON.parse(event) as BasicEventInfo;
    });
    setEventList(dulicatesRemovedEvents);
  };

  const {
    isFetching,
    isError,
    data: activityLogs = [],
    refetch,
  } = useQuery(['activity-logs'], getEventActivityLogs, {
    refetchOnWindowFocus: false,
    onSuccess: (newLogs) => {
      getEventsList(newLogs);
    },
    onError: () => {
      triggerToast({
        type: 'error',
        message: 'Oops! Error loading Activity Logs.',
      });
    },
  });

  useEffect(() => {
    setActiveMenu(ROUTE_KEY.ACTIVITY_LOG);
  }, []);

  useEffect(() => {
    refetch();
  }, [selectedDate, selectedEvent]);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setEventList([]);
  };

  const handleEventSelect = useCallback(
    (event: BasicEventInfo) => {
      setSelectedEvent(event);
    },
    [setSelectedEvent]
  );

  return (
    <Container maxWidth={false} sx={{ pt: '25px' }} disableGutters>
      <ActiveViewHeader title="Activity Log">
        <Stack
          spacing={2}
          mt={{ xs: 2, sm: 1 }}
          direction={{ sm: 'row', xs: 'column' }}
          flexWrap="wrap"
          width={{ xs: '100%', md: 'auto' }}
        >
          <ThemeProvider theme={calenderTheme}>
            <DesktopDatePicker
              label="Filter by Date"
              format={DATE_FORMAT}
              value={selectedDate}
              onChange={handleDateChange}
              closeOnSelect={true}
              slots={{
                textField: customInput,
              }}
            />
          </ThemeProvider>
          <Autocomplete
            disablePortal
            value={selectedEvent}
            options={eventList.filter((event) => event.name)}
            onChange={(_, value: BasicEventInfo) => handleEventSelect(value)}
            size="small"
            getOptionLabel={(value: BasicEventInfo) => value.name}
            renderInput={(params) => <TextField {...params} label="Filter by Event Name" />}
          />
        </Stack>
      </ActiveViewHeader>
      <Box mt={4} position="relative">
        {isFetching ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <CircularProgress />
          </Box>
        ) : isError ? (
          <></>
        ) : activityLogs.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <Typography variant="body1" sx={{ color: 'grey.600' }}>
              No Activity Logs Available!
            </Typography>
          </Box>
        ) : (
          <Stack alignItems="flex-start" spacing={3}>
            {activityLogs
              .sort((a, b) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf())
              .map((activityLog, idx) => {
                return (
                  <Box
                    width="100%"
                    position="relative"
                    key={activityLog.message + activityLog.timestamp}
                    sx={
                      idx !== activityLogs.length - 1 && {
                        '::after': {
                          content: '""',
                          '--offset': 'calc(8px + 30px / 2)',
                          position: 'absolute',
                          borderLeft: '2px solid',
                          borderLeftStyle: 'dashed',
                          borderLeftColor: 'grey.100',
                          width: '0px',
                          height: 'calc(100% + var(--offset))',
                          top: 'var(--offset)',
                          left: 'calc(var(--offset) - 1px)',
                        },
                      }
                    }
                  >
                    <EventActivity activity={activityLog as Activity} />
                  </Box>
                );
              })}
          </Stack>
        )}
      </Box>
    </Container>
  );
};

export default ActivityLog;
