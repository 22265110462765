import { differenceInHours, differenceInMinutes } from 'date-fns';

import {
  DefaultStreamActionButtonsStatus,
  EventStreamStatus,
  StreamActionButtons,
} from 'constants/StreamConstants';

import {
  APIEvent,
  EventStream,
  StreamActionButtonsStatus,
  StreamActionButtonsTooltip,
} from 'types';

type GetStreamButtonStatesReturnType = {
  buttonStates: StreamActionButtonsStatus;
  tooltips: StreamActionButtonsTooltip;
};

export const onStreamNonExistent = (inProgress: boolean): GetStreamButtonStatesReturnType => {
  const buttonStates = {
    visible: [
      inProgress ? StreamActionButtons.RESET : StreamActionButtons.CREATE,
      StreamActionButtons.ATTACH_INPUT,
      StreamActionButtons.START_STREAM,
    ],
    disabled: [StreamActionButtons.ATTACH_INPUT, StreamActionButtons.START_STREAM],
    loading: inProgress ? [StreamActionButtons.RESET] : [],
  };
  const tooltips: StreamActionButtonsTooltip = {};
  tooltips.ATTACH_INPUT = 'Stream should be created to attach input';
  tooltips.START_STREAM = 'Create stream and attach input to start streaming';
  tooltips.RESET = inProgress && 'Please wait till we reset the event';
  return { buttonStates, tooltips };
};

export const onStreamCreated = (
  inProgress: boolean,
  hasFailed: boolean
): GetStreamButtonStatesReturnType => {
  const buttonStates = {
    visible: [
      inProgress || hasFailed ? StreamActionButtons.CREATE : StreamActionButtons.DESTROY,
      StreamActionButtons.ATTACH_INPUT,
      StreamActionButtons.START_STREAM,
    ],
    disabled:
      hasFailed || inProgress
        ? [StreamActionButtons.START_STREAM, StreamActionButtons.ATTACH_INPUT]
        : [StreamActionButtons.START_STREAM],
    loading: inProgress ? [StreamActionButtons.CREATE] : [],
  };
  const tooltips: StreamActionButtonsTooltip = {};
  tooltips.START_STREAM = 'Attach input to start streaming';
  tooltips.ATTACH_INPUT =
    (hasFailed || inProgress) && 'Please wait till until we create the stream';
  tooltips.CREATE = inProgress && 'Stream is being created';
  return { buttonStates, tooltips };
};

export const onStreamSourceSelected = (
  inProgress: boolean,
  hasFailed: boolean
): GetStreamButtonStatesReturnType => {
  const buttonStates = {
    visible: [
      StreamActionButtons.DESTROY,
      StreamActionButtons.ATTACH_INPUT,
      StreamActionButtons.START_STREAM,
    ],
    disabled: inProgress
      ? [
          StreamActionButtons.ATTACH_INPUT,
          StreamActionButtons.START_STREAM,
          StreamActionButtons.DESTROY,
        ]
      : hasFailed
      ? [StreamActionButtons.START_STREAM]
      : [],
    loading: inProgress ? [StreamActionButtons.START_STREAM] : [],
  };
  const tooltips: StreamActionButtonsTooltip = {};
  tooltips.ATTACH_INPUT = inProgress && 'Input is being attached';
  tooltips.START_STREAM =
    (inProgress || hasFailed) && 'Please wait until the input is attached to start streaming';
  tooltips.DESTROY = inProgress && 'Please wait until the input is attached to destroy the stream';
  return { buttonStates, tooltips };
};

export const onStreamStarted = (
  inProgress: boolean,
  hasFailed: boolean
): GetStreamButtonStatesReturnType => {
  const buttonStates = {
    visible: [
      StreamActionButtons.DESTROY,
      StreamActionButtons.ATTACH_INPUT,
      inProgress || hasFailed ? StreamActionButtons.START_STREAM : StreamActionButtons.STOP_STREAM,
    ],
    disabled: inProgress
      ? [StreamActionButtons.ATTACH_INPUT, StreamActionButtons.DESTROY]
      : [StreamActionButtons.DESTROY],
    loading: inProgress ? [StreamActionButtons.START_STREAM] : [],
  };
  const tooltips: StreamActionButtonsTooltip = {};
  tooltips.DESTROY = 'Stop streaming to destroy the stream';
  tooltips.ATTACH_INPUT = inProgress && 'Please wait until the streaming is started';
  tooltips.START_STREAM =
    inProgress &&
    'Please wait until the stream is started. It might take upto 5 mins to start streaming';
  return { buttonStates, tooltips };
};

export const onStreamStopped = (
  inProgress: boolean,
  hasFailed: boolean
): GetStreamButtonStatesReturnType => {
  const buttonStates = {
    visible: [
      StreamActionButtons.DESTROY,
      StreamActionButtons.ATTACH_INPUT,
      inProgress || hasFailed ? StreamActionButtons.STOP_STREAM : StreamActionButtons.START_STREAM,
    ],
    disabled: inProgress ? [StreamActionButtons.DESTROY, StreamActionButtons.ATTACH_INPUT] : [],
    loading: inProgress ? [StreamActionButtons.STOP_STREAM] : [],
  };
  const tooltips: StreamActionButtonsTooltip = {};
  tooltips.DESTROY = inProgress && 'Please wait till the streaming is stopped';
  tooltips.ATTACH_INPUT = inProgress && 'Please wait till the streaming is stopped';
  tooltips.STOP_STREAM = inProgress && 'Stream is being stopped';
  return { buttonStates, tooltips };
};

export const onStreamDestroyed = (
  inProgress: boolean,
  hasFailed: boolean
): GetStreamButtonStatesReturnType => {
  const buttonStates = {
    visible: [
      inProgress || hasFailed ? StreamActionButtons.DESTROY : StreamActionButtons.RESET,
      StreamActionButtons.START_STREAM,
    ],
    disabled: hasFailed ? [] : [StreamActionButtons.ATTACH_INPUT, StreamActionButtons.START_STREAM],
    loading: inProgress ? [StreamActionButtons.DESTROY] : [],
  };
  const tooltips: StreamActionButtonsTooltip = {};
  tooltips.ATTACH_INPUT = !hasFailed && 'Reset and create stream to attach input';
  tooltips.START_STREAM = !hasFailed && 'Attach input to start streaming';
  tooltips.DESTROY = inProgress && 'Stream is being destroyed';
  return { buttonStates, tooltips };
};

export const getStreamButtonStates = (
  status?: EventStreamStatus,
  inProgress?: boolean,
  hasFailed?: boolean
): GetStreamButtonStatesReturnType => {
  if (!status) return { buttonStates: DefaultStreamActionButtonsStatus, tooltips: {} };
  const statusToButtonStateMap: Record<
    EventStreamStatus,
    (inProgress: boolean, hasFailed: boolean) => GetStreamButtonStatesReturnType
  > = {
    CREATED: onStreamCreated,
    NON_EXISTENT: onStreamNonExistent,
    DESTROYED: onStreamDestroyed,
    SOURCE_SELECTED: onStreamSourceSelected,
    STARTED: onStreamStarted,
    STOPPED: onStreamStopped,
  };
  return statusToButtonStateMap[status](inProgress, hasFailed);
};

export const formatStreamDuration = (streamDuration: number) => {
  const hrs = differenceInHours(streamDuration, 0);
  const mins = differenceInMinutes(streamDuration, 0) % 60;
  return `${hrs}h ${mins}m`;
};

export const getStreamDurationInMinutes = (streamDuration: number) => {
  const hrs = differenceInHours(streamDuration, 0);
  const mins = differenceInMinutes(streamDuration, 0) % 60;
  const duration = hrs * 60 + mins;
  return `${duration}`;
};

export const getTotalStreamDuration = (events: APIEvent[]) => {
  let total = 0;
  events.forEach((event) => {
    total += event.stream.duration || 0;
  });

  if (total === 0) return '-';

  return formatStreamDuration(total);
};

export const getNextMasterButtonState = (
  currState: EventStreamStatus,
  hasFailed: boolean,
  isQueued: boolean
) => {
  switch (currState) {
    case EventStreamStatus.NON_EXISTENT: {
      if ((hasFailed && !isQueued) || (!hasFailed && !isQueued)) {
        return { nextAction: EventStreamStatus.CREATED, disable: false };
      }
      return { nextAction: EventStreamStatus.CREATED, disable: true };
    }
    case EventStreamStatus.CREATED: {
      if (hasFailed && !isQueued) {
        return { nextAction: EventStreamStatus.CREATED, disable: false };
      }
      if (!hasFailed && !isQueued) {
        return { nextAction: EventStreamStatus.DESTROYED, disable: false };
      }
      return { nextAction: EventStreamStatus.CREATED, disable: true };
    }
    case EventStreamStatus.DESTROYED: {
      if (hasFailed && !isQueued) {
        return { nextAction: EventStreamStatus.DESTROYED, disable: false };
      }
      if (!hasFailed && !isQueued) {
        return { nextAction: EventStreamStatus.NON_EXISTENT, disable: false };
      }
      return { nextAction: EventStreamStatus.DESTROYED, disable: true };
    }
    case EventStreamStatus.STARTED: {
      if (hasFailed && !isQueued) {
        return { nextAction: EventStreamStatus.DESTROYED, disable: false };
      }
      return { nextAction: EventStreamStatus.DESTROYED, disable: true };
    }
    case EventStreamStatus.STOPPED: {
      if (!hasFailed && isQueued) {
        return { nextAction: EventStreamStatus.DESTROYED, disable: true };
      }
      return { nextAction: EventStreamStatus.DESTROYED, disable: false };
    }
    case EventStreamStatus.SOURCE_SELECTED: {
      return { nextAction: EventStreamStatus.DESTROYED, disable: true };
    }
    default:
      return { nextAction: EventStreamStatus.DESTROYED, disable: false };
  }
};

export const getNextControlButtonState = (currState: EventStreamStatus) => {
  switch (currState) {
    case EventStreamStatus.STARTED:
      return EventStreamStatus.STOPPED;
    case EventStreamStatus.SOURCE_SELECTED:
      return EventStreamStatus.STOPPED;
    case EventStreamStatus.SOURCE_SELECTED:
      return EventStreamStatus.STOPPED;
    default:
      return EventStreamStatus.STARTED;
  }
};

export const canStartStream = (stream: EventStream) => {
  if (stream.hasFailed && stream.status === EventStreamStatus.STARTED) {
    return true;
  }
  if (!stream.isQueued) {
    return [
      EventStreamStatus.CREATED,
      EventStreamStatus.STOPPED,
      EventStreamStatus.SOURCE_SELECTED,
    ].includes(stream.status);
  }
  return false;
};

export const canStopStream = (stream: EventStream) => {
  if (stream.hasFailed && stream.status === EventStreamStatus.STOPPED) {
    return true;
  }
  if (!stream.isQueued) {
    return [EventStreamStatus.STARTED, EventStreamStatus.SOURCE_SELECTED].includes(stream.status);
  }

  return false;
};

export const canSwitchStream = (stream: EventStream) => {
  if (!stream.isQueued) {
    return [EventStreamStatus.STARTED, EventStreamStatus.STOPPED].includes(stream.status);
  }

  return false;
};
