import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';
import theme from 'themes/baseTheme';

import { Autocomplete, Box, Divider, Icon, Stack, TextField, Typography } from '@mui/material';
import { UploadFileRounded } from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { Center } from 'components/Common/Centered';

import * as SourceService from 'services/sourceService';

import { CurrentUserInfoContext } from 'contexts/CurrentUserInfoContext';
import { GroupInfoContext, useGroups } from 'contexts/GroupInfoContext';
import { useToaster } from 'contexts/ToasterContext';

import { GroupInfo, PopUpButtonProps } from 'types';

import { PopUp } from '.';

interface ICreateSlatePopUp {
  isOpen: boolean;
  close: () => void;
}

export const CreateSlatePopUp = ({ isOpen, close }: ICreateSlatePopUp) => {
  const { groups } = useGroups();
  const { userInfo } = useContext(CurrentUserInfoContext);
  const [selectedGroup, setSelectedGroup] = useState<GroupInfo | null>(null);
  const { isUserAdminGroup, currentUserGroup } = useContext(GroupInfoContext);
  const isUserAdmin = isUserAdminGroup();
  const [slateName, setSlateName] = useState('');
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [filemap, setFilemap] = useState({});
  const { triggerToast } = useToaster();
  const finalMap = useRef(null);
  const queryClient = useQueryClient();
  const intervalRef = useRef(null);

  const { mutate: getPresignedURL, isLoading: isGettingPresignedURL } = useMutation(
    SourceService.getPresignedURL,
    {
      onSuccess: async (data: any) => {
        setIsUploading(true);

        data.map(async (item) => {
          await SourceService.uploadFile(item.presignedUrl, finalMap.current[item.slateName])
            .then(() => {
              checkSlateUploaded(item);
            })
            .catch(() => {
              triggerToast({
                message: 'Error occured while uploading slate',
                type: 'error',
              });
              closingFun();
            });
        });
      },
      onError: () => {
        setIsUploading(false);
        triggerToast({
          message: 'Error occured on creating slate',
          type: 'error',
        });
      },
    }
  );

  // check slate upload status
  const checkSlateUploaded = (item) => {
    let retryCount = 0;

    const clearIntervalAndClose = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        closingFun();
      }
    };

    const checkSlate = async () => {
      retryCount += 1;

      try {
        await SourceService.getSlate(item?.slateId);
        clearIntervalAndClose();
      } catch (error) {
        if (retryCount === 3) {
          triggerToast({
            message:
              'Sometimes it might take longer than expected for Slate Creation, please refresh the page and try again',
            type: 'warning',
          });
          clearIntervalAndClose();
        }
      }
    };

    intervalRef.current = setInterval(checkSlate, 3000);
  };

  const handleOnDropFile = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    rejectedFiles.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          triggerToast({
            message: 'File too large, should not be larger than 50MB',
            type: 'error',
          });
        }

        if (err.code === 'file-invalid-type') {
          triggerToast({
            message: 'File type invalid',
            type: 'error',
          });
        }
      });
    });

    setFile(null);
    if (acceptedFiles.length) {
      const acceptedFile = acceptedFiles[0];
      setFile(acceptedFile);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleOnDropFile,
    multiple: false,
    accept: {
      'video/mp2t': ['.ts'],
    },
    // 100 MB
    maxSize: 100000000,
  });

  const buttons: PopUpButtonProps[] = [
    {
      name: 'CANCEL',
      handler: () => {
        close();
      },
      variant: 'outlined',
    },
    {
      name: 'CREATE',
      handler: () => {
        createSlate();
      },
      variant: 'contained',
      isLoading: isUploading || isGettingPresignedURL,
    },
  ];

  const createData = () => {
    return {
      filename: slateName,
      group: isUserAdmin ? selectedGroup?.id : currentUserGroup?.id,
      file: file,
      caller: JSON.stringify(userInfo),
    };
  };

  const createSlate = () => {
    const data = createData();
    const filterList = fileList.some((item) => item.filename === data.filename);
    if (filterList) {
      triggerToast({
        message: 'File name already exists',
        type: 'error',
      });
      return;
    }
    const { file, ...requestData } = data;
    const slateMap = {
      [data.filename]: data.file,
    };
    const mappingData = {
      ...filemap,
      ...slateMap,
    };
    setFilemap(mappingData);

    finalMap.current = mappingData;
    if (requestData.filename === '' && requestData.group === undefined && fileList.length > 0) {
      getPresignedURL([...fileList]);
    } else {
      getPresignedURL([...fileList, requestData]);
    }
  };

  // uncomment this piece of code if multiple slates upload needed
  // const addDataToList = () => {
  //   const data = createData();
  //   const filterList = fileList.some((item) => item.filename === data.filename);
  //   if (filterList) {
  //     triggerToast({
  //       message: 'File name already exists',
  //       type: 'error',
  //     });
  //     return;
  //   }
  //   const { file, ...requestData } = data;
  //   setFileList([...fileList, requestData]);
  //   setSlateName('');
  //   setSelectedGroup(null);
  //   setFile(null);
  //   const slateMap = {
  //     [data.filename]: data.file,
  //   };
  //   setFilemap({ ...filemap, ...slateMap });
  // };

  const removeDataFromList = (index: number) => {
    const list = [...fileList];
    list.splice(index, 1);
    setFileList(list);
  };

  useEffect(() => {
    if (isOpen) {
      setSlateName('');
      setSelectedGroup(null);
      setFile(null);
      setFileList([]);
    }
  }, [isOpen]);

  const closingFun = useCallback(
    debounce(() => {
      queryClient.invalidateQueries({ queryKey: ['slates'] });
      setIsUploading(false);
      close();
    }, 2000),
    []
  );

  // currently we don't receive this update from websocket
  // useSlateActivityUpdates(closingFun);

  return (
    <PopUp
      isPopUpOpen={isOpen}
      title="CREATE SLATE"
      buttons={buttons}
      close={close}
      maxWidth="sm"
      fullWidth
      disableCTA={
        !(
          (Boolean(slateName?.trim()) &&
            (isUserAdmin ? Boolean(selectedGroup) : true) &&
            Boolean(file)) ||
          Boolean(fileList.length > 0)
        )
      }
    >
      {/* Input files */}
      <Box sx={{ mb: '10px' }}>
        {fileList.map((file, idx) => (
          <Stack
            key={file.filename}
            direction="row"
            justifyContent="space-between"
            sx={{
              border: `1px solid ${theme.palette.grey[400]}`,
              mb: '10px',
              padding: '10px',
              boxShadow: `0px 0px 6px ${theme.palette.grey[100]}`,
              borderRadius: '5px',
              position: 'relative',
              overflow: 'visible',
            }}
          >
            <Icon
              color="error"
              fontSize="small"
              sx={{
                position: 'absolute',
                top: '-10px',
                right: '-5px',
                overflow: 'visible',
                cursor: 'pointer',
              }}
              onClick={() => removeDataFromList(idx)}
            >
              <RemoveCircleIcon />
            </Icon>
            <Stack direction="row">
              <Typography variant="body2" color="grey.600" sx={{ fontWeight: 600 }}>
                {file.filename}
              </Typography>
              <Typography variant="body2" color="grey.600" sx={{ fontWeight: 600, ml: '15px' }}>
                {file.group}
              </Typography>
            </Stack>
            <Icon color="primary">
              <InsertDriveFileIcon />
            </Icon>
          </Stack>
        ))}
        {fileList.length > 0 && <Divider />}
      </Box>
      {/* Input Field */}
      <Stack direction="column" alignItems="flex-start" width="100%">
        <TextField
          placeholder="Enter Slate Name"
          label="Slate Name"
          fullWidth
          required={fileList.length === 0}
          size="small"
          value={slateName}
          onChange={(e) => setSlateName(e.target.value)}
        />
        {isUserAdmin ? (
          <Autocomplete
            id="select-group"
            size="small"
            value={selectedGroup}
            getOptionLabel={(option: GroupInfo) => `${option.name} (${option.id})`}
            options={groups || []}
            sx={{
              '& .MuiInputBase-root': {
                width: '100%',
              },
              mt: 2,
              mb: 3,
            }}
            fullWidth
            onChange={(_, value: GroupInfo) => setSelectedGroup(value)}
            renderInput={(params) => (
              <TextField {...params} required={fileList.length === 0} label="Select Group" />
            )}
          />
        ) : (
          <TextField
            label="Group"
            size="small"
            disabled
            required={fileList.length === 0}
            value={currentUserGroup?.id}
            sx={{ mt: 2, mb: 3 }}
            fullWidth
          />
        )}
        <Center
          width="100%"
          height="220px"
          border="1px dashed"
          borderColor={isDragActive ? 'primary.main' : 'grey.300'}
          sx={{
            '&:hover': {
              borderColor: 'primary.main',
            },
          }}
          borderRadius={1}
          bgcolor="grey.100"
          color="grey.600"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <UploadFileRounded fontSize="large" sx={{ mb: 1 }} />
          {file ? file.name : 'Upload Slate (.ts)'}
        </Center>
        {/* <Button
          variant="contained"
          size="small"
          sx={{ ml: 'auto', mt: '3px' }}
          onClick={addDataToList}
          disabled={
            !(
              Boolean(slateName?.trim()) &&
              (isUserAdmin ? Boolean(selectedGroup) : true) &&
              Boolean(file)
            )
          }
        >
          Add Another
        </Button> */}
      </Stack>
    </PopUp>
  );
};
