import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IOutlinedBoxProps {
  outlineOffset?: `${number}px`;
  outlineRadius?: `${number}px`;
  active?: boolean;
}

/* just a Box, but draws an outline around using border css property,
 * outline css property can also do the same simply, but here we can control zIndex and radius of outline
 */
const OutlinedBox = styled(Box)<IOutlinedBoxProps>(
  ({ theme, outlineOffset = '10px', outlineRadius = '8px', active = false }) => {
    return {
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      '::after': {
        content: '""',
        pointerEvents: 'none',
        position: 'absolute',
        width: `calc(100% + ${outlineOffset})`,
        height: `calc(100% + ${outlineOffset})`,
        borderRadius: outlineRadius,
        left: `calc(-${outlineOffset} / 2)`,
        transition: 'border-color 0.15s ease-in-out',
        top: `calc(-${outlineOffset} / 2)`,
        border: `2px solid ${
          !active ? theme.palette.highlight.secondary : theme.palette.primary.main
        }`,
        zIndex: 0,
      },
    };
  }
);

export default OutlinedBox;
