import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { AddRounded } from '@mui/icons-material';

import { ActiveViewHeader } from 'components/Common/ActiveViewHeader';
import { Center } from 'components/Common/Centered';
import { SlateCard } from 'components/Event/SlateCard';
import { CreateSlatePopUp } from 'components/PopUp/CreateSlatePopUp';

import * as SourceService from 'services/sourceService';

import { ActiveMenuContext } from 'contexts/ActiveMenuContext';
import { CurrentUserInfoContext } from 'contexts/CurrentUserInfoContext';
import { GroupInfoContext, useGroups } from 'contexts/GroupInfoContext';

import { UserRole } from 'constants/AuthConstants';
import { ROUTE_KEY } from 'constants/RouteConstants';

import { GroupInfo } from 'types';

export const SlatesDashboard = () => {
  const { setActiveMenu } = useContext(ActiveMenuContext);
  const { currentUserGroup, isUserAdminGroup } = useContext(GroupInfoContext);
  const { userInfo } = useContext(CurrentUserInfoContext);
  const { groups } = useGroups();
  const [selectedGroup, setSelectedGroup] = useState<GroupInfo | null>(null);
  const [isCreateSlatePopupOpen, setIsCreateSlatePopupOpen] = useState(false);

  const { isFetching, data: slates = [] } = useQuery(
    ['slates'],
    () => SourceService.listSlates(currentUserGroup?.id),
    {
      enabled: isUserAdminGroup() ? true : Boolean(currentUserGroup?.id),
    }
  );

  const filteredSlates = selectedGroup
    ? slates.filter((s: { group: any }) => s.group === selectedGroup.id)
    : slates;

  const sortedSlates = _.orderBy(filteredSlates, ['createdAt', 'name']);

  useEffect(() => {
    setActiveMenu(ROUTE_KEY.SLATES);
  }, []);
  return (
    <Container maxWidth={false} sx={{ pt: '25px' }} disableGutters>
      <ActiveViewHeader title="Slates" />
      <Stack
        mt={4}
        direction={{ md: 'row', xs: 'column' }}
        width="100%"
        justifyContent="space-between"
      >
        {isUserAdminGroup() ? (
          <Autocomplete
            id="select-group"
            size="small"
            value={selectedGroup}
            getOptionLabel={(option: GroupInfo) => `${option.name} (${option.id})`}
            options={groups || []}
            sx={{
              '& .MuiInputBase-root': {
                width: '300px',
              },
            }}
            onChange={(_: any, value: GroupInfo) => setSelectedGroup(value)}
            renderInput={(params) => <TextField {...params} label="Filter by Group" />}
          />
        ) : (
          <div></div>
        )}
        <Button
          variant="contained"
          onClick={() => setIsCreateSlatePopupOpen(true)}
          startIcon={<AddRounded />}
        >
          {/* count */}
          Add New Slate
        </Button>
      </Stack>
      <Box height="75vh" mt={5}>
        {isFetching ? (
          <Center minHeight="400px">
            <CircularProgress />
          </Center>
        ) : filteredSlates.length === 0 ? (
          <Center minHeight="400px">
            <Typography variant="body1" color="grey.500">
              No Slates Found!
            </Typography>
          </Center>
        ) : (
          <Grid container spacing={2}>
            {sortedSlates.map((slate) => {
              return (
                <Grid item key={slate.id} xs={12} sm={6} md={3}>
                  <SlateCard
                    slate={slate}
                    showDelete={userInfo?.role !== UserRole.EVENT_OPERATOR}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <CreateSlatePopUp
        isOpen={isCreateSlatePopupOpen}
        close={() => setIsCreateSlatePopupOpen(false)}
      />
    </Container>
  );
};
