import { createContext, useContext, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

import { ToastOptions } from 'types';

type ToasterContextReturnType = {
  triggerToast: (toast: ToastOptions) => void;
};

const ToasterContext = createContext<ToasterContextReturnType>({
  triggerToast: () => {},
});

function ToasterProvider({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastOptions, setToastOptions] = useState<ToastOptions>();

  const triggerToast = (toast: ToastOptions) => {
    setToastOpen(true);
    setToastOptions(toast);
  };

  const handleClose = () => {
    setToastOpen(false);
  };

  return (
    <ToasterContext.Provider value={{ triggerToast }}>
      {children}
      <Snackbar
        key={toastOptions ? toastOptions.message : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={toastOpen}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={toastOptions?.type} sx={{ width: '100%' }}>
          {toastOptions?.message}
        </Alert>
      </Snackbar>
    </ToasterContext.Provider>
  );
}

const useToaster = (): ToasterContextReturnType => {
  return useContext(ToasterContext);
};

export { ToasterContext, ToasterProvider, useToaster };
