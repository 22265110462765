import { CircularProgress, Grid, Typography } from '@mui/material';

import { EventCard } from 'components/Event/EventCard';

import { APIEvent } from 'types';

import { Center } from './Centered';

export interface RunningEventDetailsProps {
  eventDetails: {
    events: APIEvent[];
    isFetching: boolean;
    isError: boolean;
  };
}

const RunningEventDetails = ({ eventDetails }: RunningEventDetailsProps) => {
  const { events, isFetching, isError } = eventDetails;
  return (
    <>
      {isFetching ? (
        <Center minHeight="150px">
          <CircularProgress />
        </Center>
      ) : (
        <>
          {isError ? (
            <Center minHeight="150px">
              <Typography variant="body1" sx={{ color: 'grey.600' }}>
                Error Fetching Events!
              </Typography>
            </Center>
          ) : events?.length > 0 && !isError ? (
            <Grid container gap="30px">
              {events?.map((event) => {
                return (
                  <Grid item key={event.id}>
                    <EventCard event={event} />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Center minHeight="150px">
              <Typography variant="body1" sx={{ color: 'grey.600' }}>
                There are no running events right now
              </Typography>
            </Center>
          )}
        </>
      )}
    </>
  );
};

export default RunningEventDetails;
