import { Box } from '@mui/material';

import { JSONGridDetail } from 'components/Event/JSONGridDetail';

import { AWSChannel, AWSResource } from 'types';

interface IChannelResourceDetails {
  data: AWSResource<AWSChannel>;
}

const transformResourceDetails = (data: AWSResource<AWSChannel>) => {
  const { details } = data;

  return {
    details,
    Name: details?.Name,
    Id: details?.Id || '-',
    State: details?.State,
    Connection: details?.ChannelClass,
  };
};

export const MedialiveChannelResourceDetails = ({ data }: IChannelResourceDetails) => {
  const transformedDetails = transformResourceDetails(data);

  return (
    <Box py={1}>
      <JSONGridDetail data={transformedDetails} />
    </Box>
  );
};
