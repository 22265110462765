import Avatar from 'boring-avatars';
import { format } from 'date-fns';

import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';
import {
  AddCircle,
  CircleNotifications,
  Delete,
  PlayCircleFilled,
  Shuffle,
  StopCircle,
  Update,
} from '@mui/icons-material';

import { avatarColors } from 'constants/MiscConstants';

import { Activity, ActivityType } from 'types';
import { timeZoneAbbreviated } from 'utils/helpers';

const ActivityIcon = ({
  activityType,
  sx,
}: {
  activityType: ActivityType;
  sx?: SxProps;
}): JSX.Element => {
  switch (activityType) {
    case ActivityType.CREATE:
      return <AddCircle sx={sx} />;
    case ActivityType.UPDATE:
      return <Update sx={sx} />;
    case ActivityType.DELETE:
      return <Delete sx={sx} />;
    case ActivityType.SWITCH_INPUT:
      return <Shuffle sx={sx} />;
    case ActivityType.START:
      return <PlayCircleFilled sx={sx} />;
    case ActivityType.STOP:
      return <StopCircle sx={sx} />;
    default:
      return <CircleNotifications sx={sx} />;
  }
};

export const EventActivity = ({ activity }: { activity: Activity }): JSX.Element => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      p={1}
      spacing={3}
      width="100%"
    >
      <Stack
        p={1}
        bgcolor="white"
        width="30px"
        height="30px"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        zIndex={2}
        position="relative"
        sx={{
          '::before': {
            content: '""',
            position: 'absolute',
            backgroundColor: 'grey.100',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            zIndex: 1,
          },
        }}
      >
        <ActivityIcon activityType={activity.type} sx={{ width: '18px', height: '18px' }} />
      </Stack>
      <Box
        sx={{
          svg: {
            borderRadius: '50%',
          },
        }}
      >
        <Avatar name={activity?.causedBy} variant="beam" colors={avatarColors} size="40px" />
      </Box>
      <Stack alignItems="flex-start" spacing={1} width="100%">
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          width="100%"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', md: 'center' }}
          spacing={{ xs: 0, md: 1 }}
        >
          <Typography>{activity.message}</Typography>
          <Divider
            variant="inset"
            sx={{ flexGrow: '1', marginLeft: 'unset', display: { xs: 'none', md: 'block' } }}
          />
          <Typography sx={{ opacity: 0.8 }} variant="body2">
            At {format(activity.timestamp, 'hh:mm:ss aa')} {timeZoneAbbreviated()},{' '}
            {format(activity.timestamp, 'dd MMM YYY')}
          </Typography>
        </Stack>
        {activity.additionalInfo && (
          <Stack direction="row" spacing={2}>
            <Typography
              component="span"
              color="grey.700"
              fontWeight="bold"
              sx={{ overflowWrap: 'anywhere' }}
            >
              {activity.additionalInfo.eventName}
            </Typography>
            <Typography component="span" color="text.secondary" fontWeight="bold" variant="body2">
              {format(new Date(activity.additionalInfo.eventScheduledTime), 'hh:mm a')}{' '}
              {timeZoneAbbreviated()}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
