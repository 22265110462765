import { useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';

import { CircularProgress, IconButton, SvgIconProps } from '@mui/material';
import { PlayArrowRounded, StopRounded } from '@mui/icons-material';

import { Center } from 'components/Common/Centered';

import { EventStreamStatus } from 'constants/StreamConstants';
import { UserTourClassSelector } from 'constants/UserTourConstants';

import { useStreamApi } from 'hooks/useStreamApi';

import { EventStream, FeedGraphControlNodeData } from 'types';
import { GRAPH_NODE_DIMENSIONS } from 'utils/eventHelpers';
import { canStartStream, canStopStream, getNextControlButtonState } from 'utils/streamHelper';

const StreamIcon = ({
  status,
  iconProps,
}: {
  status: EventStreamStatus;
  iconProps?: SvgIconProps;
}) => {
  switch (status) {
    case EventStreamStatus.STARTED:
      return <PlayArrowRounded {...iconProps} />;
    case EventStreamStatus.STOPPED:
      return <StopRounded {...iconProps} />;
    case EventStreamStatus.SOURCE_SELECTED:
      return <StopRounded {...iconProps} />;
    default:
      return <PlayArrowRounded {...iconProps} />;
  }
};

export const ControlFeedNode = ({
  data,
  id,
  type,
}: NodeProps<FeedGraphControlNodeData>): JSX.Element => {
  const [isFetching, setisFetching] = useState(false);
  const { eventOperation } = useStreamApi(setisFetching);
  const nextAction = data.hasFailed ? data.status : getNextControlButtonState(data.status);
  const isDisabled =
    data.isQueued ||
    (nextAction === EventStreamStatus.STARTED
      ? !canStartStream(data as EventStream)
      : !canStopStream(data as EventStream));

  const controlStreamAction = (status: EventStreamStatus) => {
    setisFetching(true);
    data.onClick(EventStreamStatus.STARTED, data.selectedSourceId);
    switch (nextAction) {
      case EventStreamStatus.STOPPED:
        return eventOperation({ eventId: data.eventId, operation: EventStreamStatus.STOPPED });
      case EventStreamStatus.SOURCE_SELECTED:
        return eventOperation({ eventId: data.eventId, operation: EventStreamStatus.STOPPED });
      case EventStreamStatus.STARTED:
        return eventOperation({ eventId: data.eventId, operation: EventStreamStatus.STARTED });
      default:
        return;
    }
  };
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        style={{ visibility: 'hidden' }}
      />
      <Center
        bgcolor={'primary.main'}
        sx={(theme) => ({
          transition: 'backgroundColor 0.15s ease-in-out',
          boxShadow: `0px 0px 171px 19px ${theme.palette.primary.main + '7a'}, 0px 0px 0px 2px ${
            theme.palette.primary.main + '60'
          }`,
          opacity: isDisabled ? 0.6 : 1,
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        })}
        className={UserTourClassSelector.SHOW_PLAY_BTN}
        borderRadius="50%"
        border="4px solid white"
        style={{
          width: GRAPH_NODE_DIMENSIONS[type].width,
          height: GRAPH_NODE_DIMENSIONS[type].height,
        }}
      >
        <IconButton
          size="large"
          centerRipple
          onClick={() => controlStreamAction(nextAction)}
          disabled={isDisabled}
        >
          {isFetching ||
          (data.isQueued && data.status === EventStreamStatus.STARTED) ||
          (data.isQueued && data.status === EventStreamStatus.STOPPED) ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : (
            <StreamIcon
              status={nextAction}
              iconProps={{
                sx: {
                  fontSize: '4.5rem',
                  color: 'common.white',
                },
              }}
            />
          )}
        </IconButton>
      </Center>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={false}
        style={{ visibility: 'hidden' }}
      />
    </>
  );
};
