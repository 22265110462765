import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';

import { RunningEvents } from 'views/RunningEvents';

import { AccordionComponent } from 'components/Common/AccordionComponent';

import { useGroups } from 'contexts/GroupInfoContext';

import { FilterEventsWithRange, GroupInfo, TransformApiEventsType } from 'types';

export function GridView({
  isFetching,
  isError,
  dateParamsToRender,
  selectedGroup,
  defaultGroup,
  events,
}: {
  isFetching: boolean;
  isError: boolean;
  dateParamsToRender: FilterEventsWithRange;
  selectedGroup: GroupInfo;
  defaultGroup: string;
  events: TransformApiEventsType[];
}): JSX.Element {
  const { isUserAdminGroup } = useGroups();

  const renderEventCards = () => {
    return events?.map((eventsByDate) => {
      return (
        <Grid container sx={{ margin: '40px 0px' }} key={eventsByDate.scheduledTime}>
          <Grid container flexDirection="column" gap="20px">
            <AccordionComponent eventsByDate={eventsByDate} />
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <>
      <RunningEvents group={isUserAdminGroup() ? selectedGroup?.id : defaultGroup} />
      <Stack mt={4} direction="row">
        <Box>
          <Typography variant="h6">
            <b>UPCOMING EVENTS</b>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {dateParamsToRender.startDate} to {dateParamsToRender.endDate}
          </Typography>
        </Box>
        <Box flexGrow="1" height="1px" bgcolor="grey.300" mx={1} />
      </Stack>
      {isFetching ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="160px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {isError ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="160px">
              <Typography variant="body1" sx={{ color: 'grey.600' }}>
                Error Fetching Events!
              </Typography>
            </Box>
          ) : (
            <>
              {events?.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="160px">
                  <Typography variant="body1" sx={{ color: 'grey.600' }}>
                    No Events found
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ backgroundColor: 'common.white', mt: '20px' }}>
                  <Grid>{renderEventCards()}</Grid>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
