import { createContext, useContext, useMemo } from 'react';

import { UserRole } from 'constants/AuthConstants';

import { useTokenInfo } from 'hooks/useTokenInfo';

import { User } from 'types';

export type CurrentUserInfoContextReturnType = Partial<{
  userInfo: User;
}>;

export const CurrentUserInfoContext = createContext<CurrentUserInfoContextReturnType>({});

export function CurrentUserInfoProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element {
  const currentUserInfo = useTokenInfo();

  const userInfo = useMemo(
    () => ({
      firstName: currentUserInfo?.['custom:first_name'],
      lastName: currentUserInfo?.['custom:last_name'],
      role: currentUserInfo?.role as UserRole,
      email: currentUserInfo?.email,
      group: currentUserInfo?.group,
    }),
    [currentUserInfo]
  );

  return (
    <CurrentUserInfoContext.Provider value={{ userInfo }}>
      {children}
    </CurrentUserInfoContext.Provider>
  );
}

export const useCurrentUserInfo = (): CurrentUserInfoContextReturnType => {
  return useContext(CurrentUserInfoContext);
};
