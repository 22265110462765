import { SourceType } from 'types';

export type ElementalLinkSourceSpecification = {
  deviceId: string;
};

export type SlateInputSourceSpecification = {
  slateId: string;
};

export type RTMPSourceSpecification = Record<string, never>;

export type SRTSourceSpecification = Record<string, never>;

export type ZixiSourceSpecification = Record<string, never>;

export type SourceSpecification =
  | ElementalLinkSourceSpecification
  | SlateInputSourceSpecification
  | RTMPSourceSpecification
  | SRTSourceSpecification
  | ZixiSourceSpecification;

export type OutputConfigType = {
  isMultiple: boolean;
};

// Flip the isMultiple to true for the source types that can have multiple sources
export const SourceConfigDetail: Record<
  Exclude<SourceType, SourceType.ZIXI_MULTI>,
  OutputConfigType
> = {
  [SourceType.ELEMENTAL_LINK]: {
    isMultiple: false,
  },
  [SourceType.SLATE_INPUT]: {
    isMultiple: false,
  },
  [SourceType.RTMP]: {
    isMultiple: false,
  },
  [SourceType.SRT]: {
    isMultiple: false,
  },
  [SourceType.ZIXI]: {
    isMultiple: false,
  },
};

// use specific number to limit the source count
export const SourceCount = 1;
