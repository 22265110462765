const deviceStates = {
  positive: ['CONNECTED'],
  neutral: ['CONNECTING'],
  negative: ['DISCONNECTED'],
};

export enum FeedNodeStatusType {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
}

export default deviceStates;
