import { useTour } from '@reactour/tour';

import { Link, Stack, Typography } from '@mui/material';
import { Mail, Phone } from '@mui/icons-material';

import { Center } from 'components/Common/Centered';

import { PopUpButtonProps } from 'types';

import { PopUp } from '.';

interface ControlCenterHelpPopUpProps {
  isControlCenterHelpPopUpOpen: boolean;
  eventFound: boolean;
  close: () => void | Promise<void>;
}

export function ControlCenterHelpPopUp({
  isControlCenterHelpPopUpOpen,
  eventFound,
  close,
}: ControlCenterHelpPopUpProps): JSX.Element {
  const { setIsOpen, setCurrentStep } = useTour();
  const tooltip = {
    buttonName: 'START TOUR',
    message: 'Please create an event to start the tour',
  };

  const buttons: PopUpButtonProps[] = [
    { name: 'CANCEL', handler: close, variant: 'outlined' },
    {
      name: 'START TOUR',
      handler: () => {
        close();
        setCurrentStep(0);
        setIsOpen(true);
      },
      variant: 'contained',
    },
  ];

  return (
    <PopUp
      isPopUpOpen={isControlCenterHelpPopUpOpen}
      buttons={buttons}
      close={close}
      title="CONTACT SUPPORT"
      description={
        <Typography color="text.secondary" variant="body2">
          Contact the admin and get support if necessary
        </Typography>
      }
      disableCTA={!eventFound}
      tooltip={tooltip}
    >
      <Center>
        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
          <Stack direction="row" spacing={1}>
            <Phone sx={{ color: 'text.secondary' }} />
            <Typography>+18882521539</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Mail sx={{ color: 'text.secondary' }} />
            <Link href="mailto:clubstreamsupport@nhl.com" underline="none" color="black">
              clubstreamsupport@nhl.com
            </Link>
          </Stack>
        </Stack>
      </Center>
      <Typography textAlign="center" mt={3} color="text.secondary" fontWeight="bold">
        or
      </Typography>
    </PopUp>
  );
}
