import { API } from 'aws-amplify';

import environment from 'config';
import { User } from 'types';

export const getAllUsers = (): Promise<User[]> => API.get(environment.API.REST.NAME, 'user', {});

export const postUser = (user: User): Promise<void> =>
  API.post(environment.API.REST.NAME, 'user', { body: user });

export const putUser = (user: User): Promise<User> =>
  API.put(environment.API.REST.NAME, `user/${user.email}`, { body: user });

export const deleteUser = (user: User): Promise<void> =>
  API.del(environment.API.REST.NAME, `user/${user.email}`, {});
