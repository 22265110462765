import { format } from 'date-fns';

import { Box, CircularProgress, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';

import { APIEvent, StreamStatus } from 'types';
import { timeZoneAbbreviated } from 'utils/helpers';
interface EventStatusProps {
  event: APIEvent;
}

const getStatusInfo = (status: StreamStatus, isQueued: boolean, hasFailed: boolean) => {
  let icon = null;
  let text = null;
  if (isQueued && !hasFailed) {
    icon = <CircularProgress size={15} sx={{ color: 'white' }} />;
  } else {
    icon = <FiberManualRecord sx={{ color: 'common.white', width: '16px' }} />;
  }
  switch (status) {
    case StreamStatus.CREATED:
      text = isQueued && !hasFailed ? 'CREATING' : 'CREATED';
      break;
    case StreamStatus.STARTED:
      text = isQueued && !hasFailed ? 'STARTING' : 'LIVE';
      break;
    case StreamStatus.STOPPED:
      text = isQueued && !hasFailed ? 'STOPPING' : 'READY';
      break;
    case StreamStatus.DESTROYED:
      text = isQueued && !hasFailed ? 'TEARING DOWN' : 'FINISHED';
      break;
    default:
      icon = null;
      text = null;
      break;
  }

  return (
    icon &&
    text && (
      <>
        {icon}
        <Typography fontWeight="bold" variant="body2">
          {text}
        </Typography>
      </>
    )
  );
};

export const getBadgeColor = (status: StreamStatus, isQueued: boolean, hasFailed: boolean) => {
  let color = '';
  switch (status) {
    case StreamStatus.CREATED:
      color = isQueued && !hasFailed ? 'text.secondary' : 'warning.light';
      break;
    case StreamStatus.STARTED:
      color = isQueued && !hasFailed ? 'warning.light' : 'success.main';
      break;
    case StreamStatus.STOPPED:
      color = 'warning.light';
      break;
    case StreamStatus.DESTROYED:
      color = 'text.secondary';
      break;
    default:
      color = '';
      break;
  }

  return color;
};

export function EventStatus({ event }: EventStatusProps): JSX.Element {
  const { status, hasFailed, isQueued } = event.stream;
  const startUTC = format(new Date(event.scheduledTime), 'hh:mm a');
  const timeZone = timeZoneAbbreviated();
  const tooltipMessage = new Date(event.scheduledTime).toLocaleString('en-US', {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <Grid item>
        <Tooltip title={tooltipMessage} placement="top">
          <Box sx={{ backgroundColor: 'highlight.darkBg', borderRadius: '4px' }} p={0.5} px={1}>
            <Typography sx={{ color: 'common.white' }} fontWeight="bold" variant="body2">
              {startUTC} {timeZone}
            </Typography>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{
            borderRadius: '100px',
            height: '30px',
            backgroundColor: getBadgeColor(status as unknown as StreamStatus, isQueued, hasFailed),
          }}
          p={0.5}
          px={1}
          pr={2}
          color="common.white"
        >
          {getStatusInfo(status as unknown as StreamStatus, isQueued, hasFailed)}
        </Stack>
      </Grid>
    </Stack>
  );
}
