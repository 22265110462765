export enum UserRole {
  APPLICATION_ADMIN = 'APPLICATION_ADMIN', // User from NHL or Presidio
  GROUP_MANAGER = 'GROUP_MANAGER', // Specific to a team
  EVENT_OPERATOR = 'EVENT_OPERATOR', // Specific to a team
  UNASSIGNED = 'UNASSIGNED',
}

export const UserRoleDisplay = {
  [UserRole.APPLICATION_ADMIN]: 'Application Manager',
  [UserRole.GROUP_MANAGER]: 'Group Manager',
  [UserRole.EVENT_OPERATOR]: 'Event Operator',
  [UserRole.UNASSIGNED]: 'Unassigned',
};

export const ErrorStates = {
  UNASSIGNED: UserRole.UNASSIGNED,
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
};
