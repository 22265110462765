import _ from 'lodash';

import { Box } from '@mui/material';

import { JSONGridDetail } from 'components/Event/JSONGridDetail';

import { AWSMedialiveInput, AWSResource } from 'types';

interface IMedialiveInputResourceDetails {
  data: AWSResource<AWSMedialiveInput>;
}

const getConnectedComponents = (details: AWSMedialiveInput) => {
  return {
    flows:
      _.compact(details?.MediaConnectFlows?.map((flow) => flow?.FlowArn) || []).join(', ') || '-',
    channels: _.compact(details?.AttachedChannels || []).join(', ') || '-',
    inputDevices:
      _.compact(details?.InputDevices?.map((device) => device?.Id) || []).join(', ') || '-',
  };
};

const transformResourceDetails = (data: AWSResource<AWSMedialiveInput>) => {
  const { details } = data;

  const { flows, channels, inputDevices } = getConnectedComponents(details);

  return {
    details,
    Id: details?.Id || '-',
    Name: details?.Name,
    'Input Class': details?.InputClass,
    Channels: channels,
    Flows: flows,
    'Input Devices': inputDevices,
  };
};

export const MedialiveInputResourceDetails = ({ data }: IMedialiveInputResourceDetails) => {
  const transformedDetails = transformResourceDetails(data);

  return (
    <Box py={1}>
      <JSONGridDetail data={transformedDetails} />
    </Box>
  );
};
