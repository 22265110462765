import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Avatar, Box, Button, Container, Grid, Paper, Stack, Typography } from '@mui/material';

import { useToaster } from 'contexts/ToasterContext';

import { ErrorStates } from 'constants/AuthConstants';
import LoginWallpaper from 'assets/loginimage.png';
import logo from 'assets/nhlflat.svg';

import environment from 'config';
import { buildStyles } from 'utils/helpers';

const styles = buildStyles({
  button: {
    borderRadius: '100px',
    position: 'relative',
    height: '44px',
    width: '210px',
    pl: '40px',
  },
  buttonLeftIcon: {
    position: 'absolute',
    height: '30px',
    width: '30px',
    left: '7px',
    top: '7px',
  },
});

const LoginPage = (): JSX.Element => {
  const { triggerToast } = useToaster();
  const history = useHistory();

  useEffect(() => {
    const authError = sessionStorage.getItem('AUTH_ERROR');
    switch (authError) {
      case ErrorStates.UNASSIGNED:
        triggerToast({
          message: 'Please contact admin to assign you a role',
          type: 'error',
        });
        break;
      case ErrorStates.SIGN_IN_FAILURE:
        triggerToast({
          message: 'Sign In error occured! Please contact admin',
          type: 'error',
        });
        break;
      default:
        break;
    }
    return () => {
      sessionStorage.removeItem('AUTH_ERROR');
    };
  }, []);

  useEffect(() => {
    const redirectToHomePage = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser) {
          history.push('/dashboard');
        }
      } catch {
        // do nothing
      }
    };
    redirectToHomePage();

    return () => {
      sessionStorage.removeItem('AUTH_ERROR');
    };
  }, []);

  const handlePresidioLoginClick = () => {
    // changing provider to customProvider based on https://github.com/aws-amplify/amplify-js/issues/5860
    // undo if anything breaks
    Auth.federatedSignIn({ customProvider: environment.cognito.SAML_PROVIDER_OKTA });
  };

  const handleNHLLoginClick = () => {
    // changing provider to customProvider based on https://github.com/aws-amplify/amplify-js/issues/5860
    // undo if anything breaks
    Auth.federatedSignIn({ customProvider: environment.cognito.SAML_PROVIDER_ENTRA });
  };

  return (
    <>
      <Box position="absolute" py={2} px={{ md: 7, xs: 0 }} sx={{ width: '100%', zIndex: 2 }}>
        <Stack direction="row" spacing={1} justifyContent={{ xs: 'center', md: 'flex-start' }}>
          <Box sx={{ height: '40px' }}>
            <img src={logo} alt="logo" style={{ height: '100%', width: '100%' }} />
          </Box>
          <Typography variant="h6" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
            Clubstreams
          </Typography>
        </Stack>
      </Box>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: `url(${LoginWallpaper}), linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6))`,
          backgroundBlendMode: 'overlay',
          backgroundPosition: 'center',
          height: '100vh',
          px: 8,
          position: 'relative',
        }}
      >
        <Grid justifyContent="center" container width="100%" alignItems="center" height="100vh">
          <Grid item>
            <Paper
              elevation={3}
              sx={{
                textAlign: 'center',
                backgroundColor: 'common.white',
                height: { xs: '70vh', md: '75vh' },
                width: { xs: 'calc(100vw - 15px)', md: '40vmax' },
                maxWidth: '550px',
              }}
            >
              <Stack direction="column" spacing={5} sx={{ p: 4 }}>
                <Box sx={{ height: '75px' }}>
                  <img src={logo} alt="logo" style={{ height: '100%', width: '100%' }} />
                </Box>
                <Stack direction="column" spacing={2}>
                  <Typography variant="h5">Welcome to Clubstreams!</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Please login using your business email id
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="column" spacing={2} mt={{ xs: 1, md: 4 }} mb={4}>
                <Button
                  variant="contained"
                  sx={styles.button}
                  onClick={handlePresidioLoginClick}
                  startIcon={
                    <Avatar
                      sx={{
                        ...styles.buttonLeftIcon,
                        backgroundColor: 'common.white',
                        color: 'primary.main',
                      }}
                    >
                      P
                    </Avatar>
                  }
                >
                  Login with PRESIDIO
                </Button>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  or
                </Typography>
                <Button
                  variant="contained"
                  sx={styles.button}
                  onClick={handleNHLLoginClick}
                  startIcon={
                    <Avatar
                      sx={{
                        ...styles.buttonLeftIcon,
                        backgroundColor: 'black',
                        '& > img': {
                          height: '80%',
                          objectFit: 'fill',
                        },
                      }}
                      src={logo}
                    >
                      N
                    </Avatar>
                  }
                >
                  Login with NHL
                </Button>
              </Stack>
              <Stack spacing={1} mt={{ md: 10, xs: 0 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Facing trouble logging in or need access?
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Please
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: 'primary.main', ml: 0.5 }}
                  >
                    contact admin
                  </Typography>
                </Typography>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default LoginPage;
