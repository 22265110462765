import { API } from 'aws-amplify';

import environment from 'config';

import { Activity } from '../types';

export class EventActivityService {
  static async getActivityLogs(queryParams: {
    date?: string | number;
    eventId?: string;
  }): Promise<Activity[]> {
    return await API.get(environment.API.REST.NAME, 'event/activity', {
      queryStringParameters: queryParams,
    });

    // TODO: use for mock api call
    // console.log('getActivityLogs ', queryParams.date, queryParams.eventId);
    // return new Promise((resolve) => {
    //   setTimeout(() => resolve(dummyActivityLogs), 6000);
    // });
  }
}
