import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import * as EventService from 'services/eventService';

import { DefaultStreamActionButtonsStatus, EventStreamStatus } from 'constants/StreamConstants';

import {
  APIEvent,
  EventStream,
  StreamActionButtonsStatus,
  StreamActionButtonsTooltip,
} from 'types';
import { getStreamButtonStates } from 'utils/streamHelper';

import { useStreamApi } from './useStreamApi';
import { useStreamUpdated } from './useStreamUpdated';

interface StreamControlReturnType {
  buttonStates: StreamActionButtonsStatus | null;
  buttonTooltips: StreamActionButtonsTooltip;
  stream: EventStream | null;
  createStream: () => void;
  destroyStream: () => void;
  startStream: () => void;
  stopStream: () => void;
  resetStream: () => void;
  switchSource: (sourceId: string) => void;
  selectedSourceId: string;
}

export const useStreamControl = (eventId: string): StreamControlReturnType | null => {
  const [stream, setStream] = useState<EventStream | null>(null);
  const [selectedSourceId, setSelectedSourceId] = useState<string | null>(null);
  const streamApi = useStreamApi();
  const [streamButtonStates, setStreamButtonStates] = useState<StreamActionButtonsStatus>(
    DefaultStreamActionButtonsStatus
  );
  const [streamButtontooltips, setStreamButtonTooltips] = useState<StreamActionButtonsTooltip>({});

  const fetchEvent = () => {
    setSelectedSourceId(null);
    setStreamButtonStates(DefaultStreamActionButtonsStatus);
    return eventId && EventService.getEvent(eventId);
  };

  const onEventUpdate = (stream: EventStream) => {
    setStream(stream);
    const { buttonStates, tooltips } = getStreamButtonStates(
      stream?.status,
      stream?.isQueued,
      stream?.hasFailed
    );
    setStreamButtonStates(buttonStates);
    setStreamButtonTooltips(tooltips);
  };

  const { refetch: refetchEvent } = useQuery([`event-${eventId}`], fetchEvent, {
    onSuccess: (event: APIEvent) => {
      onEventUpdate(event.stream);
      setSelectedSourceId(event.sourceId);
    },
    enabled: false,
  });

  useEffect(() => {
    refetchEvent();
  }, [eventId]);
  useStreamUpdated(eventId, onEventUpdate);

  const createStream = () => {
    setSelectedSourceId(null);
    const { buttonStates, tooltips } = getStreamButtonStates(EventStreamStatus.CREATED, true);
    setStreamButtonTooltips(tooltips);
    setStreamButtonStates(buttonStates);
    return streamApi.eventOperation(
      { eventId: eventId, operation: EventStreamStatus.CREATED },
      {
        onError() {
          const { buttonStates, tooltips } = getStreamButtonStates(
            EventStreamStatus.CREATED,
            false,
            true
          );
          setStreamButtonStates(buttonStates);
          setStreamButtonTooltips(tooltips);
        },
      }
    );
  };
  const destroyStream = () => {
    setSelectedSourceId(null);
    const { buttonStates, tooltips } = getStreamButtonStates(EventStreamStatus.DESTROYED, true);
    setStreamButtonStates(buttonStates);
    setStreamButtonTooltips(tooltips);
    return streamApi.eventOperation(
      { eventId: eventId, operation: EventStreamStatus.DESTROYED },
      {
        onError() {
          const { buttonStates, tooltips } = getStreamButtonStates(
            EventStreamStatus.DESTROYED,
            false,
            true
          );
          setStreamButtonStates(buttonStates);
          setStreamButtonTooltips(tooltips);
        },
      }
    );
  };
  const startStream = () => {
    const { buttonStates, tooltips } = getStreamButtonStates(EventStreamStatus.STARTED, true);
    setStreamButtonStates(buttonStates);
    setStreamButtonTooltips(tooltips);
    return streamApi.eventOperation(
      { eventId: eventId, operation: EventStreamStatus.STARTED },
      {
        onError() {
          const { buttonStates, tooltips } = getStreamButtonStates(
            EventStreamStatus.STARTED,
            false,
            true
          );
          setStreamButtonStates(buttonStates);
          setStreamButtonTooltips(tooltips);
        },
      }
    );
  };
  const stopStream = () => {
    const { buttonStates, tooltips } = getStreamButtonStates(EventStreamStatus.STOPPED, true);
    setStreamButtonStates(buttonStates);
    setStreamButtonTooltips(tooltips);
    return streamApi.eventOperation(
      { eventId: eventId, operation: EventStreamStatus.STOPPED },
      {
        onError() {
          const { buttonStates, tooltips } = getStreamButtonStates(
            EventStreamStatus.STOPPED,
            false,
            true
          );
          setStreamButtonStates(buttonStates);
          setStreamButtonTooltips(tooltips);
        },
      }
    );
  };
  const resetStream = () => {
    setSelectedSourceId(null);
    const { buttonStates, tooltips } = getStreamButtonStates(EventStreamStatus.NON_EXISTENT, true);
    setStreamButtonStates(buttonStates);
    setStreamButtonTooltips(tooltips);
    return streamApi.eventOperation({
      eventId: eventId,
      operation: EventStreamStatus.NON_EXISTENT,
    });
  };
  const switchSource = (sourceId: string) => {
    setSelectedSourceId(sourceId);
    const { buttonStates, tooltips } = getStreamButtonStates(
      EventStreamStatus.SOURCE_SELECTED,
      true
    );
    setStreamButtonStates(buttonStates);
    setStreamButtonTooltips(tooltips);
    return streamApi.switchSource(
      { eventId: eventId, sourceId: sourceId },
      {
        onError() {
          setSelectedSourceId(null);
          const { buttonStates, tooltips } = getStreamButtonStates(
            EventStreamStatus.CREATED,
            false
          );
          setStreamButtonStates(buttonStates);
          setStreamButtonTooltips(tooltips);
        },
      }
    );
  };

  return {
    buttonStates: streamButtonStates,
    buttonTooltips: streamButtontooltips,
    stream,
    createStream,
    destroyStream,
    startStream,
    stopStream,
    resetStream,
    switchSource,
    selectedSourceId,
  };
};
