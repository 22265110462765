import { useQuery } from '@tanstack/react-query';

import { Box } from '@mui/material';

import { AccordionComponent } from 'components/Common/AccordionComponent';

import * as EventService from 'services/eventService';
export const RunningEvents = ({ group, isList }: { group: string; isList?: boolean }) => {
  const {
    isFetching,
    isError,
    data: events = [],
  } = useQuery(['events', group], () => EventService.runningEvents(), {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const renderRunningEvents = () => {
    return <AccordionComponent isRunning runningEvents={{ events, isFetching, isError, isList }} />;
  };

  return (
    <Box mt={2} py={2} borderRadius={2}>
      {/* <Stack direction="row">
        <Box>
          <Typography variant="h6" display="inline-flex" alignItems="center">
            <FiberManualRecord
              sx={{ color: 'success.main', mr: 0.5, width: '20px', height: '20px' }}
            />
            <b>RUNNING EVENTS</b>
          </Typography>
        </Box>
        <Box flexGrow="1" height="1px" bgcolor="grey.300" mx={1} />
        <Typography
          variant="caption"
          sx={{
            padding: '3px 10px',
            backgroundColor: 'grey.700',
            borderRadius: '6px',
            fontWeight: 'bold',
            color: 'common.white',
          }}
        >
          {events.length} EVENTS
        </Typography>
      </Stack> */}
      {/* {isFetching && (
        <Center minHeight="160px">
          <CircularProgress />
        </Center>
      )}
      {!isFetching && events?.length === 0 && (
        <Center minHeight="160px">
          <Typography variant="body1" sx={{ color: 'grey.600' }}>
            There are no running events right now
          </Typography>
        </Center>
      )}
      {!isFetching && isError && (
        <Center minHeight="160px">
          <Typography variant="body1" sx={{ color: 'grey.600' }}>
            Error Fetching Events!
          </Typography>
        </Center>
      )}
      */}

      {renderRunningEvents()}
    </Box>
  );
};
