import { useState } from 'react';
import theme from 'themes/baseTheme';

import { Box, Button, Grid, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import { DeleteEventPopUp } from 'components/PopUp/DeleteEventPopUp';
import { EventPopUp } from 'components/PopUp/EventPopUp';

import { ROUTE_KEY, ROUTE_PATH } from 'constants/RouteConstants';

import { useActionAccess } from 'hooks/useActionAccess';

import { APIEvent } from 'types';
import { hasEventStreamed, isConsistentStateToDelete } from 'utils/eventHelpers';

import { EventDetail } from './EventDetail';
import { EventStatus } from './EventStatus';
import { SourceDetail } from './SourceDetail';

// Uncomment when we event operation has to be excluded for application admin
// const styles = buildStyles({
//   controls: (theme: Theme) => ({
//     [theme.breakpoints.up('lg')]: {
//       width: '400px',
//       flexDirection: 'row-reverse',
//     },
//   }),
// });
interface EventCardProps {
  event: APIEvent;
}

export function EventCard({ event }: EventCardProps): JSX.Element {
  const [editPopupState, setEditPopupState] = useState(false);
  const [deletePopupState, setDeletePopupState] = useState(false);
  const { canEditEvent, canDeleteEvent } = useActionAccess();

  const disableDeleteEvent = hasEventStreamed(event) || !isConsistentStateToDelete(event);
  const tooltipMessage = hasEventStreamed(event)
    ? 'Streamed event cannot be deleted'
    : 'Inconsistent stream state to delete';
  const onUpdateCancelEvent = () => {
    setEditPopupState(false);
  };

  const onDeleteCancelEvent = () => {
    setDeletePopupState(false);
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: 'common.white',
          padding: '25px',
          border: `1px solid ${theme.palette.grey[400]}`,
          boxShadow: `0px 0px 6px ${theme.palette.grey[100]}`,
          borderRadius: '10px',
          maxWidth: '744.18px',
          minHeight: '320px',
          '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        })}
      >
        <Grid
          container
          flexDirection="row"
          columnGap={'10px'}
          rowGap={'5px'}
          justifyContent={'space-between'}
        >
          <Grid item>
            <EventStatus event={event} />
          </Grid>
          <Grid
            item
            display="flex"
            flexDirection="row"
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
            gap="10px"
            sx={{
              [theme.breakpoints.down(615)]: { order: 3 },
              [theme.breakpoints.down(825)]: { order: 3 },
            }}
            // sx={userInfo.role === UserRole.APPLICATION_ADMIN ? styles.controls : {}}
          >
            <Grid item>
              <Button
                variant="contained"
                size="small"
                href={`${ROUTE_PATH[ROUTE_KEY.CONTROL_CENTER]}/${event.id}`}
                target="_blank"
                sx={{ width: 'max-content' }}
              >
                Control Center
              </Button>
            </Grid>

            <Grid item>
              {canEditEvent && (
                <Button
                  onClick={() => setEditPopupState(true)}
                  variant="outlined"
                  size="small"
                  sx={{ width: 'max-content' }}
                  startIcon={<Edit />}
                >
                  Edit Event
                </Button>
              )}
            </Grid>

            <Grid item>
              {canDeleteEvent && (
                <Tooltip title={disableDeleteEvent ? tooltipMessage : ''} placement="top">
                  <div>
                    <Button
                      onClick={() => setDeletePopupState(true)}
                      disabled={disableDeleteEvent}
                      variant="outlined"
                      color="error"
                      size="small"
                      sx={{ width: 'max-content' }}
                      startIcon={<Delete />}
                    >
                      Delete Event
                    </Button>
                  </div>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <EventDetail event={event} />
          </Grid>
        </Grid>
        <SourceDetail eventID={event?.id} />
      </Box>
      <EventPopUp
        isEditMode
        eventToEdit={event.id}
        isPopUpOpen={editPopupState}
        close={onUpdateCancelEvent}
      />
      <DeleteEventPopUp
        isDeleteEventPopUpOpen={deletePopupState}
        close={onDeleteCancelEvent}
        event={event}
      />
    </>
  );
}
