import { Handle, NodeProps, Position } from 'reactflow';

import { Box, Stack, Switch, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { Center } from 'components/Common/Centered';

import { SourceTypeDisplayName } from 'constants/CommonConstants';
import { EventStreamStatus } from 'constants/StreamConstants';
import { UserTourClassSelector } from 'constants/UserTourConstants';

import { FeedGraphSwitchNodeData } from 'types';
import { GRAPH_NODE_DIMENSIONS } from 'utils/eventHelpers';

export const SwitchFeedNode = ({
  data,
  id,
  type,
}: NodeProps<FeedGraphSwitchNodeData>): JSX.Element => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        style={{ visibility: 'hidden' }}
      />
      <Center
        p={0.5}
        px={1.5}
        style={{
          width: GRAPH_NODE_DIMENSIONS[type].width,
          height: GRAPH_NODE_DIMENSIONS[type].height,
        }}
        className={UserTourClassSelector.SHOW_SWITCHES}
        borderRadius={2}
        border="1px solid"
        borderColor="highlight.secondary"
        bgcolor="common.white"
        sx={{
          cursor:
            data.stream.isQueued ||
            (data.stream.hasFailed && data?.stream.status !== EventStreamStatus.SOURCE_SELECTED) ||
            (data?.stream.status !== EventStreamStatus.STARTED &&
              data?.stream.status !== EventStreamStatus.SOURCE_SELECTED)
              ? 'not-allowed'
              : 'pointer',
        }}
        boxShadow={1}
        onClick={
          data.stream.isQueued ||
          (data.stream.hasFailed && data?.stream.status !== EventStreamStatus.SOURCE_SELECTED) ||
          (data?.stream.status !== EventStreamStatus.STARTED &&
            data?.stream.status !== EventStreamStatus.SOURCE_SELECTED)
            ? undefined
            : () => data.onClick(id, !data.isSwitchedOn)
        }
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          whiteSpace="nowrap"
          width="100%"
        >
          <Typography
            variant="h6"
            textOverflow="ellipsis"
            width="170px"
            // height="20px"
            overflow="hidden"
          >
            {SourceTypeDisplayName[data.type]}
          </Typography>
          <Box alignSelf="normal">
            <Switch
              checked={
                !data.stream.hasFailed &&
                ((data?.stream.selectedSourceId && data.isSwitchedOn) || data.isSingleSource)
              }
              disabled={
                data.stream.isQueued ||
                (data.stream.hasFailed &&
                  data?.stream.status !== EventStreamStatus.SOURCE_SELECTED) ||
                (data?.stream.status !== EventStreamStatus.STARTED &&
                data?.stream.status !== EventStreamStatus.SOURCE_SELECTED
                  ? true
                  : false)
              }
            />
          </Box>
        </Stack>
      </Center>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={false}
        style={{
          background: grey[400],
          width: '14px',
          height: '14px',
          borderWidth: '2px',
          right: '-6px',
        }}
      />
    </>
  );
};
