import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import baseTheme from 'themes/baseTheme';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import ActivityLog from 'views/ActivityLog';
import Analytics from 'views/Analytics';
import ControlCenter from 'views/ControlCenter';
import Dashboard from 'views/Dashboard/Dashboard';
import LoginPage from 'views/LoginPage';
import ManageTeams from 'views/ManageTeams';
import { SlatesDashboard } from 'views/SlatesDashboard';

import AppWrapper from 'components/Common/AppWrapper';
import PrivateRoute from 'components/Common/PrivateRoute';

import {
  ActivityLogAllowedRoles,
  AnalyticsAllowedRoles,
  ControlCenterAllowedRoles,
  DashboardAllowedRoles,
  ManageTeamsAllowedRoles,
} from 'constants/AccessConstants';
import { ROUTE_KEY, ROUTE_PATH } from 'constants/RouteConstants';

import { configureAmplify } from 'utils/helpers';

configureAmplify();
function App(): JSX.Element {
  return (
    <ThemeProvider theme={baseTheme}>
      <CssBaseline />
      <AppWrapper>
        <Router>
          <Switch>
            <Route exact path="/">
              <LoginPage />
            </Route>
            <PrivateRoute
              path={ROUTE_PATH[ROUTE_KEY.DASHBOARD]}
              allowedRoles={DashboardAllowedRoles}
              component={<Dashboard />}
            />
            <PrivateRoute
              path={`${ROUTE_PATH[ROUTE_KEY.CONTROL_CENTER]}/:eventId`}
              allowedRoles={ControlCenterAllowedRoles}
              component={<ControlCenter />}
            />
            <PrivateRoute
              path={ROUTE_PATH[ROUTE_KEY.CONTROL_CENTER]}
              allowedRoles={ControlCenterAllowedRoles}
              component={<ControlCenter />}
            />
            <PrivateRoute
              path={ROUTE_PATH[ROUTE_KEY.MANAGE_TEAMS]}
              allowedRoles={ManageTeamsAllowedRoles}
              component={<ManageTeams />}
            />
            <PrivateRoute
              path={ROUTE_PATH[ROUTE_KEY.ACTIVITY_LOG]}
              allowedRoles={ActivityLogAllowedRoles}
              component={<ActivityLog />}
            />
            <PrivateRoute
              path={ROUTE_PATH[ROUTE_KEY.ANALYTICS]}
              allowedRoles={AnalyticsAllowedRoles}
              component={<Analytics />}
            />
            <PrivateRoute
              path={ROUTE_PATH[ROUTE_KEY.SLATES]}
              allowedRoles={DashboardAllowedRoles}
              component={<SlatesDashboard />}
            />
          </Switch>
        </Router>
      </AppWrapper>
    </ThemeProvider>
  );
}

export default App;
