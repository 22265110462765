export const avatarColors = [
  '#90CAF9',
  '#A5D6A7',
  '#FFCC80',
  '#CE93D8',
  '#EF9A9A',
  '#BBDEFB',
  '#C8E6C9',
  '#FFE0B2',
  '#E1BEE7',
  '#FFCDD2',
];
