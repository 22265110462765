import { API } from 'aws-amplify';

import { EventStreamStatus } from 'constants/StreamConstants';

import environment from 'config';
import {
  AnalyticsResponse,
  APIEvent,
  CreateEventPayload,
  DeleteEventPayload,
  EventRequestQueryParams,
  SingleAPIEvent,
  UpdateEventPayload,
} from 'types';

export const listEvents = async (queryParams: EventRequestQueryParams): Promise<APIEvent[]> =>
  API.get(environment.API.REST.NAME, 'event/', {
    queryStringParameters: queryParams,
  });

export const getAnalyticsEvents = async (
  queryParams: EventRequestQueryParams
): Promise<AnalyticsResponse[]> =>
  API.get(environment.API.REST.NAME, 'event/analytics', {
    queryStringParameters: queryParams,
  });

export const getEvent = async (id: string): Promise<SingleAPIEvent> =>
  API.get(environment.API.REST.NAME, `event/${id}`, {});

export const createEvent = async (createEventPayload: CreateEventPayload): Promise<void> =>
  API.post(environment.API.REST.NAME, `event/`, {
    body: createEventPayload,
  });

export const updateEvent = async (updateEventPayload: UpdateEventPayload): Promise<void> =>
  API.put(environment.API.REST.NAME, `event/${updateEventPayload.id}`, {
    body: updateEventPayload,
  });

export const deleteEvent = async ({ eventId }: DeleteEventPayload): Promise<void> =>
  API.del(environment.API.REST.NAME, `event/${eventId}`, {});

export const runningEvents = async (): Promise<APIEvent[]> =>
  API.get(environment.API.REST.NAME, `event/running`, {});

// export const createEventStream = async (eventId: string): Promise<void> =>
//   API.post(environment.API.REST.NAME, `event/${eventId}/stream/create`, {
//     body: { status: EventStreamStatus.CREATED },
//   });

// export const startEventStream = async (eventId: string): Promise<void> =>
//   API.put(environment.API.REST.NAME, `event/${eventId}/stream/start`, {
//     body: { status: EventStreamStatus.STARTED },
//   });

// export const stopEventStream = async (eventId: string): Promise<void> =>
//   API.put(environment.API.REST.NAME, `event/${eventId}/stream/stop`, {
//     body: { status: EventStreamStatus.STOPPED },
//   });

// export const destroyEventStream = async (eventId: string): Promise<void> =>
//   API.del(environment.API.REST.NAME, `event/${eventId}/stream/destroy`, {
//     body: { status: EventStreamStatus.DESTROYED },
//   });

export const eventStreamOperation = async ({
  eventId,
  operation,
}: {
  eventId: string;
  operation: string;
}): Promise<void> =>
  API.post(environment.API.REST.NAME, `event/operate`, {
    body: { eventId: eventId, eventOperation: operation },
  });

export const switchEventStreamSource = async ({
  eventId,
  sourceId,
}: {
  eventId: string;
  sourceId: string;
}): Promise<void> =>
  API.post(environment.API.REST.NAME, `event/operate/source-select`, {
    body: {
      eventId: eventId,
      eventOperation: EventStreamStatus.SOURCE_SELECTED,
      sourceId: sourceId,
    },
  });

//Brighcove
export const publishBrightcove = async (id: string, hlsUrl: string, videoId): Promise<void> =>
  API.patch(environment.API.REST.NAME, `event/${id}/publish`, {
    body: {
      remoteUrl: hlsUrl,
      videoId: videoId,
    },
  });

// export const resetEventStream = async (eventId: string): Promise<void> =>
//   API.put(environment.API.REST.NAME, `event/${eventId}/stream/reset`, {
//     body: { status: EventStreamStatus.NON_EXISTENT },
//   });
