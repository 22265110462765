import { User } from 'types';

import { UserRole } from './AuthConstants';

export const DefaultTeamMemberInputs: User = {
  firstName: '',
  lastName: '',
  email: '',
  role: UserRole.UNASSIGNED,
  group: '',
};

// TODO: Change the gorup names accordingly
export enum AdminGroupNames {
  PRESIDIO = 'PRESIDIO',
  NHL = 'NHL',
}
export enum NHLLogo {
  NHLOriginalProductions = 'NHL-Original-Productions',
}
