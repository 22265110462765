import { Box } from '@mui/material';

import { JSONGridDetail } from 'components/Event/JSONGridDetail';

import { AWSFlow, AWSResource } from 'types';

interface IFlowResourceDetails {
  data: AWSResource<AWSFlow>;
}

const transformResourceDetails = (data: AWSResource<AWSFlow>) => {
  const { details } = data;

  return {
    details,
    Name: details?.Name,
    'Source Type': details?.SourceType,
    Status: details?.Status,
    Description: details?.Description,
  };
};

export const MediaconnectFlowResourceDetails = ({ data }: IFlowResourceDetails) => {
  const transformedDetails = transformResourceDetails(data);

  return (
    <Box py={1}>
      <JSONGridDetail data={transformedDetails} />
    </Box>
  );
};
