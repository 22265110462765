import { grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Sintony, sans-serif',
    caption: {
      fontSize: '0.6rem',
    },
    subtitle3: {
      fontSize: '0.72rem',
    },
  },
  palette: {
    primary: {
      main: '#1083DA',
    },
    secondary: {
      main: '#1083DA',
    },
    error: {
      main: red[600],
    },
    success: {
      main: '#24C142',
    },
    text: {
      primary: '#000000',
      secondary: '#9B9FA7',
    },
    common: {
      white: '#fff',
      black: '#000',
    },
    highlight: {
      underline: '#f86d20',
      secondary: grey[300],
      overlay: '#000000b5',
      darkBg: '#1E0F4A',
    },
    grey: {
      50: '#8080801f',
      100: '#0A0A0A19',
      200: '#aeaeae',
      300: '#c5c4c7',
      400: '#d0c8c8',
      500: '#8b8b8b',
      600: '#7e7e7e',
      700: '#999999',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          textTransform: 'none',
        },
        sizeLarge: {
          fontWeight: 'bold',
        },
        sizeMedium: {
          fontWeight: 'bold',
        },
        sizeSmall: {
          fontWeight: 'bold',
          fontSize: '0.85rem',
          padding: '4px 16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
    MuiStack: {
      defaultProps: {
        alignItems: 'center',
      },
    },
    MuiSwitch: {
      defaultProps: {
        sx: {
          '& .Mui-checked + .MuiSwitch-track': {
            backgroundColor: (theme) => `${theme.palette.success.main}  !important`,
          },
          '& .MuiButtonBase-root.Mui-checked': {
            color: (theme) => `${theme.palette.success.main}  !important`,
          },
          '& .MuiButtonBase-root.Mui-checked.Mui-disabled': {
            color: (theme) => `${theme.palette.success.main + '20'}  !important`,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthXl: (props) => {
          return {
            [props.theme.breakpoints.down('xl')]: {
              paddingLeft: '24px',
              paddingRight: '24px',
            },
          };
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        sx: {
          borderRadius: '6px',
          'button:nth-child(2)': {
            color: 'primary.main',
          },
          label: {
            color: 'grey.600',
          },
        },
      },
      styleOverrides: {
        inputRoot: {
          width: '250px',
          paddingTop: '5px',
          paddingBottom: '5px',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          minWidth: '400px',
          width: '400px',
          '.MuiAlert-icon': {
            color: 'white !important',
          },
          '.MuiAlert-root': {
            color: 'white',
            fontWeight: 'bold',
          },
          '.MuiAlert-standardSuccess': {
            backgroundColor: '#24C142',
          },
          '.MuiAlert-standardError': {
            backgroundColor: red[600],
          },
          '.MuiAlert-standardInfo': {
            backgroundColor: '#1083DA',
          },
          '.MuiAlert-standardWarning': {
            backgroundColor: '#F86D20',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          borderRadius: '6px',
        },
      },
      styleOverrides: {
        select: {
          width: '100px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '80px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '.MuiInputLabel-asterisk': {
            color: red[600],
          },
          ...(ownerState.size === 'small' && {
            '.MuiInputBase-inputSizeSmall, .MuiInputLabel-root': {
              fontSize: '0.85rem !important',
            },

            '.MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.MuiFormLabel-filled': {
              transform: 'translate(18px, -8px) scale(0.8)',
            },
          }),
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'small' && {
            fontSize: '0.85rem',
          }),
        }),
      },
    },
  },
});
// {
//   '.MuiInputBase-inputSizeSmall': {
//     fontSize: '0.85rem !important',
//   },
//   '.MuiInputLabel-root': {
//     fontSize: '0.85rem !important',
//   },
//   '.MuiInputLabel-root.Mui-focused': {
//     transform: 'translate(18px, -8px) scale(0.75)',
//   },
// },
export default theme;
