import { useState } from 'react';

import { Box, Button, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import { DeleteEventPopUp } from 'components/PopUp/DeleteEventPopUp';
import { EventPopUp } from 'components/PopUp/EventPopUp';

import { ROUTE_KEY, ROUTE_PATH } from 'constants/RouteConstants';

import { APIEvent } from 'types';
import { hasEventStreamed, isConsistentStateToDelete } from 'utils/eventHelpers';

interface EventActionsProps {
  event: APIEvent;
}

enum PopUpActions {
  EDIT,
  DELETE,
}

export function EventGridActions({ event }: EventActionsProps): JSX.Element {
  const [activePopUp, setActivePopUp] = useState<PopUpActions | null>(null);
  const disableDeleteEvent = hasEventStreamed(event) || !isConsistentStateToDelete(event);
  return (
    <Box display="flex" gap="20px" justifyContent="center">
      <Button
        size="small"
        href={`${ROUTE_PATH[ROUTE_KEY.CONTROL_CENTER]}/${event.id}`}
        target="_blank"
        variant="outlined"
        sx={{ fontSize: '10px' }}
      >
        CONTROL CENTER
      </Button>
      <IconButton onClick={() => setActivePopUp(PopUpActions.EDIT)} style={{ padding: '0' }}>
        <Edit color="primary" sx={{ width: '20px', height: '20px' }} />
      </IconButton>
      <IconButton
        disabled={disableDeleteEvent}
        onClick={() => setActivePopUp(PopUpActions.DELETE)}
        style={{ padding: '0' }}
      >
        <Delete color="error" sx={{ width: '20px', height: '20px' }} />
      </IconButton>
      <EventPopUp
        isEditMode
        eventToEdit={event.id}
        isPopUpOpen={activePopUp === PopUpActions.EDIT}
        close={() => setActivePopUp(null)}
      />
      <DeleteEventPopUp
        isDeleteEventPopUpOpen={activePopUp === PopUpActions.DELETE}
        close={() => setActivePopUp(null)}
        event={event}
      />
    </Box>
  );
}
