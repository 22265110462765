import { useState } from 'react';
import theme from 'themes/baseTheme';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Circle, ExpandMore } from '@mui/icons-material';

import { EventByDateHeader } from 'components/Event/EventByDateHeader';
import { EventCard } from 'components/Event/EventCard';
import { getBadgeColor } from 'components/Event/EventStatus';

import { APIEvent, StreamStatus } from 'types';

import { RenderTable } from './RenderTable';
import RunningEventDetails from './RunningEventDetails';
import RunningEventsHeader from './RunningEventsHeader';

const EventByDaySummaryTooltip = ({ events }: { events: APIEvent[] }): JSX.Element => {
  return (
    <div>
      {events.map((event) => {
        const { status, isQueued, hasFailed } = event.stream;
        return (
          <>
            <Typography
              variant="subtitle2"
              display="inline-flex"
              alignItems="center"
              key={event.id}
            >
              <Circle
                sx={{
                  color: getBadgeColor(status as unknown as StreamStatus, isQueued, hasFailed),
                  mr: 0.5,
                  width: '13px',
                  height: '13px',
                }}
              />
              {event.name} ({event.group})
            </Typography>
            <div></div>
          </>
        );
      })}
    </div>
  );
};

interface AccordionComponentProps {
  eventsByDate?: {
    scheduledTime: string;
    events: APIEvent[];
  };
  isRunning?: boolean;
  runningEvents?: {
    events: APIEvent[];
    isFetching: boolean;
    isError: boolean;
    isList: boolean;
  };
}

export const AccordionComponent = ({
  eventsByDate,
  isRunning,
  runningEvents,
}: AccordionComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={isOpen && !isRunning ? <EventByDaySummaryTooltip events={eventsByDate.events} /> : ''}
      arrow
      placement="top"
      leaveDelay={200}
    >
      <Accordion
        onChange={() => {
          setIsOpen(!isOpen);
        }}
        expanded={!isOpen}
        sx={
          isOpen && !isRunning
            ? {
                border: `1px solid transparent`,
                boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
                '&:hover': {
                  border: `1px solid ${
                    isOpen && !isRunning ? theme.palette.primary.main : 'transparent'
                  }`,
                },
              }
            : {
                boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
              }
        }
      >
        <AccordionSummary
          sx={
            isRunning && {
              backgroundColor: 'grey.50',
              padding: '8px',
            }
          }
          expandIcon={
            <IconButton>
              <ExpandMore />
            </IconButton>
          }
        >
          {!isRunning ? (
            <EventByDateHeader
              date={eventsByDate.scheduledTime}
              eventCount={eventsByDate.events.length}
            />
          ) : (
            <RunningEventsHeader eventDetails={runningEvents} />
          )}
        </AccordionSummary>
        <AccordionDetails
          sx={
            isRunning && {
              backgroundColor: 'grey.50',
            }
          }
        >
          {isRunning ? (
            !runningEvents.isList ? (
              <RunningEventDetails eventDetails={runningEvents} />
            ) : (
              <RenderTable events={runningEvents?.events} isRunning />
            )
          ) : (
            <Grid container gap="30px">
              {eventsByDate?.events?.map((event) => {
                return (
                  <Grid
                    item
                    // sx={userInfo.role === UserRole.APPLICATION_ADMIN ? styles.eventCard : {}}
                    key={event.id}
                  >
                    <EventCard event={event} />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Tooltip>
  );
};
