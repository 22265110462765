import { useContext } from 'react';
import theme from 'themes/baseTheme';

import { Box, Stack, Tooltip, Typography } from '@mui/material';

import nhlLogo from 'assets/nhlflat.svg';

import { APIEvent } from 'types';
import { formatName } from 'utils/helpers';

import { GroupInfoContext } from '../../contexts/GroupInfoContext';

interface EventDetailProps {
  event: APIEvent;
  isInline?: boolean;
}

export function EventDetail({ event, isInline = false }: EventDetailProps): JSX.Element {
  const { firstName, lastName } = event.createdBy;
  const creatorName = formatName(firstName, lastName) || 'Unknown';
  const { getGroupLogoById } = useContext(GroupInfoContext);
  const getGroupLogo = (id: string): JSX.Element => {
    const logo = getGroupLogoById(id);
    if (logo) {
      return (
        <Box>
          <img height="30px" src={logo} alt="logo" style={{ margin: '4px 0 0 0' }} />
        </Box>
      );
    }
    return (
      <Box>
        <img height="30px" src={nhlLogo} alt="logo" style={{ margin: '4px 0 0 0' }} />
      </Box>
    );
  };
  return (
    <Stack
      spacing={isInline ? 1 : 0}
      direction={isInline ? 'row' : 'column'}
      alignItems={isInline ? 'center' : 'none'}
      sx={{
        mb: isInline ? '0px' : '10px',
        minWidth: '220px',
        [theme.breakpoints.down(897)]: {
          flexWrap: 'wrap',
        },
      }}
    >
      <Stack spacing={1} alignItems="center" direction="row" style={{ padding: '10px 0px' }}>
        {getGroupLogo(event.group)}
        <Tooltip
          title={isInline ? event.name || 'Event Name - Unknown' : ''}
          placement="bottom-start"
        >
          <Typography
            fontWeight="bold"
            variant="subtitle2"
            sx={
              isInline
                ? {
                    [theme.breakpoints.down(1100)]: {
                      width: '150px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                    [theme.breakpoints.between(1100, 1300)]: {
                      maxWidth: '300px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                    [theme.breakpoints.up(1300)]: {
                      maxWidth: '400px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }
                : { overflowWrap: 'anywhere' }
            }
          >
            {event.name || 'Event Name - Unknown'}
          </Typography>
        </Tooltip>
      </Stack>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="subtitle2" color="text.secondary">
          {isInline ? '|' : 'created by'}
        </Typography>
        <Typography variant="subtitle2">{creatorName}</Typography>
      </Stack>
    </Stack>
  );
}
