import { format } from 'date-fns';

import { Grid, Typography } from '@mui/material';

interface EventByDateHeaderProps {
  date: string;
  eventCount: number;
}

export function EventByDateHeader({ date, eventCount }: EventByDateHeaderProps): JSX.Element {
  return (
    <Grid spacing={1} container direction="row" wrap="nowrap" alignItems="center">
      <Grid item sx={{ textAlign: 'center' }}>
        <Typography variant="h3" sx={{ color: 'grey.400', fontWeight: 'bold' }}>
          {format(new Date(date), 'dd')}
        </Typography>
      </Grid>
      <Grid
        item
        flexGrow="1"
        ml="5px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <>
          <Typography
            variant="subtitle1"
            sx={{ color: 'grey.500', fontWeight: 'bold', textTransform: 'uppercase' }}
          >
            {format(new Date(date), 'MMMM yy')}
            <Typography variant="subtitle2" sx={{ color: 'grey.500', textTransform: 'uppercase' }}>
              {format(new Date(date), 'EEEE')}
            </Typography>
          </Typography>
        </>
        <Typography
          variant="caption"
          sx={{
            padding: '3px 10px',
            backgroundColor: '#f86d20cc',
            borderRadius: '6px',
            fontWeight: 'bolder',
            fontSize: '12px',
            color: 'common.white',
            height: '25px',
            marginRight: '10px',
          }}
        >
          {eventCount} {eventCount <= 1 ? 'EVENT' : 'EVENTS'}
        </Typography>
        {/* <Grid item display="flex" gap="5px" alignItems="center" mt="-10px">
          <Divider
            variant="inset"
            sx={{ height: '0px', flexGrow: '1', borderColor: 'white', ml: '0px' }}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
}
