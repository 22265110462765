import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

import { EventType } from 'types';

const filter = createFilterOptions({ trim: true });

interface AutoCompleteInputProps {
  label: string;
  options: EventType[];
  value: EventType;
  required?: boolean;
  onChange: (value: EventType) => void;
  disabled?: boolean;
  freeSolo?: boolean;
  customStyle?: boolean;
  style?: React.CSSProperties;
  size?: 'small' | 'medium';
}

export const AutoCompleteInput = ({
  label,
  options,
  value,
  onChange,
  required = false,
  disabled = false,
  freeSolo = true,
  customStyle = false,
  style,
  size = 'small',
}: AutoCompleteInputProps) => {
  const onInputChange = (value) => {
    if (typeof value === 'string') {
      onChange({
        label: value,
        value: value,
      });
    } else if (value && value.value) {
      onChange({
        label: value.value,
        value: value.value,
      });
    } else {
      onChange(value);
    }
  };

  return (
    <Autocomplete
      value={value}
      size={size}
      freeSolo={freeSolo}
      disabled={disabled}
      options={options}
      filterOptions={(options, params) => {
        const filtered = filter(options, params) as EventType[];
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting && freeSolo) {
          filtered.push({
            label: `Add "${inputValue}"`,
            value: inputValue,
          });
        }
        return filtered;
      }}
      onChange={(_, value: EventType) => onInputChange(value)}
      getOptionLabel={(options: EventType) => `${options.label}`}
      renderInput={(params) => (
        <TextField
          required={required}
          {...params}
          label={label}
          sx={
            customStyle
              ? {
                  '& .MuiInputBase-root': {
                    width: '100%',
                  },
                  ...style,
                }
              : {}
          }
        />
      )}
    />
  );
};
