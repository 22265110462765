import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import {
  Autocomplete,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import { putUser } from 'services/userService';

import { CurrentUserInfoContext } from 'contexts/CurrentUserInfoContext';
import { useGroups } from 'contexts/GroupInfoContext';
import { useToaster } from 'contexts/ToasterContext';

import { AllowedUserRoleCreations } from 'constants/AccessConstants';
import { UserRole, UserRoleDisplay } from 'constants/AuthConstants';
import { AdminGroupNames } from 'constants/TeamConstants';

import { PopUpButtonProps, User } from 'types';
import { isUserInputValid } from 'utils/helpers';

import { PopUp } from '.';

interface UpdateMemberProps {
  isUpdateMemberPopUpOpen: boolean;
  close: () => void | Promise<void>;
  member: User;
  triggerRefetch: () => void;
}

export function UpdateMemberPopUp({
  isUpdateMemberPopUpOpen,
  close,
  member,
  triggerRefetch,
}: UpdateMemberProps): JSX.Element {
  const [memberInfo, setMemberInfo] = useState<User>(member);
  const { groups } = useGroups();
  const { userInfo } = useContext(CurrentUserInfoContext);
  const { triggerToast } = useToaster();
  const allowedUserRoleCreations = AllowedUserRoleCreations[userInfo.role];

  useEffect(() => {
    if (isUpdateMemberPopUpOpen) {
      setMemberInfo(member);
    }
  }, [isUpdateMemberPopUpOpen]);

  const { mutate: editUser, isLoading: isEditingUser } = useMutation(
    (editeduser: User) => putUser(editeduser),
    {
      onSuccess: () => {
        triggerToast({
          message: `${memberInfo?.firstName} ${memberInfo?.lastName} successfully updated`,
          type: 'success',
        });
        triggerRefetch();
        close();
      },
      onError: () => {
        triggerToast({
          message: `Error while trying to update ${memberInfo?.firstName} ${memberInfo?.lastName}`,
          type: 'error',
        });
      },
    }
  );

  const updateMember = () => {
    if (userInfo?.role === UserRole.GROUP_MANAGER) {
      editUser({ ...memberInfo, group: userInfo?.group });
    } else {
      editUser(memberInfo);
    }
  };

  const handleGroupSelect = (group: string) => {
    if (group) {
      setMemberInfo({ ...memberInfo, group });
    }
  };

  const buttons: PopUpButtonProps[] = [
    { name: 'CANCEL', handler: close, variant: 'outlined' },
    { name: 'UPDATE', handler: updateMember, variant: 'contained', isLoading: isEditingUser },
  ];

  const handleMemberInputChange =
    (key: keyof User) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setMemberInfo((prevMemberInfo) => ({
        ...prevMemberInfo,
        [key]: event.target.value,
        group: key === 'role' ? '' : prevMemberInfo.group,
      }));
    };

  return (
    <PopUp
      isPopUpOpen={isUpdateMemberPopUpOpen}
      title="UPDATE MEMBER"
      buttons={buttons}
      close={close}
      disableCTA={!isUserInputValid(memberInfo, userInfo.role)}
    >
      <Box display="flex" flexDirection="column" gap="20px">
        <TextField
          size="small"
          label="First name"
          value={memberInfo.firstName}
          required
          onChange={handleMemberInputChange('firstName')}
          error={memberInfo.firstName.trim().length === 0}
        />
        <TextField
          size="small"
          label="Last name"
          value={memberInfo.lastName}
          required
          onChange={handleMemberInputChange('lastName')}
          error={memberInfo.lastName.trim().length === 0}
        />
        <TextField
          size="small"
          label="Email"
          type="email"
          value={memberInfo.email}
          required
          error={memberInfo.email.trim().length === 0}
          onChange={handleMemberInputChange('email')}
        />
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 600, color: 'grey.600' }}>
            ROLE
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={memberInfo.role}
            onChange={handleMemberInputChange('role')}
          >
            {allowedUserRoleCreations.map((role) => (
              <FormControlLabel
                key={role}
                value={role}
                control={<Radio size="small" />}
                label={<Typography variant="body2">{UserRoleDisplay[role]}</Typography>}
              />
            ))}
          </RadioGroup>
        </Box>
        {groups && (
          <Autocomplete
            id="select-member-team"
            disabled={userInfo.role !== UserRole.APPLICATION_ADMIN}
            readOnly={userInfo.role === UserRole.GROUP_MANAGER}
            value={
              userInfo.role === UserRole.APPLICATION_ADMIN
                ? memberInfo?.group || ''
                : userInfo?.group || ''
            }
            size="medium"
            options={
              memberInfo.role !== UserRole.APPLICATION_ADMIN
                ? groups.map((group) => group.id || '')
                : Object.keys(AdminGroupNames)
            }
            onChange={(_, value: string) => handleGroupSelect(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Choose Team"
                required
                error={
                  userInfo.role === UserRole.APPLICATION_ADMIN &&
                  memberInfo?.group?.trim()?.length === 0
                }
              />
            )}
          />
        )}
      </Box>
    </PopUp>
  );
}
