import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { addDays, format, subDays } from 'date-fns';
import { calenderTheme } from 'themes/calenderTheme';

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  ThemeProvider,
  Tooltip,
} from '@mui/material';
import { Add, FormatListBulleted, Refresh, ViewQuiltRounded } from '@mui/icons-material';

import { ActiveViewHeader } from 'components/Common/ActiveViewHeader';
import DateRangePicker from 'components/Common/DateRangePicker';
import { EventPopUp } from 'components/PopUp/EventPopUp';

import { listEvents } from 'services/eventService';

import { ActiveMenuContext } from 'contexts/ActiveMenuContext';
import { useCurrentUserInfo } from 'contexts/CurrentUserInfoContext';
import { useGroups } from 'contexts/GroupInfoContext';

import { UserRole } from 'constants/AuthConstants';
import { ROUTE_KEY } from 'constants/RouteConstants';

import { useActionAccess } from 'hooks/useActionAccess';
import useDebounceHook from 'hooks/useDebounceHook';

import { EventRequestQueryParams, GroupInfo, TransformApiEventsType } from 'types';
import { transformApiEvents } from 'utils/eventHelpers';

import { GridView } from './GridView';
import { ListView } from './ListView';

// Uncomment when we event operation has to be excluded for application admin
// const styles = buildStyles({
//   eventCard: (theme: Theme) => ({
//     [theme.breakpoints.up('lg')]: {
//       minWidth: '720px',
//     },
//   }),
// });

function Dashboard(): JSX.Element {
  const { setActiveMenu } = useContext(ActiveMenuContext);
  const [fromDate, setFromDate] = useState<Date | null>(subDays(new Date(), 1));
  const [toDate, setToDate] = useState<Date | null>(addDays(new Date(), 60));
  const [createEventPopUpState, setCreateEventPopUpState] = useState(false);
  const { canCreateEvent, canFilterByTeam } = useActionAccess();
  const { userInfo } = useCurrentUserInfo();
  const [showView, setShowView] = useState(false);
  const { groups, isUserAdminGroup } = useGroups();
  const [selectedGroup, setSelectedGroup] = useState<GroupInfo | null>(null);
  const defaultGroup = userInfo.role === UserRole.APPLICATION_ADMIN ? null : userInfo.group;
  const [changedValue, setChangedValue] = useState<any>(null);
  const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [reset, setReset] = useState(false);

  const debouncedValue = useDebounceHook(changedValue, 2000);

  const setView = () => {
    setShowView(!showView);
  };

  const getDateParams = (isMMDDYY = false) => {
    const dateFormat = isMMDDYY ? 'MM-dd-yyyy' : 'yyyy-MM-dd';
    const pathParams: EventRequestQueryParams = {
      startDate: format(fromDate, dateFormat),
      endDate: format(toDate, dateFormat),
    };

    return pathParams;
  };

  const getData = async (selectedGroup: GroupInfo): Promise<TransformApiEventsType[]> => {
    const pathParams = getDateParams();
    if (isUserAdminGroup()) {
      if (selectedGroup) {
        pathParams.group = selectedGroup.id;
      }
    } else {
      pathParams.group = defaultGroup;
    }
    const response = await listEvents(pathParams);
    const eventByDate = transformApiEvents(response, fromDate, toDate);
    return eventByDate;
  };

  const dateParamsToRender = getDateParams(true);

  const {
    isFetching,
    isError,
    data: events = [],
    refetch,
  } = useQuery(['events'], () => getData(selectedGroup), {
    refetchOnWindowFocus: false,
  });

  const resetFilters = () => {
    setReset(!reset);
    setFromDate(subDays(new Date(), 1));
    setToDate(addDays(new Date(), 60));
    setAnchorEl(null);
    setCount(0);
    userInfo.role === UserRole.APPLICATION_ADMIN && setSelectedGroup(null);
  };

  useEffect(() => {
    refetch();
  }, [selectedGroup, reset]);

  useEffect(() => {
    if (anchorEl === null && count !== 0) {
      refetch();
    }
  }, [anchorEl]);

  useEffect(() => {
    if (debouncedValue) {
      refetch();
    }
  }, [debouncedValue]);

  useEffect(() => {
    // clean up session storage if any
    sessionStorage.removeItem('AUTH_ERROR');
    setActiveMenu(ROUTE_KEY.DASHBOARD);
  }, []);

  const renderCurrentView = () => {
    return showView ? (
      <ListView
        isFetching={isFetching}
        isError={isError}
        dateParamsToRender={dateParamsToRender}
        events={events}
        selectedGroup={selectedGroup}
        defaultGroup={defaultGroup}
      />
    ) : (
      <GridView
        isFetching={isFetching}
        isError={isError}
        dateParamsToRender={dateParamsToRender}
        events={events}
        selectedGroup={selectedGroup}
        defaultGroup={defaultGroup}
      />
    );
  };
  return (
    <>
      <Box sx={{ pt: '25px' }}>
        <ActiveViewHeader title="Dashboard">
          <Tooltip title="Reset">
            <IconButton onClick={resetFilters}>
              <Refresh sx={{ color: 'primary.main' }} />
            </IconButton>
          </Tooltip>
          {canFilterByTeam && (
            <Autocomplete
              id="select-team"
              size="small"
              value={selectedGroup}
              getOptionLabel={(option: GroupInfo) => `${option.name} (${option.id})`}
              options={groups || []}
              onChange={(_, value: GroupInfo) => setSelectedGroup(value)}
              renderInput={(params) => <TextField {...params} label="Filter by Group" />}
            />
          )}
          <ThemeProvider theme={calenderTheme}>
            <DateRangePicker
              dateObj={{
                fromDate,
                toDate,
                count,
                anchorEl,
                setFromDate,
                setToDate,
                setCount,
                setAnchorEl,
                setChangedValue,
                reset,
              }}
            />
          </ThemeProvider>

          {canCreateEvent && (
            <Button
              onClick={() => setCreateEventPopUpState(true)}
              size="small"
              variant="contained"
              startIcon={<Add />}
              sx={{ fontWeight: 'bold' }}
            >
              CREATE EVENT
            </Button>
          )}
          <Grid
            item
            sx={{
              position: 'relative',
            }}
          >
            {/* <Button onClick={() => setView()} variant="contained">
              {showView ? (
                <ViewQuiltRounded
                  style={{
                    fontSize: '1.2rem',
                  }}
                />
              ) : (
                <FormatListBulleted
                  style={{
                    fontSize: '1.2rem',
                  }}
                />
              )}
            </Button> */}
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: 'primary.main',
                cursor: 'pointer',
                borderRadius: '2px',
                width: '30px',
                height: '30px',
              }}
              onClick={() => setView()}
            >
              {showView ? (
                <ViewQuiltRounded
                  style={{
                    fontSize: '16px',
                    color: 'white',
                  }}
                />
              ) : (
                <FormatListBulleted
                  style={{
                    fontSize: '16px',
                    color: 'white',
                  }}
                />
              )}
            </Stack>
            <div
              style={{
                color: 'grey.300',
                fontSize: '0.45rem',
                position: 'absolute',
                bottom: '-1rem',
                width: '50px',
                left: 0,
              }}
            >
              {showView ? 'Grid View' : 'List View'}
            </div>
          </Grid>
        </ActiveViewHeader>
        {renderCurrentView()}
      </Box>
      <EventPopUp
        isPopUpOpen={createEventPopUpState}
        close={() => setCreateEventPopUpState(false)}
      />
    </>
  );
}

export default Dashboard;
