import { createContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { io } from 'socket.io-client';

import environment from 'config';
import {
  WEBSOCKET_AUTHENTICATION_REQUEST,
  WebSocketActionTypes,
  WebSocketEventRequest,
} from 'types';

enum ReadyState {
  OPENING = 'opening',
  OPEN = 'open',
  CLOSED = 'closed',
}

export const WebSocketSessionContext = createContext<
  Partial<{
    subscribeToEvent: (eventId: string) => void;
    unsubscribeToEvent: (eventId: string) => void;
  }>
>({});

export const socketConnection = io(environment.API.REST.ENDPOINT, {
  ackTimeout: 10000,
  retries: 3,
});

export function WebSocketSessionProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // cache requests before authentication
  const [failedRequest, setFailedRequest] = useState<WebSocketEventRequest>(null);
  const [lastSucceededRequest, setLastSucceededRequest] = useState<WebSocketEventRequest>(null);
  const [hasFailed, setHasFailed] = useState(false);
  let backOffTime = 100;
  let backOffLimit = 3;

  const connectionStatus = {
    [ReadyState.OPENING]: 'opening',
    [ReadyState.OPEN]: 'open',
    [ReadyState.CLOSED]: 'closed',
  }[socketConnection.io._readyState];

  useEffect(() => {
    socketConnection.on('authentication', (data) => {
      if (data?.authenticated) {
        setIsAuthenticated(true);
        return;
      }

      try {
        const parsedData = JSON.parse(data);
        // check for 403 just in case
        if (parsedData.statusCode === 403 && parsedData.body === '') {
          setHasFailed(true);
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.log({ error });
      }
    });

    return () => {
      socketConnection.disconnect();
    };
  }, []);

  useEffect(() => {
    if (hasFailed && socketConnection.io._readyState === ReadyState.OPEN.toString()) {
      if (!isAuthenticated && backOffLimit >= 0) {
        backOffTime += 100;
        backOffLimit -= 1;
        setTimeout(() => {
          if (lastSucceededRequest) {
            setHasFailed(false);
            socketConnection.emit(
              lastSucceededRequest.subscriptionEvent,
              lastSucceededRequest.body
            );
            setIsAuthenticated(true);
          }
        }, backOffTime);
      }
    }
  }, [hasFailed, connectionStatus]);

  useEffect(() => {
    const updateUser = async () => {
      try {
        const currentSession = await Auth.currentSession();
        if (
          currentSession.isValid() &&
          socketConnection.io._readyState === ReadyState.OPEN.toString()
        ) {
          const authenticationRequest: WEBSOCKET_AUTHENTICATION_REQUEST = {
            token: currentSession?.getIdToken()?.getJwtToken(),
          };
          socketConnection.emit(WebSocketActionTypes.AUTHENTICATE, authenticationRequest);
        }
      } catch {
        // do nothing
      }
    };
    updateUser();
  }, [connectionStatus]);

  useEffect(() => {
    // resend the cached requests after authenticated
    if (isAuthenticated && failedRequest) {
      socketConnection.emit(failedRequest.subscriptionEvent, failedRequest.body);
      // set last succeded message to backoff incase we get a 403
      setLastSucceededRequest(failedRequest);
      setFailedRequest(null);
    }
  }, [isAuthenticated, failedRequest]);

  const subscribeToEvent = async (eventId: string) => {
    const request: WebSocketEventRequest = {
      subscriptionEvent: WebSocketActionTypes.EVENT_SUBSCRIBE,
      body: {
        eventId,
      },
    };
    if (isAuthenticated) {
      socketConnection.emit(request.subscriptionEvent, request.body);
      // set the last succeeded message to backoff incase there is 403 error
      setLastSucceededRequest(request);
    } else {
      // put request in cache
      setFailedRequest(request);
    }
  };

  const unsubscribeToEvent = async (eventId: string) => {
    const request: WebSocketEventRequest = {
      subscriptionEvent: WebSocketActionTypes.EVENT_UNSUBSCRIBE,
      body: {
        eventId,
      },
    };
    if (isAuthenticated) {
      socketConnection.emit(request.subscriptionEvent, request.body);
      // set the last succeeded message to backoff incase there is 403 error
      setLastSucceededRequest(request);
    } else {
      // put request in cache
      setFailedRequest(request);
    }
  };

  return (
    <WebSocketSessionContext.Provider
      value={{
        subscribeToEvent,
        unsubscribeToEvent,
      }}
    >
      {children}
    </WebSocketSessionContext.Provider>
  );
}
