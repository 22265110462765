import { createContext, useState } from 'react';

import { ROUTE_KEY } from 'constants/RouteConstants';

export const ActiveMenuContext = createContext<{
  activeMenu: ROUTE_KEY | null;
  setActiveMenu?: (menu: ROUTE_KEY) => void;
}>({ activeMenu: ROUTE_KEY.DASHBOARD });

export const ActiveMenuProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const [activeMenu, setActiveMenu] = useState<ROUTE_KEY>(ROUTE_KEY.DASHBOARD);

  return (
    <ActiveMenuContext.Provider value={{ activeMenu, setActiveMenu }}>
      {children}
    </ActiveMenuContext.Provider>
  );
};
