import { PropsWithChildren } from 'react';

import { Stack, StackProps } from '@mui/material';

export const Center = ({ children, ...otherProps }: PropsWithChildren<StackProps>): JSX.Element => {
  return (
    <Stack {...otherProps} justifyContent="center" alignItems="center">
      {children}
    </Stack>
  );
};
