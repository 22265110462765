import { API } from 'aws-amplify';

import environment from 'config';
import { GroupInfo } from 'types';

export const getAllGroups = (): Promise<GroupInfo[]> =>
  API.get(environment.API.REST.NAME, 'group', {});

export const getGroupById = (id: string): Promise<GroupInfo> =>
  API.get(environment.API.REST.NAME, `group/${id}`, {});
