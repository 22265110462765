import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Box, Button } from '@mui/material';

import { DisableMemberPopUp } from 'components/PopUp/DisableMemberPopUp';
import { RemoveMemberPopUp } from 'components/PopUp/RemoveMemberPopUp';
import { UpdateMemberPopUp } from 'components/PopUp/UpdateMemberPopUp';

import { User } from 'types';

interface TeamMemberActionsProps {
  member: User;
  isDisabled?: boolean;
}

enum PopUpActions {
  UPDATE,
  REMOVE,
  DISABLE,
}

export function TeamMemberActions({ member, isDisabled }: TeamMemberActionsProps): JSX.Element {
  const [activePopUp, setActivePopUp] = useState<PopUpActions | null>(null);
  const queryClient = useQueryClient();

  const triggerUserRefetch = () => {
    queryClient.invalidateQueries(['users']);
  };

  return (
    <Box display="flex" gap="40px">
      <Button onClick={() => setActivePopUp(PopUpActions.UPDATE)}>Update</Button>
      <Button onClick={() => setActivePopUp(PopUpActions.REMOVE)} color="error">
        Remove
      </Button>
      <Button
        onClick={() => setActivePopUp(PopUpActions.DISABLE)}
        disabled={isDisabled}
        color="error"
      >
        Disable
      </Button>
      <RemoveMemberPopUp
        member={member}
        isRemovePopUpOpen={activePopUp === PopUpActions.REMOVE}
        close={() => setActivePopUp(null)}
        triggerRefetch={triggerUserRefetch}
      />
      <DisableMemberPopUp
        member={member}
        isDisablePopUpOpen={activePopUp === PopUpActions.DISABLE}
        close={() => setActivePopUp(null)}
        triggerRefetch={triggerUserRefetch}
      />
      <UpdateMemberPopUp
        member={member}
        isUpdateMemberPopUpOpen={activePopUp === PopUpActions.UPDATE}
        close={() => setActivePopUp(null)}
        triggerRefetch={triggerUserRefetch}
      />
    </Box>
  );
}
