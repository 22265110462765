import { useMutation } from '@tanstack/react-query';

import { Typography } from '@mui/material';

import { putUser } from 'services/userService';

import { useToaster } from 'contexts/ToasterContext';

import { UserRole } from 'constants/AuthConstants';

import { PopUpButtonProps, User } from 'types';

import { PopUp } from '.';

interface DisableMemberPopUpProps {
  isDisablePopUpOpen: boolean;
  close: () => void | Promise<void>;
  member: User;
  triggerRefetch: () => void;
}

export function DisableMemberPopUp({
  isDisablePopUpOpen,
  close,
  member,
  triggerRefetch,
}: DisableMemberPopUpProps): JSX.Element {
  const { firstName, lastName } = member;
  const name = `${firstName} ${lastName}`;
  const { triggerToast } = useToaster();

  const { mutate: disableUser, isLoading: isDisablingUser } = useMutation(
    (userToDisable: User) => putUser(userToDisable),
    {
      onSuccess: () => {
        triggerRefetch();
        triggerToast({
          message: `${name}'s access has been disabled`,
          type: 'success',
        });
        close();
      },
      onError: () => {
        triggerToast({
          message: `Error while trying to disable user`,
          type: 'error',
        });
      },
    }
  );

  const disableMember = () => {
    disableUser({ ...member, role: UserRole.UNASSIGNED });
  };

  const buttons: PopUpButtonProps[] = [
    { name: 'CANCEL', handler: close, variant: 'outlined' },
    { name: 'DISABLE', handler: disableMember, variant: 'contained', isLoading: isDisablingUser },
  ];

  return (
    <PopUp
      isPopUpOpen={isDisablePopUpOpen}
      buttons={buttons}
      close={close}
      description={
        <Typography sx={{ fontWeight: '600' }}>
          Are you sure you want to
          <Typography
            component="span"
            variant="body1"
            sx={{
              color: 'primary.main',
              fontWeight: '600',
              mx: '5px',
            }}
          >
            disable
          </Typography>
          {name} application access?
        </Typography>
      }
    ></PopUp>
  );
}
