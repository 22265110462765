import { Box, Stack, Typography } from '@mui/material';

import { RunningEventDetailsProps } from './RunningEventDetails';

const RunningEventsHeader = ({ eventDetails }: RunningEventDetailsProps) => {
  const { events, isError, isFetching } = eventDetails;
  return (
    <Stack direction="row" width="100%" justifyContent="space-between">
      <Box>
        <Typography variant="h6" display="inline-flex" alignItems="center">
          <Box
            className={events.length > 0 ? 'pulse' : ''}
            sx={{
              backgroundColor:
                events.length > 0
                  ? 'success.main'
                  : isError && !isFetching
                  ? 'error.main'
                  : 'success.main',
              mr: 1,
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              '&.pulse': {
                animation: 'pulse 2s infinite',
                margin: '0 10px 0px 4px',
              },
            }}
          />
          <b>RUNNING EVENTS</b>
        </Typography>
      </Box>
      <Typography
        variant="caption"
        sx={{
          padding: '3px 10px',
          backgroundColor: '#f86d20cc',
          borderRadius: '6px',
          fontWeight: 'bolder',
          fontSize: '12px',
          color: 'common.white',
          height: '25px',
          marginRight: '10px',
        }}
      >
        {events.length} {events.length <= 1 ? 'EVENT' : 'EVENTS'}
      </Typography>
    </Stack>
  );
};

export default RunningEventsHeader;
