import { PropsWithChildren } from 'react';

import { Grid, Typography } from '@mui/material';

interface ActiveViewHeaderProps {
  title: string;
}
export function ActiveViewHeader({
  title,
  children,
}: PropsWithChildren<ActiveViewHeaderProps>): JSX.Element {
  return (
    <Grid
      container
      justifyContent="space-between"
      direction={{ xs: 'column', sm: 'row' }}
      rowGap="20px"
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            position: 'relative',
            textTransform: 'uppercase',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: '100%',
              left: '0',
              width: '100px',
              height: '6px',
              backgroundColor: 'highlight.underline',
            },
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item display="flex" gap="10px" alignItems="center">
        {children}
      </Grid>
    </Grid>
  );
}
