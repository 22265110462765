import { useContext, useMemo } from 'react';

import { CurrentUserInfoContext } from 'contexts/CurrentUserInfoContext';

import { validateUser } from 'utils/helpers';

import {
  CreateEventAllowedRoles,
  DeleteEventAllowedRoles,
  EditEventAllowedRoles,
  FilterByTeamAllowedRoles,
  ManageStreamAllowedRoles,
} from '../constants/AccessConstants';

interface ActionAccessReturnType {
  canCreateEvent: boolean;
  canEditEvent: boolean;
  canDeleteEvent: boolean;
  canFilterByTeam: boolean;
  canManageStreams: boolean;
}

export function useActionAccess(): ActionAccessReturnType {
  const { userInfo } = useContext(CurrentUserInfoContext);
  const accessInfo = useMemo(() => {
    return {
      canCreateEvent: validateUser(userInfo.role, CreateEventAllowedRoles),
      canEditEvent: validateUser(userInfo.role, EditEventAllowedRoles),
      canDeleteEvent: validateUser(userInfo.role, DeleteEventAllowedRoles),
      canFilterByTeam: validateUser(userInfo.role, FilterByTeamAllowedRoles),
      canManageStreams: validateUser(userInfo.role, ManageStreamAllowedRoles),
    };
  }, [userInfo]);
  return accessInfo;
}
