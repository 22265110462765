import { useRef, useState } from 'react';
import ReactHlsPlayer from '@panelist/react-hls-player/dist';

import { Box, Typography } from '@mui/material';
import { MovieRounded } from '@mui/icons-material';

import { Center } from 'components/Common/Centered';
import OutlinedBox from 'components/Common/OutlinedBox';

import { CONTROL_CENTER_OUTPUT_PATH_ID } from 'constants/CommonConstants';
import { UserTourClassSelector } from 'constants/UserTourConstants';

export const HLSOutputNode = ({
  outputUrl,
  isActive,
}: {
  outputUrl: string;
  isActive: boolean;
}): JSX.Element => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const playerRef = useRef<HTMLVideoElement>(null);

  return (
    <OutlinedBox
      p={0.5}
      borderRadius={1}
      width="100%"
      height="100%"
      id={CONTROL_CENTER_OUTPUT_PATH_ID}
      active={isActive}
      className={UserTourClassSelector.SHOW_OUTPUTS}
    >
      <Box
        borderRadius="5px"
        width="100%"
        height="100%"
        position="relative"
        sx={{ cursor: outputUrl ? 'pointer' : 'normal' }}
      >
        <ReactHlsPlayer
          style={{ borderRadius: '5px' }}
          src={outputUrl}
          playerRef={playerRef}
          width="100%"
          height="100%"
          autoPlay
          controls
          onLoadedMetadata={(e) => {
            setHasLoaded(true);
          }}
        />
        {!hasLoaded && (
          <Center
            width="100%"
            height="100%"
            borderRadius={1}
            bgcolor="grey.100"
            color="grey.600"
            position="absolute"
            top="0"
            textAlign="center"
          >
            <MovieRounded fontSize="large" sx={{ mb: 1 }} />
            <Typography variant="body2">Loading...</Typography>
          </Center>
        )}
      </Box>
    </OutlinedBox>
  );
};
