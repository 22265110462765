import _ from 'lodash';

import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';

import { RunningEvents } from 'views/RunningEvents';

import { RenderTable } from 'components/Common/RenderTable';

import { useGroups } from 'contexts/GroupInfoContext';

import { FilterEventsWithRange, GroupInfo, TransformApiEventsType } from 'types';

export function ListView({
  isFetching,
  isError,
  dateParamsToRender,
  selectedGroup,
  defaultGroup,
  events,
}: {
  isFetching: boolean;
  isError: boolean;
  dateParamsToRender: FilterEventsWithRange;
  selectedGroup: GroupInfo;
  defaultGroup: string;
  events: TransformApiEventsType[];
}): JSX.Element {
  const transformEvents = _.flatten(events.map((event) => event.events));
  const { isUserAdminGroup } = useGroups();

  return (
    <Container maxWidth={false} disableGutters>
      <Box>
        <RunningEvents group={isUserAdminGroup() ? selectedGroup?.id : defaultGroup} isList />
      </Box>
      <Stack mt={4} direction="row">
        <Box>
          <Typography variant="h6">
            <b>UPCOMING EVENTS</b>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {dateParamsToRender.startDate} to {dateParamsToRender.endDate}
          </Typography>
        </Box>
        <Box flexGrow="1" height="1px" bgcolor="grey.300" mx={1} />
      </Stack>
      <Box mt={5}>
        {isFetching ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="30vh">
            <CircularProgress />
          </Box>
        ) : (
          <>
            {isError ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="160px">
                <Typography variant="body1" sx={{ color: 'grey.600' }}>
                  Error Fetching Events!
                </Typography>
              </Box>
            ) : (
              <RenderTable events={transformEvents} />
            )}
          </>
        )}
      </Box>
    </Container>
  );
}
