import { API } from 'aws-amplify';
import axios from 'axios';

import environment from 'config';
import { AWSResource, presignedUrlRequest, Slate, SourceBase } from 'types';

export const listAWSResources = (type?: string, group?: string): Promise<AWSResource<any>[]> => {
  return API.get(environment.API.REST.NAME, 'awsResource', {
    queryStringParameters: {
      type,
      group,
    },
  });
};

export const listSlates = (group?: string): Promise<Slate[]> => {
  return API.get(environment.API.REST.NAME, 'slate', {
    queryStringParameters: {
      group,
    },
  });
};

export const getAllSources = (): Promise<SourceBase[]> => {
  return API.get(environment.API.REST.NAME, 'source', {});
};

export const getPresignedURL = async (body: presignedUrlRequest[]) => {
  const data = {
    slates: body,
  };
  return API.post(environment.API.REST.NAME, 'slate/get-presigned-url', { body: data });
};

export const uploadFile = async (url: string, file: File) => {
  const buff = await file.arrayBuffer();
  return axios.put(url, buff, { headers: { 'Content-Type': file.type } });
};

export const deleteSlate = (slateId: string) => {
  return API.del(environment.API.REST.NAME, `slate/${slateId}`, {});
};

export const getSlate = (slateId: any) => {
  return API.get(environment.API.REST.NAME, `slate/${slateId}`, {});
};
