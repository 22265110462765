import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ActiveMenuProvider } from 'contexts/ActiveMenuContext';
import { CurrentUserInfoProvider } from 'contexts/CurrentUserInfoContext';
import { ToasterProvider } from 'contexts/ToasterContext';

import { GroupInfoProvider } from '../../contexts/GroupInfoContext';
import { WebSocketSessionProvider } from '../../contexts/WebSocketContext';

const AppWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <WebSocketSessionProvider>
        <ToasterProvider>
          <ActiveMenuProvider>
            <CurrentUserInfoProvider>
              <GroupInfoProvider>{children}</GroupInfoProvider>
            </CurrentUserInfoProvider>
          </ActiveMenuProvider>
        </ToasterProvider>
      </WebSocketSessionProvider>
    </LocalizationProvider>
  );
};

export default AppWrapper;
