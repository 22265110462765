import { useEffect, useRef } from 'react';

// this is to check if the component is actually mounted on DOM
export const useIsMount = (): boolean => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};
