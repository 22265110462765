import { memo } from 'react';

import { Icon, Stack, Theme, Typography } from '@mui/material';
import { Cancel, CheckCircle, Stop } from '@mui/icons-material';

import deviceStates, { FeedNodeStatusType } from 'constants/DeviceStates';

import { EntityStatus } from 'types';
import { buildStyles, getStatusColor } from 'utils/helpers';

export const styles = buildStyles({
  icon: {
    width: { xs: '12px', md: '15px' },
    height: { xs: '12px', md: '15px' },
  },
  statusText: (theme: Theme) => ({
    fontSize: { xs: theme.typography.subtitle3.fontSize, md: theme.typography.body2.fontSize },
  }),
});

// support for legacy status and new status types
export const getStatusProps = (status: EntityStatus | string) => {
  const props = {
    success: {
      icon: (
        <CheckCircle sx={[{ color: getStatusColor(FeedNodeStatusType.POSITIVE) }, styles.icon]} />
      ),
      color: getStatusColor(FeedNodeStatusType.POSITIVE),
    },
    error: {
      icon: <Cancel sx={[{ color: getStatusColor(FeedNodeStatusType.NEGATIVE) }, styles.icon]} />,
      color: getStatusColor(FeedNodeStatusType.NEGATIVE),
    },
    neutral: {
      icon: <Stop sx={[{ color: getStatusColor(FeedNodeStatusType.NEUTRAL) }, styles.icon]} />,
      color: getStatusColor(FeedNodeStatusType.NEUTRAL),
    },
  };

  if (typeof status === 'string') {
    if (deviceStates.positive.includes(status)) {
      return props.success;
    } else if (deviceStates.negative.includes(status)) {
      return props.error;
    }

    return props.neutral;
  } else {
    switch (status.type) {
      case FeedNodeStatusType.POSITIVE:
        return props.success;
      case FeedNodeStatusType.NEGATIVE:
        return props.error;
      default:
        return props.neutral;
    }
  }
};

export const DeviceStatus = memo(({ status }: { status: EntityStatus | string }): JSX.Element => {
  const statusProps = getStatusProps(status);
  return (
    <Stack direction="row" alignItems="center" spacing={0.5} lineHeight="0">
      <Icon sx={styles.icon}>{statusProps.icon}</Icon>
      <Typography
        fontWeight="bold"
        textTransform="uppercase"
        sx={styles.statusText}
        color={statusProps.color}
      >
        {typeof status === 'string' ? status : status.value}
      </Typography>
    </Stack>
  );
});
