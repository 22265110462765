import { PopoverContentProps } from '@reactour/tour';

import { Button, IconButton, Paper, Stack, Typography } from '@mui/material';
import { ArrowBack, ArrowForward, Check, Close } from '@mui/icons-material';

import { UserTourStepContent, UserTourSteps } from 'constants/UserTourConstants';

export const UserTourContent = ({
  steps,
  currentStep,
  setCurrentStep,
  setIsOpen,
}: PopoverContentProps): JSX.Element => {
  const resetTour = () => {
    setCurrentStep(0);
    setIsOpen(false);
  };

  // this should be a string
  const contentKey = steps[currentStep].content as string;
  const { content, title }: UserTourStepContent = UserTourSteps[contentKey];

  return (
    <Paper sx={{ p: { md: 2.5, xs: 2 } }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2" sx={{ opacity: 0.6 }}>
          {currentStep + 1}/{steps.length}
        </Typography>
        <IconButton size="small" onClick={resetTour}>
          <Close />
        </IconButton>
      </Stack>
      <Typography variant="h6" fontWeight="bold" mb={0.5}>
        {title}
      </Typography>
      {typeof content === 'object' ? (
        Object.values(content).map((contentBit, idx) => {
          return (
            <Typography variant="body1" key={idx} mb={0.4}>
              {contentBit}
            </Typography>
          );
        })
      ) : (
        <Typography variant="body1">{content}</Typography>
      )}

      <Stack direction="row" justifyContent="space-between" mt={4}>
        <Button variant="outlined" size="small" onClick={resetTour}>
          {currentStep === steps.length - 1 ? 'End' : 'Skip'} Tour
        </Button>
        <Stack direction="row" spacing={1}>
          <IconButton
            color="primary"
            size="small"
            onClick={() => setCurrentStep(currentStep - 1)}
            disabled={currentStep === 0}
          >
            <ArrowBack />
          </IconButton>
          <Button
            variant="contained"
            size="small"
            endIcon={currentStep === steps.length - 1 ? <Check /> : <ArrowForward />}
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            {currentStep === steps.length - 1 ? 'End' : 'Next'}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
