import { format } from 'date-fns';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { DeviceStatus } from 'components/Common/DeviceStatus';

import { DATE_FORMAT_WITH_TIME } from 'constants/CommonConstants';

import { AWSChannel, AWSFlow, AWSMedialiveDevice, AWSResource, AWSResourceType } from 'types';

import { MediaconnectFlowResourceDetails } from './MediaconnectFlowResourceDetails';
import { MedialiveChannelResourceDetails } from './MedialiveChannelResourceDetails';
import { MedialiveDeviceResourceDetails } from './MedialiveDeviceResourceDetails';
import { MedialiveInputResourceDetails } from './MedialiveInputResourceDetails';
import { ZixiResourceDetails } from './ZixiResourceDetails';

interface IResourceDetails {
  resource: AWSResource;
}

export const ResourceDetails = ({ resource }: IResourceDetails) => {
  const renderDetails = () => {
    switch (resource.type) {
      case AWSResourceType.MEDIALIVE_INPUT:
        return <MedialiveInputResourceDetails data={resource} />;
      case AWSResourceType.MEDIALIVE_DEVICE:
        return (
          <MedialiveDeviceResourceDetails data={resource as AWSResource<AWSMedialiveDevice>} />
        );
      case AWSResourceType.CHANNEL:
        return <MedialiveChannelResourceDetails data={resource as AWSResource<AWSChannel>} />;
      case AWSResourceType.FLOW:
        return <MediaconnectFlowResourceDetails data={resource as AWSResource<AWSFlow>} />;
      case AWSResourceType.ZIXI_SOURCE:
      case AWSResourceType.ZIXI_CHANNEL:
      case AWSResourceType.ZIXI_TARGET:
        return <ZixiResourceDetails data={resource} />;
      default:
        return <></>;
    }
  };
  return (
    <Box p={1}>
      <Stack direction="row" spacing={1}>
        <Box>
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
            <b>{resource.type.replace('_', ' ')}</b>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {resource?.updatedAt
              ? format(resource?.updatedAt, DATE_FORMAT_WITH_TIME)
              : 'STALE DATA'}
          </Typography>
        </Box>
        <Divider variant="inset" sx={{ flexGrow: '1' }} />
        <DeviceStatus status={resource?.status} />
      </Stack>
      {renderDetails()}
    </Box>
  );
};
