import { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import {
  Autocomplete,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import { postUser } from 'services/userService';

import { CurrentUserInfoContext } from 'contexts/CurrentUserInfoContext';
import { useGroups } from 'contexts/GroupInfoContext';
import { useToaster } from 'contexts/ToasterContext';

import { AllowedUserRoleCreations } from 'constants/AccessConstants';
import { UserRole, UserRoleDisplay } from 'constants/AuthConstants';
import { AdminGroupNames, DefaultTeamMemberInputs } from 'constants/TeamConstants';

import { PopUpButtonProps, User } from 'types';
import { isUserInputValid } from 'utils/helpers';

import { PopUp } from '.';

interface AddMemberProps {
  isAddMemberPopUpOpen: boolean;
  close: () => void | Promise<void>;
  triggerRefetch: () => void;
}

export function AddMemberPopUp({
  isAddMemberPopUpOpen,
  close,
  triggerRefetch,
}: AddMemberProps): JSX.Element {
  const [memberInfo, setMemberInfo] = useState<User>(DefaultTeamMemberInputs);
  const { groups } = useGroups();
  const { userInfo } = useContext(CurrentUserInfoContext);
  const allowedUserRoleCreations = AllowedUserRoleCreations[userInfo.role];
  const { triggerToast } = useToaster();
  const [errorOnFirstNameField, setErrorOnFirstNameField] = useState(false);
  const [errorOnLastNameField, setErrorOnLastNameField] = useState(false);
  const [errorOnEmailField, setErrorOnEmailField] = useState(false);
  const [errorOnGroupField, setErrorOnGroupField] = useState(false);

  const { mutate: addNewUser, isLoading: isAddingNewUser } = useMutation(
    (newUser: User) => postUser(newUser),
    {
      onSuccess: () => {
        triggerToast({
          message: `${memberInfo?.firstName} ${memberInfo?.lastName} successfully added`,
          type: 'success',
        });
        setMemberInfo(DefaultTeamMemberInputs);
        triggerRefetch();
        close();
      },
      onError: () => {
        triggerToast({
          message: `Error while trying to add ${memberInfo?.firstName} ${memberInfo?.lastName}`,
          type: 'error',
        });
      },
    }
  );

  const addMember = () => {
    if (userInfo?.role === UserRole.GROUP_MANAGER) {
      addNewUser({ ...memberInfo, group: userInfo?.group });
    } else {
      addNewUser(memberInfo);
    }
  };

  const handleGroupSelect = (group: string) => {
    setErrorOnGroupField(true);
    if (group) {
      setMemberInfo({ ...memberInfo, group });
    }
  };

  const closePopUp = () => {
    setErrorOnFirstNameField(false);
    setErrorOnEmailField(false);
    setErrorOnLastNameField(false);
    setErrorOnGroupField(false);
    close();
  };

  const buttons: PopUpButtonProps[] = [
    { name: 'CANCEL', handler: closePopUp, variant: 'outlined' },
    { name: 'ADD', handler: addMember, variant: 'contained', isLoading: isAddingNewUser },
  ];

  const handleMemberInputChange =
    (key: keyof User) => (event: React.ChangeEvent<HTMLInputElement>) => {
      switch (key) {
        case 'firstName':
          setErrorOnFirstNameField(true);
          break;
        case 'lastName':
          setErrorOnLastNameField(true);
          break;
        case 'email':
          setErrorOnEmailField(true);
          break;
      }
      setMemberInfo((prevMemberInfo) => ({
        ...prevMemberInfo,
        [key]: event.target.value,
        group: key === 'role' ? '' : prevMemberInfo.group,
      }));
    };

  return (
    <PopUp
      isPopUpOpen={isAddMemberPopUpOpen}
      title="ADD MEMBER"
      buttons={buttons}
      close={closePopUp}
      disableCTA={!isUserInputValid(memberInfo, userInfo.role)}
    >
      <Box display="flex" flexDirection="column" gap="20px">
        <TextField
          size="small"
          label="First name"
          required
          value={memberInfo.firstName}
          onChange={handleMemberInputChange('firstName')}
          error={errorOnFirstNameField && memberInfo.firstName.trim().length === 0}
        />
        <TextField
          size="small"
          label="Last name"
          required
          value={memberInfo.lastName}
          onChange={handleMemberInputChange('lastName')}
          error={errorOnLastNameField && memberInfo.lastName.trim().length === 0}
        />
        <TextField
          size="small"
          label="Email"
          type="email"
          required
          value={memberInfo.email}
          onChange={handleMemberInputChange('email')}
          error={errorOnEmailField && memberInfo.email.trim().length === 0}
        />
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 600, color: 'grey.600' }}>
            ROLE
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={memberInfo.role}
            onChange={handleMemberInputChange('role')}
          >
            {allowedUserRoleCreations?.map((role) => (
              <FormControlLabel
                key={role}
                value={role}
                control={<Radio size="small" />}
                label={<Typography variant="body2">{UserRoleDisplay[role]}</Typography>}
              />
            ))}
          </RadioGroup>
        </Box>
        {groups && (
          <Autocomplete
            id="select-member-team"
            disabled={userInfo.role !== UserRole.APPLICATION_ADMIN}
            readOnly={userInfo.role === UserRole.GROUP_MANAGER}
            value={
              userInfo.role === UserRole.APPLICATION_ADMIN
                ? memberInfo?.group || ''
                : userInfo?.group || ''
            }
            size="medium"
            options={
              memberInfo.role !== UserRole.APPLICATION_ADMIN
                ? groups.map((group) => group.id || '')
                : Object.keys(AdminGroupNames)
            }
            onChange={(_, value: string) => handleGroupSelect(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Choose Group"
                required
                error={
                  errorOnGroupField &&
                  userInfo.role === UserRole.APPLICATION_ADMIN &&
                  memberInfo?.group?.trim()?.length === 0
                }
              />
            )}
          />
        )}
      </Box>
    </PopUp>
  );
}
