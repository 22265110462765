enum Region {
  A = 'us-east-1',
  B = 'us-west-2',
}

enum Deployment {
  development = 'dev',
  test = 'test',
  prod = 'prod',
  local = 'local',
}

const CognitoConfig: Record<
  Deployment,
  Record<Region, { USER_POOL_ID: string; APP_CLIENT_ID: string }>
> = {
  [Deployment.development]: {
    [Region.A]: {
      USER_POOL_ID: 'us-east-1_XIKHutTkL',
      APP_CLIENT_ID: '1052gr8als0q1jld6bmpj52m9',
    },
    [Region.B]: {
      USER_POOL_ID: 'us-west-2_G0mJoN3VR',
      APP_CLIENT_ID: '7n5bu5k1u7t102el8g8jj8h9qo',
    },
  },
  [Deployment.test]: {
    [Region.A]: {
      USER_POOL_ID: 'us-east-1_cHUkOIbi6',
      APP_CLIENT_ID: '2bn2734kgi3sum9hvd0hgspd67',
    },
    [Region.B]: {
      USER_POOL_ID: 'us-west-2_QHC225vVX',
      APP_CLIENT_ID: '3tdmgv0moh8eqjhvm6g18p3oi9',
    },
  },
  [Deployment.prod]: {
    [Region.A]: {
      USER_POOL_ID: 'us-east-1_ohfaxgO3J',
      APP_CLIENT_ID: '4p57493v1r4lce27te4nbjedm',
    },
    [Region.B]: {
      USER_POOL_ID: 'us-west-2_WMlEXLh25',
      APP_CLIENT_ID: '2u2tgtsve54o6mc3b6d1i3qqjq',
    },
  },
  [Deployment.local]: {
    [Region.A]: {
      USER_POOL_ID: 'us-east-1_XIKHutTkL',
      APP_CLIENT_ID: '1052gr8als0q1jld6bmpj52m9',
    },
    [Region.B]: {
      USER_POOL_ID: 'us-west-2_G0mJoN3VR',
      APP_CLIENT_ID: '7n5bu5k1u7t102el8g8jj8h9qo',
    },
  },
};

const getBaseUrl = (stage) => {
  switch (stage) {
    case Deployment.local:
      return `localhost:3000`;
    case Deployment.prod:
      return `clubstreams.nhl.com`;
    default:
      return `${stage}.clubstreams.nhl.com`;
  }
};

const buildEnvVariables = (stage = 'local', region = 'A') => {
  const regionCode = region;
  region = Region[region];
  stage = Deployment[stage];
  const envVariables = {
    regionCode,
    cognito: {
      REGION: region,
      USER_POOL_ID: CognitoConfig[stage][region].USER_POOL_ID,
      APP_CLIENT_ID: CognitoConfig[stage][region].APP_CLIENT_ID,
      APP_DOMAIN: `clubstreams-${
        stage === Deployment.local ? Deployment.development : stage
      }-${region}.auth.${region}.amazoncognito.com`,
      SCOPE: ['email', 'openid', 'profile'],
      CALLBACK_URL:
        stage === Deployment.local
          ? `http://${getBaseUrl(stage)}/dashboard/`
          : `https://${getBaseUrl(stage)}/dashboard/`,
      SIGNOUT_URL:
        stage === Deployment.local
          ? `http://${getBaseUrl(stage)}/`
          : `https://${getBaseUrl(stage)}/`,
      RESPONSE_TYPE: 'code',
      SAML_PROVIDER_OKTA: 'PresidioMSOkta',
      SAML_PROVIDER_ENTRA: 'NHLEntra',
    },
    API: {
      REST: {
        TYPE: 'rest',
        NAME: 'APIGatewayREST',
        BASE_URL:
          stage === Deployment.local ? getBaseUrl(Deployment.development) : getBaseUrl(stage),
        REGION: region,
        get ENDPOINT() {
          return `https://${this.REGION}.${this.TYPE}.api.${this.BASE_URL}/`;
        },
      },
      WS: {
        TYPE: 'websocket',
        NAME: 'APIGatewayWS',
        BASE_URL:
          stage === Deployment.local ? getBaseUrl(Deployment.development) : getBaseUrl(stage),
        REGION: region,
        get ENDPOINT() {
          return `wss://${this.REGION}.${this.TYPE}.api.${this.BASE_URL}/`;
        },
      },
    },
  };

  return envVariables;
};

const getEnvVariables = (stage, region) => {
  switch (stage) {
    case 'test':
      return buildEnvVariables('test', region);
    case 'local':
      return buildEnvVariables('local', region);
    case 'development':
      return buildEnvVariables('development', region);
    case 'prod':
      return buildEnvVariables('prod', region);
    default:
      return buildEnvVariables('development', region);
  }
};

const isRegionOnLocalStorageValid = () => {
  return Object.keys(Region).includes(localStorage.getItem('REGION'));
};

const deployment =
  process.env.REACT_APP_STAGE === 'local'
    ? Deployment['development']
    : Deployment[process.env.REACT_APP_STAGE];

const config = {
  // Add common config values here
  regions: Region,
  deployment: deployment,
  baseUrl: getBaseUrl(deployment),
  ...getEnvVariables(
    process.env.REACT_APP_STAGE,
    // make default region A, irrespective of where the frontend build is deployed
    isRegionOnLocalStorageValid()
      ? localStorage.getItem('REGION')
      : process.env.REACT_APP_REGION || 'A'
  ),
};

export default config;
